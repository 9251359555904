import React from "react";
import {ErrorMessage, Field} from "formik";
import TextError from "../TextError";

const Input = (props) => {
    const {label, name, groupClass, className, touched, errors, children, required, ...rest} = props;
    const classes = `form-group ${groupClass ? groupClass : ""}`;
    const fieldClasses = "form-control " + className + `${touched && errors ? " is-invalid" : ""}`;

    return (
        <div className={classes}>
            { label && <label htmlFor={name}>{label} { required && <span className="text-danger">*</span> }</label>}
            <Field id={name} name={name} className={fieldClasses} {...rest} />
            <ErrorMessage component={TextError} name={name}/>
            {children}
        </div>
    );
};

export default Input