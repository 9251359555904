import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {isEmpty} from "lodash";
import limApi from "../../apis/limApi";
import {getCountries} from "./countrySlice";
import {escapeSpecialChars, formatDate, replacePlaceholders, sleep} from "../../../@utils";
import sgMailConfig from "../../apis/sgMailConfig";
import {languageCodeOnly} from "../../../i18n";
import i18n from "i18next";
import sgMail from "../../apis/sgMail";
import {updateCartItems} from "./cartSlice";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";

const customerOrderPostRequest = {
    idClientAccount: "{{idClientAccount}}",
    idUser: "{{idUser}}",
    orderReason: "{{shippingMethod.orderReason}}",
    campaign: null,
    eventName: null,
    eventBeginDate: null,
    eventEndDate: null,
    requestIdContact: "{{idUser}}",
    requestReference: "{{requestReference}}",
    requestLastname: "{{requestLastname}}",
    requestFirstname: "{{requestFirstname}}",
    requestPhone: "{{requestPhone}}",
    requestMobile: "{{requestMobile}}",
    requestEmail: "{{requestEmail}}",
    principalReference: null,
    principalLastname: null,
    principalFirstname: null,
    principalPhone: null,
    principalMobile: null,
    principalEmail: null,
    buyerReference: null,
    buyerLastname: null,
    buyerFirstname: null,
    buyerPhone: null,
    buyerMobile: null,
    buyerEmail: null,
    buyerCompany: null,
    buyerAddress1: null,
    buyerAddress2: null,
    buyerZipCode: null,
    buyerCity: null,
    buyerCountryISO2: null,
    buyerComment: null,
    deliveries: [
        {
            idWarehouse: "{{idWarehouse}}",
            requestedDeliveryDate: "{{deliveryDate}}",
            idAddressBookAddress: "{{idAddressBookAddress}}",
            deliveryAddressType: "{{addressType}}",
            deliveryAddressReference: "{{reference}}",
            deliveryAddressFirstname: "{{firstname}}",
            deliveryAddressLastname: "{{lastname}}",
            deliveryAddressCompanyName: "{{companyName}}",
            deliveryAddress1: "{{address.street}}",
            deliveryAddress2: "{{address.street2}}",
            deliveryAddressZipCode: "{{address.postCode}}",
            deliveryAddressCity: "{{address.city}}",
            deliveryAddressCountryISO2: "{{address.country}}",
            deliveryAddressCountry: "{{countryName}}",
            deliveryInstruction: "{{deliveryInstruction}}",
            deliveryAddressMobile: "{{mobile}}",
            deliveryAddressPhone: "{{phone}}",
            deliveryAddressEmail: "{{email}}",
            deliveryAddressComment: "{{comment}}",
            deliveryAddressOpeningSchedules: "{{openingSchedules}}",
            appointmentRequired: false,
            appointmentDateBegin: null,
            appointmentDateEnd: null,
            appointmentComment: null,
            idRelayPointAddress: null,
            idShippingMethod: "{{shippingMethod.idShippingMethod}}",
            transportRequired: "{{shippingMethod.isTransportRequired}}",
            transportType: "{{shippingMethod.transportType}}",
            products: [],
        },
    ],
};

const executePostCustomerOrder = async (data, state) => {
    const variables = {
        idClientAccount: data.idClientAccount,
        idUser: data.user.idUser,
        requestReference: data.user.reference,
        requestLastname: data.user.lastName,
        requestFirstname: data.user.firstName,
        requestPhone: data.user.phone,
        requestMobile: data.user.mobile,
        requestEmail: data.user.email,
        ...data.shipping,
        deliveryDate: data.shipping.deliveryDate instanceof Date ? data.shipping.deliveryDate.toISOString() : data.shipping.deliveryDate,
        idWarehouse: state.warehouse.active.idWarehouse,
        countryName: state.country.list.find((country) => country.isO2 === data.shipping.address.country).countryNameEN,
        openingSchedules: escapeSpecialChars(data.shipping.openingSchedules),
        comment: escapeSpecialChars(data.shipping.comment)
    };

    const templateData = replacePlaceholders(customerOrderPostRequest, variables);
    templateData.deliveries[0].transportRequired = data.shipping.shippingMethod.isTransportRequired;
    templateData.deliveries[0].products = data.orderItems.map((item) => ({
        idProduct: item.idProduct,
        orderedQuantity: item.quantity
    }));

    templateData.documents = data.shipping.documents.map(document => ({
        document: document.base64.split(',')[1],
        documentDescription: document.fileTitle,
        documentName: document.name,
        documentFileName: document.name,
        documentSize: parseInt(document.size),
        displayPosition: document.displayPosition,
        fileType: document.ext
    }));

    const response = await limApi.post('/customerorders', templateData).then(async res => await res.data.customerOrder);

    const msg = {
        from: sgMailConfig.from,
        personalizations: [{
            to: [
                {
                    email: data.user.email
                }
            ],
            dynamic_template_data: {
                user: {
                    firstName: data.user.firstName,
                    lastName: data.user.lastName
                },
                order: {
                    orderNumber: response.orderNumber,
                    requestedDeliveryDate: formatDate(response.deliveries[0].requestedDeliveryDate),
                    inboundName: response.deliveries[0].inboundName,
                    deliveryAddress1: response.deliveries[0].deliveryAddress1,
                    deliveryAddress2: response.deliveries[0].deliveryAddress2,
                    deliveryAddressZipCode: response.deliveries[0].deliveryAddressZipCode,
                    deliveryAddressCity: response.deliveries[0].deliveryAddressCity,
                    deliveryAddressCountryISO2: state.country.list.find((country) => country.isO2 === response.deliveries[0].deliveryAddressCountryISO2).countryNameEN,
                    outboundName: response.deliveries[0].outboundName,
                    outboundAddress1: state.warehouse.active.address1,
                    outboundAddress2: state.warehouse.active.address2,
                    outboundZipCode: state.warehouse.active.zipCode,
                    outboundCity: state.warehouse.active.city,
                    outboundCountry: state.warehouse.active.country,
                    products: response.deliveries[0].products
                },
                appUrl: sgMailConfig.appUrl
            }
        }],
        template_id: sgMailConfig.templatesId.order.confirmation[languageCodeOnly(data.user.cultureLanguageCode)]
    };

    await sgMail.send(msg).catch(err => {
        console.error(err)
    });

    return response;
}

export const getCustomerOrders = createAsyncThunk(
    'customerOrders/fetchAll',
    async ({queryParams = ""}, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/customerorders?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getCustomerOrder = createAsyncThunk(
    'customerOrders/fetchOne',
    async (idCustomerOrder, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/customerorders/${idCustomerOrder}`);
            return response.data.customerOrder;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postCustomerOrder = createAsyncThunk(
    'customerOrders/create',
    async (data, {rejectWithValue, getState, dispatch}) => {
        if (isEmpty(getState().country.list)) {
            await dispatch(getCountries());
        }

        try {
            let response;

            if (data.shipping.shipToMultiple && data.shipping.multipleAddressBookAddresses.length > 0) {
                const addressData = {...data};
                let i = 0;
                for (const idAddressBookAddress of data.shipping.multipleAddressBookAddresses) {
                    const address = data.shipping.addresses.find(address => address.idAddressBookAddress === idAddressBookAddress);
                    if (address) {
                        addressData.shipping.idAddressBookAddress = idAddressBookAddress;
                        addressData.shipping.address = {
                            country: address.countryISO2,
                            street: address.address1,
                            street2: address.address2,
                            postCode: address.zipCode,
                            city: address.city
                        }
                        addressData.shipping.addressType = address.addressType;
                        addressData.shipping.reference = address.reference;
                        addressData.shipping.firstname = address.firstname;
                        addressData.shipping.lastname = address.lastname;
                        addressData.shipping.companyName = address.companyName;
                        addressData.shipping.deliveryInstruction = address.deliveryInstruction;
                        addressData.shipping.mobile = address.mobile;
                        addressData.shipping.phone = address.phone;
                        addressData.shipping.email = address.email;
                        addressData.shipping.comment = address.comment;
                        addressData.shipping.openingSchedules = address.openingSchedules;

                        response = await executePostCustomerOrder(addressData, getState()).catch(e => {
                            if (i === 0) {
                                throw Error(e.message);
                            } else {
                                dispatch(setMultipleAddressError({
                                    code: e.code,
                                    message: i18n.t("Order for {{addressName}} failed with error - {{errorMessage}}", {
                                        addressName: `<strong>${address.addressName}</strong>`,
                                        errorMessage: e.message,
                                        // interpolation: { escapeValue: false }
                                    })
                                }))
                            }
                        });
                        i++;
                    }
                }
            } else {
                response = await executePostCustomerOrder(data, getState());
            }

            return response;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const bulkCustomerOrderImport = createAsyncThunk(
    'customerOrders/bulkImport',
    async (data, {rejectWithValue}) => {
        try {
            const response = await limApi.post("/customerorders/import", {
                orders: data.orders,
                file: data.file[0],
                idShippingMethod: data.shippingMethod.idShippingMethod
            });
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const customerOrdersSlice = createSlice({
    name: 'customerOrders',
    initialState: {
        list: [],
        listLoading: false,
        order: null,
        loading: false,
        multipleAddressErrors: []
    },
    reducers: {
        resetCustomerOrders: state => {
            state.list = [];
        },
        resetCustomerOrder: state => {
            state.order = null;
        },
        resetMultipleAddressErrors: state => {
            state.multipleAddressErrors = [];
        },
        setMultipleAddressError: (state, action) => {
            state.multipleAddressErrors.push(action.payload);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCustomerOrders.pending, state => {
                state.listLoading = true;
            })
            .addCase(getCustomerOrders.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addCase(getCustomerOrders.rejected, state => {
                state.listLoading = false;
            })
            .addCase(getCustomerOrder.pending, state => {
                state.loading = true
            })
            .addCase(getCustomerOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.order = action.payload;
            })
            .addCase(getCustomerOrder.rejected, state => {
                state.loading = false
            })
            .addCase(postCustomerOrder.fulfilled, (state, action) => {
                state.order = action.payload;
            })
    }
});

export const reorderCustomerOrder = (order) => async (dispatch) => {
    // Filter only existing products.
    // When product is disabled or deleted the currentProduct property will be null
    const products = order.deliveries[0].products.filter(product => product.currentProduct);

    if (products.length === 0) {
        throw new Error(i18n.t('No eligible products for reorder.'));
    }

    // Do it like this because of the API
    for (const product of products) {
        await sleep(500)
        await dispatch(updateCartItems(product.currentProduct, product.orderedQuantity))
    }
}

export const {resetCustomerOrders, resetCustomerOrder, setMultipleAddressError, resetMultipleAddressErrors} = customerOrdersSlice.actions;

export default customerOrdersSlice.reducer;