import React, {Suspense} from "react";
import "../fake-db";
import "../styles/app/app.scss";

import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";

import routes from "./rootRoutes";
import RootRoutes from "./rootRoutes";
import {store} from "./redux/store";
import {renderRoutes} from "react-router-config";
import Auth from "./auth/Auth";
import {LoginRedirect} from "./auth/LoginRedirect";
import {AbilityContext} from "./casl/Can";
import ability from "./casl/Ability";
import {Loading} from "../@gull";
import ScrollRestoration from "../@gull/components/ScrollRestoration";
import ErrorBoundary from "../@gull/components/ErrorBoundary";
import MaintenanceMode from "../@gull/components/MaintenanceMode";
import { LastLocationProvider } from 'react-router-last-location';


function App() {
    return (
        <AppContext.Provider value={{routes}}>
            <Provider store={store}>
                <AbilityContext.Provider value={ability}>
                    <LoginRedirect>
                        <MaintenanceMode>
                            <ErrorBoundary>
                                <Auth>
                                    <Suspense fallback={<Loading/>}>
                                        <Router history={history}>
                                            <LastLocationProvider>
                                                {renderRoutes(RootRoutes)}
                                                <ScrollRestoration storageKey="linkeepers-scroll"/>
                                            </LastLocationProvider>
                                        </Router>
                                    </Suspense>
                                </Auth>
                            </ErrorBoundary>
                        </MaintenanceMode>
                    </LoginRedirect>
                </AbilityContext.Provider>
            </Provider>
        </AppContext.Provider>
    );
}

export default App;
