import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {postStockRegularizationDocumentProduct} from "./stockRegularizationDocumentProductSlice";

export const getStockRegularizationDocuments = createAsyncThunk(
    'stockRegularizationDocument/fetchAll',
    async (idBox, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/stockregularizationdocuments?pageSize=10000`)
            return response.data.stockRegularizationDocuments;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getStockRegularizationDocument = createAsyncThunk(
    'stockRegularizationDocument/fetchOne',
    async ({idBox, idStockRegularizationDocument}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/stockregularizationdocuments/${idStockRegularizationDocument}`);
            return response.data.stockRegularizationDocument;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postStockRegularizationDocuments = createAsyncThunk(
    'stockRegularizationDocument/create',
    async ({idBox, data}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.post(`/box/boxs/${idBox}/stockregularizationdocuments`, {
                idBox,
                reasonCode: data.reasonCode,
                comment: data.comment,
            });

            if (data.products) {
                for (const product of data.products) {
                    await dispatch(postStockRegularizationDocumentProduct({
                        idBox,
                        idStockRegularizationDocument: response.data.stockRegularizationDocument.idStockRegularizationDocument,
                        product
                    }))
                }
            }

            return response.data.stockRegularizationDocument;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const stockRegularizationDocumentSlice = createSlice({
    name: 'stockRegularizationDocument',
    initialState: {
        list: [],
        listLoading: false,
        document: null,
        documentLoading: false
    },
    reducers: {
        resetStockRegularizationDocument: state => {
            state.document = null;
        },
        resetStockRegularizationDocuments: state => {
            state.list = []
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getStockRegularizationDocuments.pending, state => {
                state.listLoading = true;
            })
            .addCase(getStockRegularizationDocuments.fulfilled, (state, action) => {
                state.list = action.payload;
                state.listLoading = false;
            })
            .addCase(getStockRegularizationDocuments.rejected, state => {
                state.listLoading = false;
            })
            .addCase(getStockRegularizationDocument.pending, state => {
                state.documentLoading = true;
            })
            .addCase(getStockRegularizationDocument.fulfilled, (state, action) => {
                state.document = action.payload;
                state.documentLoading = false;
            })
            .addCase(getStockRegularizationDocument.rejected, state => {
                state.documentLoading = false;
            })
    }
});

export const {resetStockRegularizationDocument, resetStockRegularizationDocuments} = stockRegularizationDocumentSlice.actions;

export default stockRegularizationDocumentSlice.reducer;