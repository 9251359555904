import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getApplications = createAsyncThunk(
    'application/fetchAll',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/application');
            return response.data.applications;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const applicationSlice = createSlice({
    name: "application",
    initialState: {
        list: [],
        listLoading: undefined
    },
    reducers: {
        resetApplications: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getApplications.pending, state => {
                state.listLoading = true;
            })
            .addCase(getApplications.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload
            })
            .addCase(getApplications.rejected, state => {
                state.listLoading = undefined;
            })
    }
});

export const {resetApplications} = applicationSlice.actions

export default applicationSlice.reducer;