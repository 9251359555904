import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from '../TextError'

const Textarea = (props) => {
    const {label, name, groupClass, className, touched, errors, required, ...rest} = props;
    const classes = `form-group ${groupClass ? groupClass : ""}`;
    const fieldClasses = "form-control " + className + `${touched && errors ? " is-invalid" : ""}`;

    return (
        <div className={classes}>
            { label && <label htmlFor={name}>{label} { required && <span className="text-danger">*</span> }</label>}
            <Field as="textarea" id={name} name={name} className={fieldClasses} {...rest} />
            <ErrorMessage component={TextError} name={name} />
        </div>
    );
}

export default Textarea