import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {getSupplier} from "./supplierSlice";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import {productTypes} from "../../views/app/catalog-admin/products/form-model/formInitialValues";

export const getSupplierOrders = createAsyncThunk(
    "supplierOrder/fetchAll",
    async ({idClientAccount, idWarehouse = "",  queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/supplierorders/?${queryParams}&idClientAccount=${idClientAccount}${idWarehouse ? `&idWarehouse=${idWarehouse}` : ""}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getSupplierOrder = createAsyncThunk(
    "supplierOrder/fetchOne",
    async (idSupplierOrder, {rejectWithValue}) => {
        try {
            return await limApi.get(`/supplierorders/${idSupplierOrder}`).then(response => response.data.supplierOrder);
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postSupplierOrder = createAsyncThunk(
    "supplierOrder/create",
    async ({data}, {rejectWithValue}) => {
        try {
            return await limApi.post('/supplierorders', data).then(response => response.data.supplierOrder);
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putSupplierOrder = createAsyncThunk(
    "supplierOrder/update",
    async ({idSupplierOrder, data}, {rejectWithValue}) => {
        try {
            return await limApi.put(`/supplierorders/${idSupplierOrder}`, data).then(response => response.data.supplierOrder);
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const cancelSupplierOrder = createAsyncThunk(
    "supplierOrder/cancel",
    async ({idSupplierOrder, data}, {rejectWithValue}) => {
        try {
            await limApi.post(`/supplierorders/${idSupplierOrder}/cancel`, data);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const bulkImportSupplierOrder = createAsyncThunk(
    "supplierOrder/bulkImport",
    async ({data}, {rejectWithValue}) => {
        try {
            return await limApi.post('/supplierorders/import', data).then(response => response.data);
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const supplierOrderSearchProduct = createAsyncThunk(
    "supplierOrder/searchProduct",
    async ({query}, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const jsonParams = {
                currentPage: 1,
                pageSize: 30,
                filterGroups: JSON.stringify([
                    {
                        filters: [
                            {
                                field: "productName",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            },
                            {
                                field: "productShortName",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            },
                            {
                                field: "sku",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            },
                            {
                                field: "skuClient",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            }
                        ]
                    },
                    {
                        filters: [{
                            field: "type",
                            value: productTypes["configurable"].value,
                            operator: "neq"
                        }]
                    }
                ])
            }

            const queryParams = new URLSearchParams(jsonParams).toString();
            // Using this endpoint instead products/search because of product type filter
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products?${queryParams}`);

            return response.data.items;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const initialState = {
    list: [],
    listLoading: undefined,
    order: undefined,
    loading: undefined,
    productsSuggestions: undefined,
    suggestionsLoading: undefined,
}

const supplierOrderSlice = createSlice({
    name: "supplierOrder",
    initialState,
    reducers: {
        resetSupplierOrders: state => {
            state.list = initialState.list;
        },
        resetSupplierOrder: state => {
            state.order = initialState.order;
        },
        resetSupplierOrderProductSuggestions: state => {
            state.productsSuggestions = initialState.productsSuggestions;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getSupplierOrders.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getSupplierOrders.fulfilled, (state, action) => {
                state.list = action.payload;
                state.listLoading = undefined;
            })
            .addCase(getSupplierOrders.rejected, state => {
                state.loading = undefined;
            })
            .addCase(getSupplierOrder.pending, state => {
                state.loading = true;
            })
            .addCase(getSupplierOrder.fulfilled, (state, action) => {
                state.order = action.payload;
                state.loading = undefined;
            })
            .addCase(getSupplier.rejected, state => {
                state.loading = undefined;
            })
            .addCase(putSupplierOrder.fulfilled, (state, action) => {
                state.order = action.payload;
            })
            .addCase(supplierOrderSearchProduct.pending, state => {
                state.suggestionsLoading = true;
            })
            .addCase(supplierOrderSearchProduct.fulfilled, (state, action) => {
                state.productsSuggestions = action.payload;
                state.suggestionsLoading = undefined;
            })
            .addCase(supplierOrderSearchProduct.rejected, state => {
                state.suggestionsLoading = undefined;
            })
    }
});

export const {resetSupplierOrders, resetSupplierOrder, resetSupplierOrderProductSuggestions} = supplierOrderSlice.actions;

export default supplierOrderSlice.reducer;