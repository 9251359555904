import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {selectUserEventAccount, selectUserIdClientAccount} from "./userSlice";
import limApi from "../../apis/limApi";
import {updateEventTags} from "./eventTagSlice";
import localStorageService from "../../services/localStorageService";

export const getEvents = createAsyncThunk(
    'events/fetchAll',
    async ({queryParams}, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/client/account/${idClientAccount}/events?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getEvent = createAsyncThunk(
    'events/fetchOne',
    async (idEvent, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/client/account/${idClientAccount}/events/${idEvent}`);
            return response.data.event;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postEvent = createAsyncThunk(
    'events/create',
    async (values, {rejectWithValue, getState, dispatch}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            const event = await limApi.post(`/client/account/${idClientAccount}/events`, {idClientAccount, ...values}).then(response => response.data.event);

            if (values.eventTags.length > 0) {
                await dispatch(updateEventTags(idClientAccount, event.idEvent, values.eventTags));
            }

            return event;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putEvent = createAsyncThunk(
    'events/update',
    async ({idEvent, values}, {rejectWithValue, getState, dispatch}) => {
        try {
            const prevValues = getState().events.event;
            // prevent event editing when the status is different from upcoming
            if (prevValues.status !== "upcoming") {
                return prevValues;
            }

            // keep dates from editing when there is orders to for this event
            if (prevValues.attachedOrders > 0 ) {
                values.eventStartDate = prevValues.eventStartDate;
                values.eventEndDate = prevValues.eventEndDate;
                values.expeditionDate = prevValues.expeditionDate;
                values.returnDate = prevValues.returnDate;
            }

            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.put(`/client/account/${idClientAccount}/events/${idEvent}`, values);

            await dispatch(updateEventTags(idClientAccount, idEvent, values.eventTags));

            return response.data.event;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteEvent = createAsyncThunk(
    'events/delete',
    async (idEvent, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            await limApi.delete(`/client/account/${idClientAccount}/events/${idEvent}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getUpcomingEvents = createAsyncThunk(
    'events/fetchUpcoming',
    async (_, {getState, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            const queryParams = 'currentPage=1&sortOrders=[{"field":"eventStartDate","direction":"asc"}]&filterGroups=[{"filters":[{"field":"status","value":"upcoming","operator":"eq"}]}]'

            const response = await limApi.get(`/client/account/${idClientAccount}/events?${queryParams}`);
            return response.data.items;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getActiveEvent = createAsyncThunk(
    'events/fetchActive',
    async (_, {getState, rejectWithValue, dispatch}) => {
        try {
            const activeEvent = localStorageService.getItem("activeEvent");
            const idClientAccount = selectUserIdClientAccount(getState());

            const event = await limApi.get(`/client/account/${idClientAccount}/events/${activeEvent.idEvent}`).then(response => response.data.event);

            if (event.status !== 'upcoming') {
                dispatch(resetActiveEvent());
                return;
            }

            localStorageService.setItem("activeEvent", event);
            return event;
        } catch (e) {
            dispatch(resetActiveEvent());
            return rejectWithValue(e);
        }
    }
);

const eventsSlice = createSlice({
    name: 'events',
    initialState: {
        list: [],
        listLoading: undefined,
        event: undefined,
        loading: undefined,
        upcomingList: [],
        upcomingListLoading: undefined,
        active: undefined
    },
    reducers: {
        resetEvents: state => {
            state.list = [];
        },
        resetEvent: state => {
            state.event = undefined;
        },
        setActiveEvent: (state, action) => {
            localStorageService.setItem("activeEvent", action.payload);
            state.active = action.payload;
        },
        resetActiveEvent: state => {
            localStorageService.removeItem("activeEvent");
            state.active = undefined;
            state.upcomingList = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getEvents.pending, (state) => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getEvents.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getEvents.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getEvent.pending, (state) => {
                state.loading = true;
            })
            .addCase(getEvent.fulfilled, (state, action) => {
                state.loading = undefined;
                state.event = action.payload;
            })
            .addCase(getEvent.rejected, state => {
                state.loading = undefined;
            })
            .addCase(putEvent.fulfilled, (state, action) => {
                state.event = action.payload;
            })
            .addCase(getUpcomingEvents.pending, state => {
                if (state.upcomingList.length === 0) {
                    state.upcomingListLoading = true;
                }
            })
            .addCase(getUpcomingEvents.fulfilled, (state, action) => {
                state.upcomingListLoading = undefined;
                state.upcomingList = action.payload;
            })
            .addCase(getUpcomingEvents.rejected, state => {
                state.upcomingListLoading = undefined;
            })
            .addCase(getActiveEvent.fulfilled, (state, action) => {
                state.active = action.payload;
            })
    }
});

/**
 * Checks if the active event is empty.
 *
 * @param {Object} state - The state object.
 * @returns {boolean} - Returns true if the active event is empty, otherwise false.
 */
export const selectIsActiveEventEmpty = state => {
    const eventAccount = selectUserEventAccount(state);
    const activeEvent = state.events.active;

    return !!(eventAccount && !activeEvent);
}

export const {resetEvents, resetEvent, setActiveEvent, resetActiveEvent} = eventsSlice.actions;

export default eventsSlice.reducer;