import React from 'react';
import PopupModal from "../../../app/views/modal/PopupModal";
import useTheme from "../../../app/hooks/use-theme";
import MasqueradeForm from "./MasqueradeForm";
import {useDispatch} from "react-redux";
import {setLayoutSettings} from "../../../app/redux/slices/layoutSlice";
import {useTranslation} from "react-i18next";
import {masqueradeSessionOpen, resetMasqueradeSuggestions} from "../../../app/redux/slices/masqueradeSlice";
import {Notifications} from "../../../app/notifications/Notifications";

const Masquerade = (props) => {
    const {t} = useTranslation();
    const themeName = useTheme();
    const dispatch = useDispatch();

    const handleModalClose = () => {
        dispatch(setLayoutSettings({
            layout1Settings: {
                masquerade: {
                    open: false
                },
            },
        }));
        dispatch(resetMasqueradeSuggestions());
    }

    const handleSubmit = (values, {resetForm, setSubmitting}) => {
        dispatch(masqueradeSessionOpen(values.idUser, values.idClientAccount))
            .then((response) => {
                if (response) {
                    resetForm();
                    handleModalClose();
                }
            })
            .catch(e => {
                Notifications({message: e?.response?.data || e.message}, '');
            });

        setSubmitting(false);
    }

    return (
        <PopupModal showModal={props.open}>
            <div className={`popup-container max-560 border rounded p-4 ${themeName}`}>
                <div className="text-right"><i className="i-Close-Window text-24 text-danger font-weight-700 cursor-pointer" onClick={handleModalClose} /></div>
                <h4 className="mb-4">{t("Masquerade as")}</h4>
                <MasqueradeForm handleSubmit={handleSubmit}/>
            </div>
        </PopupModal>
    );
};

export default Masquerade;
