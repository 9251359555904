import React from "react";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";

async function handleLogout(instance) {
    // Moved local storage cleanup to LoginRedirect.jsx EventType.LOGOUT_SUCCESS
    await instance.logoutRedirect().catch((e) => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const {instance} = useMsal();
    const {t} = useTranslation();

    return (
        <button
            className="dropdown-item cursor-pointer"
            onClick={() => handleLogout(instance)}
        >
            {t("Sign out")}
        </button>
    );
}