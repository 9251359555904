import { lazy } from "react";

const Error403 = lazy(() => import("./Error403"));

const Error404 = lazy(() => import("./Error"));

const sessionsRoutes = [
  {
    path: "/session/403",
    component: Error403,
  },
  {
    path: "/session/404",
    component: Error404
  }
];

export default sessionsRoutes;
