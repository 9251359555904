import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getCatalogGroups = createAsyncThunk(
    'catalogGroup/fetchAll',
    async ({idClient, idClientAccount, idCatalog}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}/groups`);
            return response.data.catalogGroups
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postCatalogGroup = createAsyncThunk(
    'catalogGroup/create',
    async ({idClient, idClientAccount, idCatalog, idClientAccountGroup, enabled}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}/groups`,{
                idCatalog: idCatalog,
                idClientAccountGroup: idClientAccountGroup,
                enabled: enabled,
            });

            return response.data.group;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putCatalogGroup = createAsyncThunk(
    'catalogGroup/update',
    async ({idClient, idClientAccount, idCatalog, idClientAccountGroup, idCatalogGroup, enabled}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}/groups/${idClientAccountGroup}`, {
                idCatalogGroup,
                enabled
            });

            return response.data.group;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteCatalogGroup = createAsyncThunk(
    'catalogGroup/delete',
    async ({idClient, idClientAccount, idCatalog, idClientAccountGroup}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}/groups/${idClientAccountGroup}`)
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const catalogGroupSlice = createSlice({
    name: "catalogGroup",
    initialState: {
        list: [],
        listLoading: undefined
    },
    reducers: {
        resetCatalogGroups: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCatalogGroups.pending, state => {
                state.listLoading = true;
            })
            .addCase(getCatalogGroups.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getCatalogGroups.rejected, state => {
                state.listLoading = undefined;
            })
    }
});

export const {resetCatalogGroups} = catalogGroupSlice.actions;

export default catalogGroupSlice.reducer;