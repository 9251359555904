import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {selectUserIdClientAccount} from "./userSlice";

export const getUserAddressBook = createAsyncThunk(
    'userAddressBook/fetchAll',
    async ({queryParams = "", jsonParams}, {rejectWithValue, getState}) => {
        const idClientAccount = selectUserIdClientAccount(getState());

        try {
            if (!jsonParams) {
                const queryParamsObj = Object.fromEntries(
                    new URLSearchParams(queryParams)
                );

                const readOnlySort = {
                    field: "readOnly",
                    direction: "asc",
                }

                if ("sortOrders" in queryParamsObj) {
                    // attach sortOrders to existing sortOrders
                    // queryParamsObj.sortOrders = queryParamsObj.sortOrders.slice(0, -1) + "," + JSON.stringify(readOnlySort) + "]";
                    // prepend sortOrders to existing sortOrders
                    queryParamsObj.sortOrders = "[" + JSON.stringify(readOnlySort) + "," + queryParamsObj.sortOrders.slice(1);
                } else {
                    // create sortOrders if not exists
                    queryParamsObj.sortOrders = JSON.stringify([readOnlySort]);
                }

                if (idClientAccount) {
                    queryParamsObj.idClientAccount = idClientAccount;
                }

                queryParams = new URLSearchParams(queryParamsObj).toString();
            }


            const response = jsonParams ?
                await limApi.put(`/userinfo/addressbook${idClientAccount ? `?idClientAccount=${idClientAccount}` : ''}`, jsonParams) :
                await limApi.get(`/userinfo/addressbook?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getUserAddress = createAsyncThunk(
    'userAddressBook/fetchOne',
    async (idAddressBookAddress, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/userinfo/addressbook/${idAddressBookAddress}${idClientAccount ? `?idClientAccount=${idClientAccount}` : ''}`);
            return response.data.addressBookAddress;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postUserAddress = createAsyncThunk(
    'userAddressBook/create',
    async ({address}, thunkApi) => {
        const {rejectWithValue} = thunkApi;

        try {
            const response = await limApi.post('/userinfo/addressbook', address);
            return response.data.addressBookAddress;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putUserAddress = createAsyncThunk(
    'userAddressBook/update',
    async ({idAddressBookAddress, address}, thunkApi) => {
        const {rejectWithValue} = thunkApi;

        try {
            const response = await limApi.put(`/userinfo/addressbook/${idAddressBookAddress}`, address);
            return response.data.addressBookAddress;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteUserAddress = createAsyncThunk(
    'userAddressBook/delete',
    async (idAddressBookAddress, {rejectWithValue}) => {
        try {
            await limApi.delete(`/userinfo/addressbook/${idAddressBookAddress}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const userAddressBookSlice = createSlice({
    name: 'userAddressBook',
    initialState: {
        list: [],
        listLoading: false,
        searchCriteria: undefined,
        address: null,
        addressLoading: false
    },
    reducers: {
        resetUserAddressBook: (state) => {
            state.list = [];
            state.searchCriteria = undefined;
        },
        resetUserAddress: (state) => {
            state.address = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserAddressBook.pending, (state) => {
            if (state.list.length === 0) {
                state.listLoading = true;
            }
        })
        builder.addCase(getUserAddressBook.fulfilled, (state, action) => {
            const {items, searchCriteria} = action.payload;
            state.listLoading = false;
            state.list = items;
            state.searchCriteria = searchCriteria
        })
        builder.addCase(getUserAddressBook.rejected, (state) => {
            state.listLoading = false;
        })
        builder.addCase(getUserAddress.pending, (state) => {
            state.addressLoading = true;
        })
        builder.addCase(getUserAddress.fulfilled, (state, action) => {
            state.addressLoading = false;
            state.address = action.payload
        })
        builder.addCase(getUserAddress.rejected, (state) => {
            state.addressLoading = false;
        })
        builder.addCase(putUserAddress.fulfilled, (state, action) => {
            state.address = action.payload;
        })
    }
})

export const {resetUserAddressBook, resetUserAddress} = userAddressBookSlice.actions

export default userAddressBookSlice.reducer;