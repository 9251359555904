import React, {Component} from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const customStyles = {
    content: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        overflowY: 'scroll',
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },
    overlay: {
        zIndex: '101',
    }
};

// props, {showModal, closeModal}
class PopupModal extends Component{
    render() {
        return (
            <>
                <Modal
                    isOpen={this.props.showModal}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="Modal"
                >
                    {this.props.children}
                </Modal>
            </>
        )
    }


}

export default PopupModal;