import { lazy } from "react";

const EventsList = lazy(() => import("./EventsList"));
const CreateEvent = lazy(() => import("./CreateEvent"));
const EditEvent = lazy(() => import("./EditEvent"));

const eventsRoutes = [
    {
        path: "/event/list",
        component: EventsList,
        exact: true,
        key: "View_EventsEvents"
    },
    {
        path: "/event/create",
        component: CreateEvent,
        exact: true,
        key: "Create_EventsEvents"
    },
    {
        path: "/event/:idEvent",
        component: EditEvent,
        exact: true,
        key: "Edit_EventsEvents"
    },
];

export default eventsRoutes;
