import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi, {RECORD_STATUS_DELETE, RECORD_STATUS_NEW} from "../../apis/limApi";
import {cloneDeep} from "lodash";

export const getClientAccountFeatures = createAsyncThunk(
    'clientAccountFeatures/fetchAll',
    async (idClientAccount, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/accounts/${idClientAccount}/features`);
            return response.data.clientAccountFeatures;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putClientAccountFeatures = createAsyncThunk(
    "getClientAccountFeatures/update",
    async ({idClientAccount, clientAccountFeatures}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/clients/accounts/${idClientAccount}/features`, {clientAccountFeatures});
            return response.data.clientAccountFeatures;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const clientAccountFeaturesSlice = createSlice({
    name: "clientAccountFeatures",
    initialState: {
        list: []
    },
    reducers: {
        resetClientAccountFeatures: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getClientAccountFeatures.fulfilled, (state, action) => {
                state.list = action.payload;
            })
            .addCase(putClientAccountFeatures.fulfilled, (state, action) => {
                state.list = action.payload;
            })
    }
});

export const updateClientAccountFeatures = (idClientAccount, clientAccountFeatures) => async  (dispatch, getState) => {
    const features = cloneDeep(getState().clientAccountFeatures.list);

    // Find new & removed features
    const prevFeatures = features.filter(feature => feature.isSelected).map(feature => feature.feature);
    const newFeatures = clientAccountFeatures.filter(feature => !prevFeatures.includes(feature));
    const removedFeatures = prevFeatures.filter(feature => !clientAccountFeatures.includes(feature));

    if (newFeatures.length > 0 || removedFeatures.length > 0) {
        // Update record status in obj
        features.forEach(f => {
            if (newFeatures.includes(f.feature)) {
                f.recordStatus = RECORD_STATUS_NEW;
                f.isSelected = true;
            } else if (removedFeatures.includes(f.feature)) {
                f.recordStatus = RECORD_STATUS_DELETE;
                f.isSelected = false;
            }
        });

        await dispatch(putClientAccountFeatures({idClientAccount, clientAccountFeatures: features}));
    }
}

export const {resetClientAccountFeatures} = clientAccountFeaturesSlice.actions;

export default clientAccountFeaturesSlice.reducer;