import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getGroupUsers = createAsyncThunk(
    'groupUser/fetchAll',
    async ({idClient, idClientAccount, idClientAccountGroup}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/groups/${idClientAccountGroup}/users?pageSize=10000`)

            return response.data.clientAccountGroupUsers;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postGroupUser = createAsyncThunk(
    'groupUser/create',
    async ({idClient, idClientAccount, idClientAccountGroup, idUser}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/groups/${idClientAccountGroup}/users`, {
                idUser
            });
            await dispatch(getGroupUsers({idClient, idClientAccount, idClientAccountGroup}));

            return response.data.clientAccountGroupUser;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteGroupUser = createAsyncThunk(
    'groupUser/delete',
    async ({idClient, idClientAccount, idClientAccountGroup, idClientAccountGroupUser}, {
        rejectWithValue, dispatch
    }) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/groups/${idClientAccountGroup}/users/${idClientAccountGroupUser}`)
            dispatch(getGroupUsers({idClient, idClientAccount, idClientAccountGroup}))
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const groupUserSlice = createSlice({
    name: "groupUser",
    initialState: {
        list: [],
        listLoading: undefined
    },
    reducers: {
        resetGroupUsers: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getGroupUsers.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getGroupUsers.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getGroupUsers.rejected, state => {
                state.listLoading = undefined;
            })
    }
});

export const {resetGroupUsers} = groupUserSlice.actions;

export default groupUserSlice.reducer;