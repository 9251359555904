import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getQuotaUserGroup = createAsyncThunk(
    'quotaUserGroupSlice/fetchOne',
    async ({idClientAccount, idQuota}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/quota/${idQuota}/usergroup`);
            return response.data.quotaUserGroups;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putQuotaUserGroup = createAsyncThunk(
    'quotaUserGroupSlice/update',
    async ({idClientAccount, idQuota, idClientAccountGroup, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/client/account/${idClientAccount}/quota/${idQuota}/usergroup/${idClientAccountGroup}`, {quotaUsers: data});
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getQuotaClientAccountGroup = createAsyncThunk(
    'quotaUserGroupSlice/fetchClientAccountGroup',
    async ({idClientAccount, idClientAccountGroup}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/quota/usergroup/${idClientAccountGroup}`);
            return response.data.quotaUserGroups;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const quotaUserGroupSlice = createSlice({
    name: "quotaUserGroup",
    initialState: {
        group: [],
        loading: false,
        clientAccountGroupLoading: false
    },
    reducers: {

    },
    extraReducers: builder => {
        builder
            .addCase(getQuotaClientAccountGroup.pending, state => {
                state.clientAccountGroupLoading = true;
            })
            .addCase(getQuotaClientAccountGroup.fulfilled, (state, action) => {
                state.clientAccountGroupLoading = false;
                state.group = action.payload;
            })
            .addCase(getQuotaClientAccountGroup.rejected, state => {
                state.clientAccountGroupLoading = false;
            })
    }
});

export default quotaUserGroupSlice.reducer;