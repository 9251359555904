import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";

export const getEcommerceCatalogs = createAsyncThunk(
    "ecommerce/fetchCatalogs",
    async (_, {rejectWithValue, getState}) => {
        const idClient = selectUserIdClient(getState());
        const idClientAccount = selectUserIdClientAccount(getState());

        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/catalogs`);
            return response.data.catalogs;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getEcommerceCategory = createAsyncThunk(
    "ecommerce/fetchCategory",
    async ({idCatalog, idCatalogCategory}, {rejectWithValue, getState}) => {
        const state = getState();
        const idClient = selectUserIdClient(state);
        const idClientAccount = selectUserIdClientAccount(state);
        const idWarehouse = state.warehouse?.active?.idWarehouse;
        const idEvent = state.events?.active?.idEvent;

        const queryParams = new URLSearchParams({
            ...(idWarehouse ? {idWarehouse} : {}),
            ...(idEvent ? {idEvent} : {})
        }).toString();

        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}/categories/${idCatalogCategory}?${queryParams}`);
            return response.data
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const getEcommerceProduct = createAsyncThunk(
    "ecommerce/fetchProduct",
    async (idProduct, {rejectWithValue, getState}) => {
        const state = getState();
        const idClient = selectUserIdClient(state);
        const idClientAccount = selectUserIdClientAccount(state);
        const idWarehouse = state.warehouse?.active?.idWarehouse;
        const idEvent = state.events?.active?.idEvent;

        const queryParams = new URLSearchParams({
            ...(idWarehouse ? {idWarehouse} : {}),
            ...(idEvent ? {idEvent} : {})
        }).toString();

        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products/${idProduct}?${queryParams}`);
            return response.data.product;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const ecommerceSearchProducts = createAsyncThunk(
    "ecommerce/searchProducts",
    async (query, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products/search/${query}`);
            return response.data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

const initialState = {
    catalogList: [],
    catalogListLoading: undefined,
    category: {
        products: []
    },
    categoryLoading: undefined,
    breadCrumbs: [],
    product: undefined,
    productLoading: undefined,
    searchProducts: [],
    searchProductsLoading: undefined,
}

const ecommerceSlice = createSlice({
    name: "ecommerce",
    initialState,
    reducers: {
        resetEcommerceProduct: state => {
            state.product = initialState.product;
            state.breadCrumbs = initialState.breadCrumbs;
        },
        resetEcommerceCategory: state => {
            state.category = initialState.category
        },
        resetEcommerceBreadCrumbs: state => {
            state.breadCrumbs = initialState.breadCrumbs
        },
        resetEcommerceSearchProducts: state => {
            state.searchProducts = [];
        },
        setEcommerceSearchProductOffset: (state, action) => {
            state.offsetSearchProducts = action.payload;
        },
        setEcommerceSearchEndOffset: (state, action) => {
            state.offsetSearchEnd = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getEcommerceCatalogs.fulfilled, (state, action) => {
                state.catalogList = action.payload;
            })
            .addCase(getEcommerceCategory.pending, state => {
                state.categoryLoading = true;
            })
            .addCase(getEcommerceCategory.fulfilled, (state, action) => {
                const {breadCrumbs, ...category} = action.payload;

                state.categoryLoading = initialState.categoryLoading;
                state.category = category;
                state.breadCrumbs = breadCrumbs;
            })
            .addCase(getEcommerceCategory.rejected, state => {
                state.categoryLoading = initialState.categoryLoading;
            })
            .addCase(getEcommerceProduct.pending, state => {
                state.productLoading = true;
            })
            .addCase(getEcommerceProduct.fulfilled, (state, action) => {
                state.productLoading = initialState.productLoading;
                state.product = action.payload;
            })
            .addCase(getEcommerceProduct.rejected, state => {
                state.productLoading = initialState.productLoading;
            })
            .addCase(ecommerceSearchProducts.pending, state => {
                state.searchProductsLoading = true;
            })
            .addCase(ecommerceSearchProducts.fulfilled, (state, action) => {
                state.searchProductsLoading = undefined;
                state.searchProducts = action.payload;
            })
            .addCase(ecommerceSearchProducts.rejected, state => {
                state.searchProductsLoading = undefined;
            })
    }
});

export const {resetEcommerceCategory, resetEcommerceBreadCrumbs, resetEcommerceProduct, resetEcommerceSearchProducts, setEcommerceSearchEndOffset, setEcommerceSearchProductOffset} = ecommerceSlice.actions;

export default ecommerceSlice.reducer;