import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getProviderSiteContacts = createAsyncThunk(
    'providerSiteContact/fetchAll',
    async ({idProvider, idProviderSite}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/providers/${idProvider}/providersites/${idProviderSite}/sitecontacts?pageSize=1000`);
            return response.data.siteContacts;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postProviderSiteContact = createAsyncThunk(
    'providerSiteContact/create',
    async ({idProvider, idProviderSite, values}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.post(`/box/providers/${idProvider}/providersites/${idProviderSite}/sitecontacts`,{
                idProviderSite,
                firstname: values.firstName,
                lastname: values.lastName,
                title: values.job,
                phone: values.phone,
                email: values.email,
            });
            await dispatch(getProviderSiteContacts({idProvider, idProviderSite}));
            return response.data.siteContact;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putProviderSiteContact = createAsyncThunk(
    'providerSiteContact/update',
    async ({idProvider, idProviderSite, idProviderSiteContact, values}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.put(`/box/providers/${idProvider}/providersites/${idProviderSite}/sitecontacts/${idProviderSiteContact}`,{
                idProviderSiteContact,
                firstname: values.firstName,
                lastname: values.lastName,
                title: values.job,
                phone: values.phone,
                email: values.email,
            });
            await dispatch(getProviderSiteContacts({idProvider, idProviderSite}));
            return response.data.siteContact;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProviderSiteContact = createAsyncThunk(
    'providerSiteContact/delete',
    async ({idProvider, idProviderSite, idProviderSiteContact}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/box/providers/${idProvider}/providersites/${idProviderSite}/sitecontacts/${idProviderSiteContact}`);
            await dispatch(getProviderSiteContacts({idProvider, idProviderSite}));
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);



const providerSiteContactSlice = createSlice({
    name: 'providerSiteContact',
    initialState: {
        list: [],
        listLoading: false,
        contact: undefined,
        loading: false
    },
    reducers: {
        resetProviderSiteContacts: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProviderSiteContacts.pending, state => {
                state.listLoading = true;
            })
            .addCase(getProviderSiteContacts.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addCase(getProviderSiteContacts.rejected, state => {
                state.listLoading = false;
            })
    }
});

export const {resetProviderSiteContacts} = providerSiteContactSlice.actions

export default providerSiteContactSlice.reducer;