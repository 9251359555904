import React from 'react';
import Input from "./Input";
import {useTranslation} from "react-i18next";

const Email = (props) => {
    const {t} = useTranslation()

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

    const validate = value => {
        let errors;

        if (!value && props.required) {
            errors = t('Email field is required');
        } else if(value && value.length){

            if(value.indexOf('@') <= 0 || value.indexOf('.') <= 0) {
                errors = t('Please enter valid email address');
            }

            // var requestOptions = {
            //     method: 'GET',
            //     redirect: 'follow'
            // };
            //
            // fetch(`https://api.craftyclicks.co.uk/email/1.0/validate?key=${process.env.REACT_APP_FETCHIFY_API_KEY}&email=${value}`, requestOptions)
            //     .then(response => response.text())
            //     .then((result) => {
            //         let response = result;
            //         let jsonResponse = JSON.parse(response);
            //         if(!jsonResponse.result) {
            //             errors = 'Please enter valid email address';
            //         } else if(jsonResponse.is_disposable_address) {
            //             errors = 'This email is from a disposable service. Please enter another email address';
            //         } else if(jsonResponse.is_role_address) {
            //             errors = 'You can not use this email. Please enter another email address';
            //         } else if(jsonResponse.risk == "high") {
            //             errors = 'You can not use this email. Please enter another email address';
            //         }
            //     })
            //     .catch(error => console.log('error', error));

        }

        return sleep(500).then(() => {
            return errors
        });
    };

    return <Input {...props} validate={validate}  />;
};

export default Email;
