import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import globalErrorListenerMiddleware from './middlewares/globalErrorListenerMiddleware';
import cartCheckoutListenerMiddleware from "./middlewares/cartCheckoutListenerMiddleware";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      })
          .prepend(globalErrorListenerMiddleware.middleware)
          .concat(cartCheckoutListenerMiddleware.middleware),
});
