import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {selectUserIdClientAccount} from "./userSlice";

export const getClientAccountProductAttributes = createAsyncThunk(
    "clientAccountProductAttributes/fetchAll",
    async ({queryParams}, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/client/account/${idClientAccount}/product/attributes?${queryParams}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getClientAccountProductAttribute = createAsyncThunk(
    "clientAccountProductAttributes/fetchOne",
    async (idAttribute, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/client/account/${idClientAccount}/product/attributes/${idAttribute}`);

            return response.data.productAttribute;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postClientAccountProductAttribute = createAsyncThunk(
    "clientAccountProductAttributes/create",
    async (data, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.post(`/client/account/${idClientAccount}/product/attributes`, data);

            return response.data.productAttribute;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putClientAccountProductAttribute = createAsyncThunk(
    "clientAccountProductAttributes/update",
    async ({idAttribute, data}, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.put(`/client/account/${idClientAccount}/product/attributes/${idAttribute}`, data);

            return response.data.productAttribute;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProductAttribute = createAsyncThunk(
    "clientAccountProductAttributes/delete",
    async ({idClientAccount, idAttribute}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/client/account/${idClientAccount}/product/attributes/${idAttribute}`);

            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const clientAccountProductAttributesSlice = createSlice({
    name: "clientAccountProductAttributes",
    initialState: {
        list: [],
        listLoading: undefined,
        attribute: undefined,
        loading: undefined
    },
    reducers: {
        resetClientAccountProductAttributes: state => {
            state.list = [];
        },
        resetClientAccountProductAttribute: state => {
            state.attribute = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getClientAccountProductAttributes.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getClientAccountProductAttributes.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getClientAccountProductAttributes.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getClientAccountProductAttribute.pending, state => {
                state.loading = true;
            })
            .addCase(getClientAccountProductAttribute.fulfilled, (state, action) => {
                state.loading = undefined;
                state.attribute = action.payload;
            })
            .addCase(getClientAccountProductAttribute.rejected, state => {
                state.loading = undefined;
            })
            .addCase(putClientAccountProductAttribute.fulfilled, (state, action) => {
                state.attribute = action.payload;
            })
    }
});

export const {resetClientAccountProductAttributes, resetClientAccountProductAttribute} = clientAccountProductAttributesSlice.actions;

export default clientAccountProductAttributesSlice.reducer;