import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getDocument = createAsyncThunk(
    'document/fetchOne',
    async (idDocument, {rejectWithValue, requestId, getState}) => {
        const { currentRequestId, loading } = getState().document;

        if (!loading || requestId !== currentRequestId) {
            return;
        }

        try {
            const response = await limApi.get(`/documents/${idDocument}`)
            return {
                idDocument,
                data:  response.data
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
    {
        condition(idDocument, {getState}) {
            const { document } = getState();
            const fetchStatus = document.documents[idDocument];

            if (fetchStatus) {
                // Already fetched or in progress, don't need to re-fetch
                return false;
            }
        }
    }
);

const documentSlice = createSlice({
    name: 'document',
    initialState: {
        documents: {},
        loading: false,
        currentRequestId: undefined
    },
    reducers: {
        resetDocuments: (state, action) => {
            if (action.payload.length) {
                state.documents = Object.keys(state.documents)
                    .filter((idDocument) => !action.payload.includes(idDocument))
                    .reduce((cur, idDocument) => {
                        return Object.assign(cur, { [idDocument]: state.documents[idDocument] })
                    }, {});
            } else {
                state.documents = {};
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getDocument.pending, (state, action) => {
                state.loading = true;
                state.currentRequestId = action.meta.requestId;
            })
            .addCase(getDocument.fulfilled, (state, action) => {
                state.documents[action.payload.idDocument] = action.payload.data;
                state.loading = false;
                state.currentRequestId = undefined;
            })
            .addCase(getDocument.rejected, state => {
                state.loading = false;
                state.currentRequestId = undefined;
            })
    }
});

export const {resetDocuments} = documentSlice.actions;

export default documentSlice.reducer;