import {createSlice} from "@reduxjs/toolkit";
import {GullLayoutSettings} from "../../GullLayout/settings";
import {merge} from "lodash";

const initialState = {
    settings: {
        ...GullLayoutSettings
    },
    defaultSettings: {
        ...GullLayoutSettings
    }
};

const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setLayoutSettings: (state, action) => {
            state.settings = merge({}, state.settings, action.payload);
        },
        setDefaultSettings: (state, action) => {
            state.defaultSettings = merge({}, state.defaultSettings, action.payload);
        }
    }
});

export const {setLayoutSettings, setDefaultSettings} = layoutSlice.actions;

export default layoutSlice.reducer;