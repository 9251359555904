import React from 'react';
import {FaRegObjectGroup, FaUser, FaStreetView} from "react-icons/fa";
import {BsTruck} from "react-icons/bs";
import {MdOutlineChecklist, MdOutlineWarehouse} from "react-icons/md";
import {IoPieChartOutline, IoOptions} from "react-icons/io5";
import {AiOutlineLineChart, AiOutlineAreaChart} from "react-icons/ai";
import {RiShieldUserLine} from "react-icons/ri";
import { PiPackage } from "react-icons/pi";

const Components = {
    FaRegObjectGroup,
    FaStreetView,
    FaUser,
    BsTruck,
    IoPieChartOutline,
    IoOptions,
    AiOutlineLineChart,
    AiOutlineAreaChart,
    MdOutlineChecklist,
    RiShieldUserLine,
    PiPackage,
    MdOutlineWarehouse
};

const SidenavIcons = ({icon}) => {
    // component does exist
    if (typeof Components[icon] !== "undefined") {
        return React.createElement(Components[icon], {
            className: 'nav-icon'
        });
    }

    // component doesn't exist yet
    return <i className={`nav-icon ${icon}`} />;
};

export default SidenavIcons;
