import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import ability from "./Ability";

// export const Can = createCanBoundTo(ability);

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export default (action, subject) => {
    return ability.can(action, subject);
};