import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const postApplicationUserRole = createAsyncThunk(
    'applicationUsersRoles/create',
    async (data, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/v2/users/${data.idUser}/roles`, data);
            return response.data.userRole;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteApplicationUserRole = createAsyncThunk(
    'applicationUsersRoles/delete',
    async ({idUser, idRole}, {rejectWithValue}) => {
        try {
            const response = await limApi.delete(`/v2/users/${idUser}/roles/${idRole}`);
            return response.data.userRole;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const applicationUsersRolesSlice = createSlice({
    name: 'applicationUsersRoles',
    initialState: {
        list: [],
        role: null
    }
});

export default applicationUsersRolesSlice.reducer;