import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getUserQuotas = createAsyncThunk(
    'userQuota/fetchAll',
    async ({queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/userinfo/quota?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const userQuotaSlice = createSlice({
    name: 'userQuota',
    initialState: {
        list: [],
        listLoading: false,
        quota: undefined,
        loading: false
    },
    reducers: {
        resetUserQuotas: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getUserQuotas.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getUserQuotas.fulfilled, (state, action) => {
                state.list = action.payload;
                state.listLoading = false;
            })
            .addCase(getUserQuotas.rejected, state => {
                state.listLoading = false;
            })
    }
});

export const {resetUserQuotas} = userQuotaSlice.actions;

export default userQuotaSlice.reducer;