import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getSuppliers = createAsyncThunk(
    "supplier/fetchAll",
    async ({idClientAccount, queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/accounts/${idClientAccount}/suppliers?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getSupplier = createAsyncThunk(
    "supplier/fetchOne",
    async ({idClientAccount, idClientAccountSupplier}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/accounts/${idClientAccount}/suppliers/${idClientAccountSupplier}`);
            return response.data.supplier;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postSupplier = createAsyncThunk(
    "supplier/create",
    async ({idClientAccount, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/clients/accounts/${idClientAccount}/suppliers`, data);
            return response.data.supplier;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putSupplier = createAsyncThunk(
    "supplier/update",
    async ({idClientAccount, idClientAccountSupplier, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/clients/accounts/${idClientAccount}/suppliers/${idClientAccountSupplier}`, data);
            return response.data.supplier;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteSupplier = createAsyncThunk(
    "supplier/delete",
    async ({idClientAccount, idClientAccountSupplier}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/clients/accounts/${idClientAccount}/suppliers/${idClientAccountSupplier}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const initialState = {
    list: [],
    listLoading: undefined,
    supplier: undefined,
    loading: undefined
}

const supplierSlice = createSlice({
    name: "supplier",
    initialState,
    reducers: {
        resetSupplierList: state => {
            state.list = initialState.list;
        },
        resetSupplier: state => {
            state.supplier = initialState.supplier
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getSuppliers.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getSuppliers.fulfilled, (state, action) => {
                state.list = action.payload;
                state.listLoading = undefined;
            })
            .addCase(getSuppliers.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getSupplier.pending, state => {
                state.loading = true;
            })
            .addCase(getSupplier.fulfilled, (state, action) => {
                state.supplier = action.payload;
                state.loading = undefined;
            })
            .addCase(getSupplier.rejected, state => {
                state.loading = undefined;
            })
            .addCase(putSupplier.fulfilled, (state, action) => {
                state.supplier = action.payload;
            })
    }
});

export const {resetSupplierList, resetSupplier} = supplierSlice.actions;

export default supplierSlice.reducer;