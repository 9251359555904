import React, { Fragment } from "react";
import {Link} from "react-router-dom";

const Breadcrumb = ({ routeSegments }) => {
  return (
    <Fragment>
      <div className="breadcrumb">
        {routeSegments ? (
          <Fragment>
            {/*<h1>{routeSegments[routeSegments.length - 1]["name"]}</h1>*/}
          </Fragment>
        ) : null}
        <ul>
            <li>
                <i className="i-Home1"></i>
            </li>
          {routeSegments
            ? routeSegments.reverse().map((route, index) =>
                index !== routeSegments.length - 1 ? (
                  <li key={index}>
                      {
                          route.path && (route.path !== '#' && route.path !== '') ?
                          <Link to={route.path}>
                            <span className="capitalize text-dark">
                                <u>{route.name}</u>
                            </span>
                          </Link> :

                          <span className="capitalize text-dark">
                            {route.name}
                          </span>
                      }
                  </li>
                ) : (
                  <li key={index}>
                    <span className="capitalize text-dark">{route.name}</span>
                  </li>
                )
              )
            : null}
        </ul>
      </div>
      <div className="separator-breadcrumb"></div>
    </Fragment>
  );
};

export default Breadcrumb;
