import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getCultureLanguages = createAsyncThunk(
    'cultureLanguage/fetchAll',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/culturelanguages');
            return response.data.cultureLaguages;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const cultureLanguageSlice = createSlice({
    name: 'cultureLanguage',
    initialState: {
        list: []
    },
    extraReducers: builder => {
        builder.addCase(getCultureLanguages.fulfilled, (state, action) => {
            state.list = action.payload.sort((a, b) => a.displayPosition - b.displayPosition);
        })
    }
});

export default cultureLanguageSlice.reducer;