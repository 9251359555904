import { lazy } from "react";

const UserProfile = lazy(() => import("./profile/UserProfile"));
const UserAddressList = lazy(() => import("./address/UserAddressList"));
const CreateUserAddress = lazy(() => import("./address/CreateUserAddress"));
const EditUserAddress = lazy(() => import("./address/EditUserAddress"));

const userRoutes = [
	{
		path: "/user/profile",
		component: UserProfile,
		key: "View_CoreGeneralUserAccountUserProfile"
	},
	{
		path: "/user/address/list",
		component: UserAddressList,
		key: "View_CoreGeneralUserAccountMyAddress"
	},
	{
		path: "/user/address/create",
		component: CreateUserAddress,
		key: "Create_CoreGeneralUserAccountMyAddress"
	},
	{
		path: "/user/address/:idAddressBookAddress",
		component: EditUserAddress,
		key: "Edit_CoreGeneralUserAccountMyAddress"
	},
];

export default userRoutes;