import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import {productTypes} from "../../views/app/catalog-admin/products/form-model/formInitialValues";
import {postKitComponent} from "./kitComponentSlice";

export const getKits = createAsyncThunk(
    'kit/fetchAll',
    async ({idClientAccount, queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/accounts/${idClientAccount}/kits?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getKit = createAsyncThunk(
    'kit/fetchOne',
    async ({idClientAccount, idKit}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/accounts/${idClientAccount}/kits/${idKit}`);
            return response.data.kit;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postKit = createAsyncThunk(
    'kit/create',
    async ({idClientAccount, data}, {dispatch, rejectWithValue}) => {
        try {
            const kit = await limApi.post(`/clients/accounts/${idClientAccount}/kits`, data).then(response => response.data.kit);

            if (data.components.length > 0) {
                await dispatch(addKitComponents(kit.idKit, data.components));
            }

            return kit;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putKit = createAsyncThunk(
    'kit/update',
    async ({idClientAccount, idKit, data}, {getState, dispatch, rejectWithValue}) => {
        try {
            const kit = await limApi.put(`/clients/accounts/${idClientAccount}/kits/${idKit}`, data).then(response => response.data.kit);

            const prevKitComponents = getState().kit.kit.components;

            if (prevKitComponents.length === 0 && data.components.length > 0) {
                await dispatch(addKitComponents(idKit, data.components));
            }

            return kit;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteKit = createAsyncThunk(
    'kit/delete',
    async ({idClientAccount, idKit}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/clients/accounts/${idClientAccount}/kits/${idKit}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const kitSearchProducts = createAsyncThunk(
    "kit/search",
    async ({query, isMaster, idMasterProduct}, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const jsonParams = {
                currentPage: 1,
                pageSize: 30,
                filterGroups: JSON.stringify([
                    {
                        filters: [
                            {
                                field: "productName",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            },
                            {
                                field: "skuclient",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            }
                        ]
                    },
                    {
                        filters: [{
                            field: "type",
                            value: productTypes["configurable"].value,
                            operator: "neq"
                        }]
                    },
                    ...(isMaster ? [{
                        filters: [{
                            field: "logisticProfile",
                            value: "'KIT','KIT_DLUO'",
                            operator: "in"
                        }]
                    }] : []),
                    ...(idMasterProduct ? [{
                        filters: [{
                            field: "idProduct",
                            value: idMasterProduct,
                            operator: "neq"
                        }]
                    }] : [])
                ])
            }

            const queryParams = new URLSearchParams(jsonParams).toString();
            // Using this endpoint instead products/search because of product type filter
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products?${queryParams}`);
            return isMaster ? { master: response.data.items } : { component: response.data.items };
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const getKitProduct = createAsyncThunk(
    "kit/fetchProduct",
    async (idProduct, {rejectWithValue, getState}) => {
        const idClient = selectUserIdClient(getState());
        const idClientAccount = selectUserIdClientAccount(getState());

        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products/${idProduct}`);
            return response.data.product;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const initialState = {
    list: [],
    listLoading: undefined,
    kit: undefined,
    loading: undefined,
    product: undefined,
    productLoading: undefined,
    productsSuggestions: {},
    suggestionsLoading: {},
}

const kitSlice = createSlice({
   name: "kit",
    initialState,
    reducers: {
        resetKitList: state => {
            state.list = initialState.list;
        },
        resetKit: state => {
            state.kit = initialState.kit;
        },
        kitProductsSuggestionsReset: (state, action) => {
            state.productsSuggestions[action.payload] = undefined;
        },
        resetKitProduct: state => {
            state.product = initialState.product;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getKits.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getKits.fulfilled, (state, action) => {
                state.list = action.payload;
                state.listLoading = undefined;
            })
            .addCase(getKits.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getKit.pending, state => {
                state.loading = true;
            })
            .addCase(getKit.fulfilled, (state, action) => {
                state.kit = action.payload;
                state.loading = undefined;
            })
            .addCase(getKit.rejected, state => {
                state.loading = undefined;
            })
            .addCase(kitSearchProducts.pending, (state, action) => {
                const searchSelector = action.meta.arg.isMaster ? "master" : "component";
                state.suggestionsLoading[searchSelector] = true;
            })
            .addCase(kitSearchProducts.fulfilled, (state, action) => {
                Object.assign(state.productsSuggestions, action.payload)
                const searchSelector = action.meta.arg.isMaster ? "master" : "component";
                state.suggestionsLoading[searchSelector] = undefined;
            })
            .addCase(kitSearchProducts.rejected, (state, action) => {
                const searchSelector = action.meta.arg.isMaster ? "master" : "component";
                state.suggestionsLoading[searchSelector] = undefined;
            })
            .addCase(getKitProduct.pending, state => {
                state.productLoading = true;
            })
            .addCase(getKitProduct.fulfilled, (state, action) => {
                state.productLoading = undefined;
                state.product = action.payload;
            })
            .addCase(getKitProduct.rejected, state => {
                state.productLoading = undefined;
            })
    }
});

export const addKitComponents = (idKit, components) => async (dispatch) => {
    const addComponentsPromises = components.map(component => dispatch(postKitComponent({
        idKit,
        data: {...component, idKit}
    })));
    await Promise.all(addComponentsPromises);
}

export const {resetKitList, resetKit, kitProductsSuggestionsReset, resetKitProduct} = kitSlice.actions;

export default kitSlice.reducer;