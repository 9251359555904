import { createMongoAbility } from "@casl/ability";
import {isEmpty} from "lodash";
import {SYSTEM_ADMIN_ROLE} from "../apis/limApi";

const ability = createMongoAbility([]);

export const defineAbilitiesForAccount = (accountPermissions = [], idClientAccount) => {
    const systemAdministrator = accountPermissions.find(permission => permission.idClientAccount === SYSTEM_ADMIN_ROLE);

    if (systemAdministrator) {
        ability.update(systemAdministrator.permissions);
        return;
    }

    const selectedAccount = idClientAccount || (accountPermissions.length === 1 && accountPermissions[0].idClientAccount);

    if (selectedAccount) {
        const account = accountPermissions.find(permission => permission.idClientAccount === selectedAccount);

        if (account) {
            ability.update(account.permissions);
            return;
        }
    }

    ability.update([]);
}

export const getAbilitiesForAccount = (accountPermissions = [], idClientAccount, action = undefined) => {
    let permissions = [];

    const systemAdministrator = accountPermissions.find(permission => permission.idClientAccount === SYSTEM_ADMIN_ROLE);

    if (systemAdministrator) {
        permissions = systemAdministrator.permissions;
    }

    const selectedAccount = idClientAccount || (accountPermissions.length === 1 && accountPermissions[0].idClientAccount);

    if (selectedAccount) {
        const account = accountPermissions.find(permission => permission.idClientAccount === selectedAccount);

        if (account) {
            permissions = account.permissions;
        }
    }

    if (action) {
        permissions = permissions.find(permission => permission.action === action);
    }

    return permissions;
}

/**
 * @param user
 * @param adminCustomizer
 * @returns {*[]}
 */
export const getUserRoles = (user, adminCustomizer) => {
    if (!user.idUser) return [];
    const roles = [];
    const systemAdministrator = user.accounts.find(permission => permission.idClientAccount === SYSTEM_ADMIN_ROLE);

    if (!systemAdministrator && user.accounts.length === 1 && isEmpty(adminCustomizer)) {
        roles.push(...user.accounts[0].roles.map(role => role.role))
    } else if (!isEmpty(adminCustomizer) && !systemAdministrator) {
        const selectedUserAccount = user.accounts.find(account => account.idClientAccount === adminCustomizer.idClientAccount);
        roles.push(...selectedUserAccount.roles.map(role => role.role))
    } else if (systemAdministrator) {
        roles.push(SYSTEM_ADMIN_ROLE)
    }

    return roles;
}

export default ability;