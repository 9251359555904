import i18n from "i18next";
import { initReactI18next,  } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from 'i18next-http-backend';
// import XHR from 'i18next-xhr-backend';

import translationEN from "./translations/en/translation.json";
import translationFR from "./translations/fr/translation.json";
import localStorageService from "./app/services/localStorageService";
import {gaEvents} from "./app/services/googleAnalytics";

const storageKey = 'appLang';
const defaultLanguage = "en";

export const activeLanguage = localStorageService.getItem(storageKey);

export const supportedLanguages = [
	{ code: "en", name: "EN" },
	{ code: "fr", name: "FR" },
];

export function languageCodeOnly(fullyQualifiedCode) {
	return fullyQualifiedCode.split("-")[0];
}

export const changeLanguage = (lng) => {
	const langCode = languageCodeOnly(lng);
	i18n.changeLanguage(langCode);
	localStorageService.setItem(storageKey, langCode);
	gaEvents.setLanguage(langCode);
	document.documentElement.lang = langCode;
}

i18n
	// .use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: supportedLanguages.map((lang) => lang.code),
		resources: {
			en: { translation: translationEN },
			fr: { translation: translationFR },
		},
		nonExplicitSupportedLngs: true,
		lng: activeLanguage,
		fallbackLng: defaultLanguage,
		debug: false,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		returnEmptyString: false,
	});

document.documentElement.lang = i18n.language

export default i18n;