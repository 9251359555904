import React from 'react';
import { Form } from "react-bootstrap";
import { ErrorMessage, useField } from 'formik'
import TextError from "../TextError";
import {useTranslation} from "react-i18next";


const SwitchButton = (props) => {
    const { t } = useTranslation();
    const {label, name, groupClass, className, textTrue, textFalse, ...rest} = props;
    const classes = `form-group ${groupClass ? groupClass : ""}`;
    const fieldClasses = `${className ? className : ""}`;

    const [field, meta] = useField({
        name: name,
        type: "checkbox"
    });

    const text = {
        true: textTrue ? textTrue : t("Yes"),
        false: textFalse ? textFalse : t("No")
    }

    return (
        <div className={classes}>
            { label && <label htmlFor={name}>{label}</label>}
            <Form.Check
                id={name}
                label={text[meta.value]}
                type="switch"
                className={fieldClasses}
                {...field}
                {...rest}
            />
            <ErrorMessage component={TextError} name={name}/>
        </div>
    )
}

export default SwitchButton;