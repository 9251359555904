import axios from "axios";
import * as _ from "lodash";
import uuid from "uuid";
import * as JWT from "jsonwebtoken";

class graphClient {
    apiPath;
    apiSelect;
    apiFilter;
    apiTop;
    apiSkipToken;

    constructor() {
        // Create token
        const payload = {
            jti: uuid.v4()
        };

        const token = JWT.sign(
            payload,
            process.env.REACT_APP_LINKBOX_APP_API_TOKEN_KEY,
        );

        this.axios = axios.create({
            baseURL: process.env.REACT_APP_LINKBOX_APP_API_HOST + 'api/msgraph',
            headers: {
                "x-access-token": token
            }
        })
    }

    api = (path) => {
        this.apiPath = path;
        return this;
    }

    select = (properties) => {
        this.apiSelect = properties;
        return this;
    }

    filter = (filterStr) => {
        this.apiFilter = filterStr;
        return this;
    }

    top = (n) => {
        this.apiTop = n;
        return this;
    }

    skipToken = (token) => {
        this.apiSkipToken = token;
        return this;
    }

    get = () => {
        let params = `?api=${this.apiPath}`;

        if (!_.isEmpty(this.apiSelect)) {
            params += `&select=${this.apiSelect}`;
        }
        if (!_.isEmpty(this.apiFilter)) {
            params += `&filter=${this.apiFilter}`;
        }
        if (_.isNumber(this.apiTop)) {
            params += `&top=${this.apiTop}`;
        }
        if (!_.isEmpty(this.apiSkipToken)) {
            params += `&skipToken=${this.apiSkipToken}`;
        }

        return this.axios.get(`/get${params}`)
    }

    post = (content) => {
        return this.axios.post(`/post`, {api: this.apiPath, content})
    }

    update = (content) => {
        return this.axios.patch(`/update`, {api: this.apiPath, content})
    }

    delete = () => {
        return this.axios.delete(`/delete?api=${this.apiPath}`)
    }

}

export default new graphClient();