import { lazy } from "react";

const FAQ = lazy(() => import("./FAQ"));

const pagesRoutes = [
  {
    path: "/get-support/faq",
    component: FAQ
  }
];

export default pagesRoutes;
