import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from '../TextError'

const CheckboxGroup = (props) => {
    const {label, name, options, groupClass, className, inline, touched, errors, ...rest} = props;
    const classes = `form-group ${groupClass ? groupClass : ""}`;
    // const fieldClasses = `${className ? className : ''}`;

    return (
        <div className={classes}>
            { label && <label className={`${inline ? "d-block" : ""}`}>{label}</label>}
            <div className={`${inline ? "d-inline-flex" : ""}`}>
            <Field name={name}>
                {({ field }) => {
                    return options.map((option) => {
                        return (
                            // Label class can be also - checkbox-outline-primary
                            <label
                                key={option.value}
                                className={`checkbox checkbox-primary ${inline ? "mr-2" : ""}`}>
                                <input
                                    type="checkbox"
                                    id={option.value}
                                    {...field}
                                    {...rest}
                                    value={option.value}
                                    checked={field.value.includes(option.value)}
                                />
                                <span>{option.label}</span>
                                <span className="checkmark"></span>
                            </label>
                        );
                    });
                }}
            </Field>
            </div>
            <ErrorMessage component={TextError} name={name}/>
        </div>
    )
}

export default CheckboxGroup