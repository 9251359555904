import * as _ from "lodash";

export const getClientAccounts = (idClient, clientsList, asOptions = true) => {
    const client = clientsList.find((cl) => cl.idClient === idClient);

    if (!asOptions) return client.accounts;

    let clientAccounts = [
        {
            label: "-- None --",
            value: "",
        }
    ];

    if (client && client.accounts.length > 0) {
        client.accounts.forEach((account) =>
            clientAccounts.push({
                value: account.idClientAccount,
                label: account.accountName,
            })
        );
    }

    return clientAccounts;
};

/**
 *
 * @param obj
 * @param addressFields Object {
        address: {
            country: "country",
            street: "address1",
            street2: "address2",
            postCode: "zipCode",
            city: "city"
        }
    }
 * @returns {*}
 */
export const createAddressField = (obj, addressFields = {}) => {
    let newObj = {...obj};

    _.forIn(addressFields, (addressField, addressKey) => {
        const fieldMap = new Map(_.map(addressField, (field, fKey) => {
            return [field, fKey]
        }));

        let address = {search: ""};

        _.forIn(addressField, (field, key) => {
            address[key] = "";
        })

        _.map(obj, (value, key) => {
            if (typeof fieldMap.get(key) != 'undefined') {
                address[fieldMap.get(key)] = value ?? "";
                delete newObj[key];
            }
        })

        newObj[addressKey] = address;
    })

    return newObj;
}

export const destructAddressField = (obj, addressFields) => {
    let newObj = {...obj};

    _.forIn(addressFields, (addressField, addressKey) => {
        const fieldMap = new Map(_.map(addressField, (field, fKey) => {
            return [fKey, field]
        }));

        _.map(addressField, (value, key) => {
            if (typeof fieldMap.get(key) != 'undefined' && fieldMap.get(key) !== 'search') {
                newObj[fieldMap.get(key)] = _.isEmpty(_.trim(obj[addressKey][key])) ? "" : _.trim(obj[addressKey][key]);
                delete newObj[addressKey]
            }
        })
    })

    return newObj;
}

export const daysOfWeek = [
    {
        label: 'Monday',
        weekday: 1
    },
    {
        label: 'Tuesday',
        weekday: 2
    },
    {
        label: 'Wednesday',
        weekday: 3
    },
    {
        label: 'Thursday',
        weekday: 4
    },
    {
        label: 'Friday',
        weekday: 5
    },
    {
        label: 'Saturday',
        weekday: 6
    },
    {
        label: 'Sunday',
        weekday: 0
    }
]

export const daysOfWeekIncrement = {
    1: 'Every week',
    2: 'Every 2 weeks',
    3: 'Every 3 weeks',
    4: 'Every 4 weeks',
    5: 'Even weeks',
    6: 'Odd weeks'
}

/**
 *
 * @param value
 * @returns {null|*}
 */
export const deliveryDayOfWeekToDbValue = (value) => {
    if (!_.isEmpty(value) && Array.isArray(value)) {
        return value[0].split("_").shift();
    }
    return null;
}

/**
 *
 * @param value
 * @returns {[string]|*[]}
 */
export const dbValueToDeliveryDayOfWeek = (value) => {
    if (value || value === 0) {
        const day = daysOfWeek.find(d => d.weekday === parseInt(value));
        if (day) {
            return [`${day.weekday}_${day.label}`];
        }
        return [];
    }
    return [];
}

/**
 *
 * @param value
 * @returns {null|*}
 */
export const daysOfWeekIncrementToDbValue = (value) => {
    if (!_.isEmpty(value) && Array.isArray(value)) {
        return value[0].split("_").shift();
    }
    return null;
}

/**
 *
 * @param value
 * @returns {[string]|*[]}
 */
export const dbValueToDaysOfWeekIncrement = (value) => {
    if (value) {
        return [`${value}_${daysOfWeekIncrement[value].replace(/\s/g, '_')}`];
    }
    return [];
}

export const buildApplicationRolesJsonParams = (idClient, idClientAccount) => {
    const jsonParams = {
        currentPage: 1,
        sortOrders: [{
            field: "name",
            direction: "ASC"
        }]
    }

    if (!idClient) {
        jsonParams.filterGroups = [{
            filters: [{
                field: "isAdminRole",
                operator: "eq",
                value: "1"
            }]
        }]
    } else if (idClient) {
        jsonParams.filterGroups = [
            {
                filters: [{
                    field: "isAdminRole",
                    operator: "eq",
                    value: "0"
                }]
            },
            {
                filters: [{
                    field: "idClient",
                    operator: "eq",
                    value: idClient
                }]
            },
            ...(idClientAccount ? [{
                filters: [{
                    field: "idClientAccount",
                    operator: "eq",
                    value: idClientAccount
                }]
            }] : [])
        ]
    }

    return jsonParams;
}