import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getProviderSites = createAsyncThunk(
    'providerSite/fetchAll',
    async ({queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/providers/providersites?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getProviderSite = createAsyncThunk(
    'providerSite/fetchOne',
    async ({idProvider, idProviderSite}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/providers/${idProvider}/providersites/${idProviderSite}`);
            return response.data.providerSite;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postProviderSite = createAsyncThunk(
    'providerSite/create',
    async ({idProvider, values}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/box/providers/${idProvider}/providersites`, values);
            return response.data.provider
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const putProviderSite = createAsyncThunk(
    'providerSite/update',
    async ({idProvider, idProviderSite, values}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/box/providers/${idProvider}/providersites/${idProviderSite}`, values);
            return response.data.providerSite;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProviderSite = createAsyncThunk(
    'providerSite/delete',
    async ({idProvider, idProviderSite}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/box/providers/${idProvider}/providersites/${idProviderSite}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getProviderSiteStatusTypes = createAsyncThunk(
    'providerSite/fetchProviderSiteStatusTypes',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/providersites/statustypes');
            return response.data.providerSiteStatusTypes;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const providerSiteSlice = createSlice({
    name: 'providerSite',
    initialState: {
        list: [],
        listLoading: undefined,
        providerSite: undefined,
        loading: undefined,
        statusTypes: [],
        statusTypesLoading: undefined,
    },
    reducers: {
        resetProviderSites: state => {
            state.list = [];
        },
        resetProviderSite: state => {
            state.providerSite = undefined;
        },
        resetProviderSiteStatusTypes: state => {
            state.statusTypes = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProviderSites.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getProviderSites.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getProviderSites.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getProviderSite.pending, state => {
                state.loading = true;
            })
            .addCase(getProviderSite.fulfilled, (state, action) => {
                state.loading = undefined;
                state.providerSite = action.payload;
            })
            .addCase(getProviderSite.rejected, state => {
                state.loading = undefined;
            })
            .addCase(putProviderSite.fulfilled, (state, action) => {
                state.providerSite = action.payload;
            })
            .addCase(getProviderSiteStatusTypes.pending, state => {
                state.statusTypesLoading = true;
            })
            .addCase(getProviderSiteStatusTypes.fulfilled, (state, action) => {
                state.statusTypesLoading = undefined;
                state.statusTypes = action.payload;
            })
            .addCase(getProviderSiteStatusTypes.rejected, state => {
                state.statusTypesLoading = undefined;
            })
    }
});

export const {resetProviderSites, resetProviderSite, resetProviderSiteStatusTypes} = providerSiteSlice.actions;

export default providerSiteSlice.reducer;