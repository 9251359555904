import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {replacePlaceholders} from "../../../@utils";

const outboundOrderPostRequest = {
    "idBox": "{{idBox}}",
    "orderType": "{{orderType}}",
    "orderReference": "",
    "campaign": "",
    "budget": "",
    "userUserName": "{{userUserName}}",
    "userReference": "{{userReference}}",
    "userLastname": "{{userLastname}}",
    "userFirstname": "{{userFirstname}}",
    "userPhone": "{{userPhone}}",
    "userMobile": "{{userMobile}}",
    "userEmail": "{{userEmail}}",
    "calculatedOrderWeight": 0,
    "calculatedOrderVolume": 0,
    "idAddressBookAddress": "{{idAddressBookAddress}}",
    "deliveryAddressType": "{{addressType}}",
    "deliveryAddressReference": "{{reference}}",
    "deliveryAddressLastname": "{{lastname}}",
    "deliveryAddressFirstname": "{{firstname}}",
    "deliveryAddressPhone": "{{phone}}",
    "deliveryAddressMobile": "{{mobile}}",
    "deliveryAddressEmail": "{{email}}",
    "deliveryAddressCompanyName": "{{companyName}}",
    "deliveryAddress1": "{{address.street}}",
    "deliveryAddress2": "{{address.street2}}",
    "deliveryAddressZipCode": "{{address.postCode}}",
    "deliveryAddressCity": "{{address.city}}",
    "deliveryAddressCountryISO2": "{{address.country}}",
    "products": []
}

export const getOutboundOrders = createAsyncThunk(
    'outboundOrders/fetchAll',
    async ({idBox, queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/outboundorders?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getOutboundOrder = createAsyncThunk(
    'outboundOrders/fetchOne',
    async ({idBox, idOutboundOrder}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/outboundorders/${idOutboundOrder}`);
            return response.data.outboundOrder;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const getOutboundOrderStatusTypes = createAsyncThunk(
    'outboundOrders/fetchOutboundOrderStatusTypes',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/outboundorderstatustypes`);
            return response.data.outboundOrderStatusTypes;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const postOutboundOrder = createAsyncThunk(
    'outboundOrders/create',
    async (data, {rejectWithValue, getState}) => {
        const idBox = getState().warehouse.active.idWarehouse;
        const variables = {
            idBox,
            userUserName: data.user.userName,
            userReference: data.user.reference,
            userLastname: data.user.lastName,
            userFirstname: data.user.firstName,
            userPhone: data.user.phone,
            userMobile: data.user.mobile,
            userEmail: data.user.email,
            ...data.shipping
        }

        const templateData = replacePlaceholders(outboundOrderPostRequest, variables);
        templateData.products = data.orderItems.map((item) => ({
            idProduct: item.idProduct,
            orderedQuantity: item.quantity,
            bestBeforeDate: item.bestBeforeDate instanceof Date ? item.bestBeforeDate.toISOString() : item.bestBeforeDate,
            lotNumber: item.lotNumber,
            serialNumber: item.serialNumber
        }))

        try {
            const response = await limApi.post(`/box/boxs/${idBox}/outboundorders`, templateData);
            return response.data.outboundOrder;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const initialState = {
    list: [],
    listLoading: undefined,
    order: null,
    orderLoading: undefined,
    statusTypesList: [],
    statusTypesListLoading: undefined
}

const outboundOrdersSlice = createSlice({
    name: 'outboundOrders',
    initialState,
    reducers: {
        resetOutboundOrders: (state) => {
            state.list = [];
        },
        resetOutboundOrder: (state) => {
            state.order = null;
        },
        resetOutboundOrderStatusTypes: (state) => {
            state.statusTypesList = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOutboundOrders.pending, (state) => {
                state.listLoading = true;
            })
            .addCase(getOutboundOrders.fulfilled, (state, action) => {
                state.list = action.payload;
                state.listLoading = undefined;
            })
            .addCase(getOutboundOrders.rejected, (state) => {
                state.listLoading = undefined;
            })
            .addCase(getOutboundOrder.pending, (state) => {
                state.orderLoading = true;
            })
            .addCase(getOutboundOrder.fulfilled, (state, action) => {
                state.order = action.payload;
                state.orderLoading = undefined;
            })
            .addCase(getOutboundOrder.rejected, (state) => {
                state.orderLoading = undefined;
            })
            .addCase(postOutboundOrder.fulfilled, (state, action) => {
                state.order = action.payload;
            })
            .addCase(getOutboundOrderStatusTypes.pending, state => {
                state.statusTypesListLoading = true;
            })
            .addCase(getOutboundOrderStatusTypes.fulfilled, (state, action) => {
                state.statusTypesListLoading = undefined;
                state.statusTypesList = action.payload.filter((s) => s.activ).sort((a, b) => a.displayOrder - b.displayOrder);
            })
            .addCase(getOutboundOrderStatusTypes.rejected, state => {
                state.statusTypesListLoading = undefined;
            })
    }
});

export const {resetOutboundOrders, resetOutboundOrder, resetOutboundOrderStatusTypes} = outboundOrdersSlice.actions;

export default outboundOrdersSlice.reducer;