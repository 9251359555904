import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi, {RECORD_STATUS_DELETE, RECORD_STATUS_EDIT, RECORD_STATUS_NEW} from "../../apis/limApi";
import {isEqual, some} from "lodash";

export const postProductChild = createAsyncThunk(
    'productChildren/create',
    async ({idProduct, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/products/${idProduct}/child`, data);
            return response.data.productChild;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putProductChild = createAsyncThunk(
    'productChildren/update',
    async ({idProduct, idProductChild, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/products/${idProduct}/child/${idProductChild}`, data);
            return response.data.productChild;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProductChild = createAsyncThunk(
    'productChildren/delete',
    async ({idProduct, idProductChild}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/products/${idProduct}/child/${idProductChild}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const productChildSlice = createSlice({
    name: 'productChildren',
    initialState: {}
});


function compareAndUpdateRecordStatus(oldArray, newArray) {
    const updatedArray = [];

    for (const newOption of newArray) {
        const correspondingOldOption = oldArray.find(
            oldOption => oldOption.idProductChildAttributeOption === newOption.idProductChildAttributeOption
        );

        if (!correspondingOldOption) {
            // New element not found in old array
            updatedArray.push({ ...newOption, recordStatus: RECORD_STATUS_NEW });
        } else {
            // Check for changes in properties
            const hasChanges = Object.keys(newOption).some(
                key => correspondingOldOption[key] !== newOption[key]
            );

            if (hasChanges) {
                updatedArray.push({ ...newOption, recordStatus: RECORD_STATUS_EDIT });
            } else {
                updatedArray.push(newOption);
            }
        }
    }

    for (const oldOption of oldArray) {
        const stillExists = newArray.some(
            newOption => newOption.idProductChildAttributeOption === oldOption.idProductChildAttributeOption
        );

        if (!stillExists) {
            updatedArray.push({ ...oldOption, recordStatus: RECORD_STATUS_DELETE });
        }
    }

    return updatedArray;
}

export const updateProductChild = (idClientAccount, idProduct, productChildren) => async (dispatch, getState) => {
    const oldChildren = getState().products.product?.children || [];

    const equal = isEqual(oldChildren, productChildren);

    if (!equal) {
        // DELETE/REMOVE child
        const deleteChildren = oldChildren.filter(old => {
            const exist = some(productChildren, {idChild: old.idChild});
            if (!exist) {
                return old;
            }
            return null;
        });

        for (const child of deleteChildren) {
            await dispatch(deleteProductChild({idProduct, idProductChild: child.idProductChild}));
        }
        // DELETE/REMOVE child

        // ADD child
        const addChildren = productChildren.filter(ref => {
            const exist = some(oldChildren, {idChild: ref.idChild});
            if (!exist) {
                return ref;
            }
            return null;
        });

        for (const child of addChildren) {
            const attributeOptions = child.attributeOptions ? Object.entries(child.attributeOptions).map(option => ({
                idAttribute: option[0],
                idAttributeOption: option[1].idAttributeOption,
                recordStatus: RECORD_STATUS_NEW
            })) : [];

            await dispatch(postProductChild({
                idProduct,
                data: {
                    idProduct,
                    idClientAccount,
                    quantity: child.quantity,
                    idChild: child.idChild,
                    attributeOptions
                }
            }));
        }
        // ADD child

        // UPDATE child
        for (const productChild of productChildren) {
            const exist = some(oldChildren, {idProductChild: productChild.idProductChild});
            if (!exist) continue;

            const oldChild = oldChildren.find((old) => old.idProductChild === productChild.idProductChild)

            if (!isEqual(oldChild, productChild)) {
                const oldChildAttributeOptions = oldChild.attributeOptions;
                const newAttributeOptions = productChild.attributeOptions;

                const attributeOptions = compareAndUpdateRecordStatus(oldChildAttributeOptions, newAttributeOptions);

                await dispatch(putProductChild({
                    idProduct,
                    idProductChild: productChild.idProductChild,
                    data: {
                        idProduct,
                        idProductChild: productChild.idProductChild,
                        quantity: productChild.quantity,
                        attributeOptions
                    }
                }));
            }
        }
        // UPDATE child
    }
}

export default productChildSlice.reducer;