import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {reFetchImagesById} from "./imageSlice";

export const getBoxPictures = createAsyncThunk(
    'boxPicture/fetchAll',
    async (idBox, thunkApi) => {
        const {rejectWithValue} = thunkApi;
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/pictures?pageSize=10000`);
            return response.data.boxPictures;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getBoxPicture = createAsyncThunk(
    'boxPicture/fetchOne',
    async ({idBox, idBoxPicture}, thunkApi) => {
        const {rejectWithValue} = thunkApi;
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/pictures/${idBoxPicture}`);
            return response.data.boxPicture;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postBoxPicture = createAsyncThunk(
    'boxPicture/create',
    async ({idBox, picture}, thunkApi) => {
        const {rejectWithValue} = thunkApi;
        try {
            const response = await limApi.post(`/box/boxs/${idBox}/pictures`, picture);
            return response.data.boxPicture;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putBoxPicture = createAsyncThunk(
    'boxPicture/create',
    async ({idBox, idBoxPicture, picture}, thunkApi) => {
        const {rejectWithValue, dispatch, getState} = thunkApi;
        try {
            const response = await limApi.put(`/box/boxs/${idBox}/pictures/${idBoxPicture}`, picture);
            await dispatch(reFetchImagesById([getState().boxPicture.picture.idImage]));

            return response.data.boxPicture;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const deleteBoxPicture = createAsyncThunk(
    'boxPicture/delete',
    async ({idBox, idBoxPicture}, thunkApi) => {
        const {rejectWithValue, dispatch} = thunkApi;
        try {
            await limApi.delete(`/box/boxs/${idBox}/pictures/${idBoxPicture}`);
            await dispatch(getBoxPictures(idBox));
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const boxPictureSlice = createSlice({
    name: 'boxPicture',
    initialState: {
        list: [],
        listLoading: false,
        picture: null,
        pictureLoading: false
    },
    reducers: {
        resetBoxPicture: state => {
            state.picture = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBoxPictures.pending, (state) => {
            state.listLoading = true;
        });
        builder.addCase(getBoxPictures.fulfilled, (state, action) => {
            state.listLoading = false;
            state.list = action.payload
        });
        builder.addCase(getBoxPictures.rejected, (state) => {
            state.listLoading = false;
        });
        builder.addCase(getBoxPicture.pending, (state) => {
            state.pictureLoading = true;
        });
        builder.addCase(getBoxPicture.fulfilled, (state, action) => {
            state.pictureLoading = false;
            state.picture = action.payload
        });
        builder.addCase(getBoxPicture.rejected, (state) => {
            state.pictureLoading = false;
        });
    }
})

export const {resetBoxPicture} = boxPictureSlice.actions;

export default boxPictureSlice.reducer;