import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getPaymentMethods = createAsyncThunk(
    'paymentMethod/fetchAll',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/paymentMethods');
            return response.data.paymentMethods;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const paymentMethodSlice = createSlice({
    name: 'paymentMethod',
    initialState: {
        list: []
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentMethods.fulfilled, (state, action) => {
                state.list = action.payload.sort((a, b) => a.displayPosition - b.displayPosition);
            })
    }
});

export default paymentMethodSlice.reducer;