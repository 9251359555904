import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {getCountries} from "../../redux/slices/countrySlice";
import FormikControl from "../../formic/FormikControl";
import {transliterate as tr} from 'transliteration';
import {getIn, useField, useFormikContext} from "formik";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {debounce} from "lodash";

const AddressGroup = (props) => {
    const { t } = useTranslation();
    const {name, label, defaultCountry, groupClass, disabled, required} = props;
    const classes = `form-group ${groupClass ? groupClass : ""}`;
    const searchRef = useRef(null);
    const formik = useFormikContext();

    const [suggestion, setSuggestion] = useState([]);
    const [mainSuggestion, setMainSuggestion] = useState([]);
    // const [address, setAddress] = useState('');
    const [searchFlag, setSearchFlag] = useState(false);
    const [isSearchChild, setIsSearchChild] = useState(false);
    const [enterAddressManual, setEnterAddressManual] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            search: Yup.string().when(["country", "street", "street2", "postCode", "city"], {
                is: (country, street, street2, postCode, city) => {
                    return (!country || !postCode || !city || !(street || street2)) && !enterAddressManual
                },
                then: Yup.string().required(t('Please find and select your address.'))
            }),
            country: Yup.string().required(t('Country is required.')),
            street: Yup.string().notOneOf([Yup.ref("street2")], t('Street address is required.')),
            street2: Yup.string().notOneOf([Yup.ref("street")], t('Street address is required.')),
            postCode: Yup.string().required(t('Post Code is required.')),
            city: Yup.string().required(t('City is required.')),
        });
    }, [enterAddressManual, t]);

    const validate = useCallback((path) => {
        if (required) {
            return validationSchema.validateAt(path, getIn(formik.values, name))
                .then(() => undefined)
                .catch(e => e.message);
        }
    }, [formik.values, name, required, validationSchema]);

    // Fields
    const [fieldCountry, fieldCountryMeta, fieldCountryHelpers] = useField({name: `${name}.country`, validate: () => validate('country')});
    const [fieldSearch, fieldSearchMeta, fieldSearchHelpers] = useField({name: `${name}.search`, validate: () => validate('search')});
    const [fieldStreet, fieldStreetMeta, fieldStreetHelpers] = useField({name: `${name}.street`, validate: () => validate('street')});
    const [fieldStreet2, fieldStreet2Meta, fieldStreet2Helpers] = useField({name: `${name}.street2`, validate: () => validate('street2')});
    const [fieldPostCode, fieldPostCodeMeta, fieldPostCodeHelpers] = useField({name: `${name}.postCode`, validate: () => validate('postCode')});
    const [fieldCity, fieldCityMeta, fieldCityHelpers] = useField({name: `${name}.city`, validate: () => validate('city')});
    // Fields

    const handleClickOutside = (e) => {
        if (searchRef.current && !searchRef.current.contains(e.target)) {
            setSearchFlag(false)
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    useEffect(() => {
        if (defaultCountry && !fieldCountryMeta.value) {
            fieldCountryHelpers.setValue(defaultCountry);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCountry, fieldCountryMeta.value]);

    useEffect(() => {
        props.getCountries()
        // fieldCountryHelpers.setValue(country)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    const handleAreaClick = (e, id) => {

        const currentSuggestionCount = suggestion.filter(item => item.id === id)[0].count

        fetch(`https://api.craftyclicks.co.uk/address/1.1/find?id=${id}&key=${process.env.REACT_APP_FETCHIFY_API_KEY}&country=${fieldCountryMeta.value }&query=${fieldSearchMeta.value}` , requestOptions)
            .then(response => response.text())
            .then(result => {
                let response = JSON.parse(result);
                if (response.error) {
                    return;
                }

                if (response.results.length > 1 && currentSuggestionCount > 1) {
                    setIsSearchChild(true)
                    return setSuggestion(response.results);
                }

                fetch(`https://api.craftyclicks.co.uk/address/1.1/retrieve?country=${fieldCountryMeta.value}&id=${id}&key=${process.env.REACT_APP_FETCHIFY_API_KEY}`)
                    .then(response => response.text())
                    .then(result => {
                        let singleResponse = JSON.parse(result);
                        if (singleResponse.error) {
                            return;
                        }

                        fieldStreetHelpers.setValue(tr(singleResponse.result.line_1));
                        fieldStreet2Helpers.setValue(tr(singleResponse.result.line_2));
                        fieldPostCodeHelpers.setValue(tr(singleResponse.result.postal_code));
                        fieldCityHelpers.setValue(tr(singleResponse.result.locality));
                        fieldSearchHelpers.setValue("");

                        // TODO: another way to disable this fields /\
                        //flag
                        setSearchFlag(false)
                        setIsSearchChild(false)
                        setSuggestion([]);
                        setMainSuggestion([]);
                    })
                    .catch(error => console.log('error', error))
            })
            .catch(error => console.log('error', error))
    }

    const handleAddressSearch = (e) => {
        const value = e.target.value;
        setSearchValue(value)
        searchAddress(value);
    }

    const searchAddress = useCallback(
        debounce(async (value) => {
            fieldSearchHelpers.setValue(value);
            if (value && value.length >= 3) {
                setSearchFlag(true)
                try {
                    const response = await fetch(`https://api.craftyclicks.co.uk/address/1.1/find?key=${process.env.REACT_APP_FETCHIFY_API_KEY}&country=${fieldCountryMeta.value}&query=${value}`, requestOptions);
                    const result = await response.json();
                    if (result.error) {
                        return;
                    }
                    const items = result.results;

                    setEnterAddressManual(false);
                    setSuggestion(items);
                    setMainSuggestion(items);
                } catch (error) {
                    console.log('error', error);
                }
            }
        }, 800),
        [fieldCountryMeta.value]
    );

    const emptyFieldValues = () => {
        fieldSearchHelpers.setValue("");
        fieldStreetHelpers.setValue("");
        fieldStreet2Helpers.setValue("");
        fieldPostCodeHelpers.setValue("");
        fieldCityHelpers.setValue("");
    }

    const handleBackButton = () => {
        setSuggestion(mainSuggestion)
        setIsSearchChild(false)
    }

    let {countryList} = props
    if (!countryList) {
        countryList = []
    }

    return (
        <div className={classes}>
            { label && <label>{label}</label>}
            <div className="row">
                <FormikControl
                    {...fieldCountry}
                    label={t("Country")}
                    control="select"
                    groupClass="col-md-6"
                    className="custom-select"
                    value={fieldCountryMeta.value}
                    onChange={(e) => {
                        const value = e.target.value;
                        setSuggestion([])
                        setMainSuggestion([])
                        // setCountry(value);
                        fieldCountryHelpers.setValue(value)
                        emptyFieldValues();
                    }}
                    options={countryList.map((country, i) => {
                        return {
                            label: country.countryNameEN,
                            value: country.isO2
                        }
                    })}
                    disabled={disabled}
                    required={required}
                />
            </div>

            <div className="position-relative" ref={searchRef}>
                {!disabled && (
                    <FormikControl
                        {...fieldSearch}
                        label={t("Address Search")}
                        control="inputAppend"
                        onChange={handleAddressSearch}
                        value={searchValue}
                        autoComplete="off"
                        onFocus={() => setSearchFlag(true)}
                        touched={fieldSearchMeta.touched}
                        errors={fieldSearchMeta.error}
                        className="search-address"
                        append={<button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                                setSearchFlag(false);
                                setSuggestion([]);
                                setMainSuggestion([]);
                                // setAddress('');
                                setEnterAddressManual(true);
                                // This hide field has error. After validation function update the error will be unset
                                fieldSearchHelpers.setTouched(false);
                            }}
                        >{t("Enter manually")}</button>}
                    >
                        <small>{t("To effectively find the address you are looking for, start your search with the postcode followed by the street name. If you do not find the address you are looking for in the list, use Manual Entry button.")}</small>
                    </FormikControl>
                )}


                {( (fieldSearchMeta.value && fieldSearchMeta.value.length >= 3) || fieldStreetMeta.value || fieldStreet2Meta.value || fieldPostCodeMeta.value || fieldCityMeta.value || enterAddressManual) && (
                    <React.Fragment>
                        {!disabled && searchFlag && suggestion.length > 0 && (
                            <div className="position-absolute w-100 bg-white z-9 max-height-200 overflow-auto shadow border rounded-bottom" style={{top: "65px"}}>
                                <ul className="search-address list-group border-0 m-0">
                                    {
                                        suggestion.map(item => {
                                            return (
                                                <li
                                                    className={`search-address cursor-pointer border-0 list-group-item list-group-item-action ${item.count > 1 ? "parent" : ""}`}
                                                    key={item.id}
                                                    onClick={(e) => handleAreaClick(e, item.id)}
                                                >
                                                    {tr(item.labels.toString())}
                                                    {
                                                        item.count > 1 &&
                                                        <i className="i-Arrow-Right px-1 py-2">&nbsp;</i>
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                {
                                    isSearchChild &&
                                    <p className="search-address m-0 w-100 text-center btn-primary text-white text-16 cursor-pointer p-1" onClick={handleBackButton}>
                                        {t('Back')}
                                    </p>
                                }
                            </div>
                        )}

                        <FormikControl
                            {...fieldStreet}
                            label={t("Street address")}
                            control="input"
                            touched={fieldStreetMeta.touched}
                            errors={fieldStreetMeta.error}
                            disabled={!enterAddressManual}
                            required={required}
                        />
                        <FormikControl
                            {...fieldStreet2}
                            control="input"
                            touched={fieldStreet2Meta.touched}
                            errors={fieldStreet2Meta.error}
                            disabled={!enterAddressManual}
                            required={required}
                        />

                        <div className="row">
                            <FormikControl
                                {...fieldPostCode}
                                groupClass="col-lg-6"
                                label={t("Post Code")}
                                control="input"
                                touched={fieldCountryMeta.touched}
                                errors={fieldPostCodeMeta.error}
                                disabled={!enterAddressManual}
                                required={required}
                            />
                            <FormikControl
                                {...fieldCity}
                                groupClass="col-lg-6"
                                label={t("City")}
                                control="input"
                                touched={fieldCityMeta.touched}
                                errors={fieldCityMeta.error}
                                disabled={!enterAddressManual}
                                required={required}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        countryList: state.country.list
    }
}

export default connect(mapStateToProps, {getCountries})(AddressGroup)
