import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {isEqual, some} from "lodash";

export const searchAccountProductTags = createAsyncThunk(
    'productTag/searchAccountTags',
    async ({idClientAccount, query}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/products/tags/${query}`);
            return response.data.tags;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getProductTags = createAsyncThunk(
    'productTag/fetchAll',
    async (idProduct, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/products/${idProduct}/tags`);
            return response.data.productTags;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postProductTag = createAsyncThunk(
    'productTag/create',
    async ({idProduct, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/products/${idProduct}/tags`, data);
            return response.data.productTags;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProductTag = createAsyncThunk(
    'productTag/delete',
    async ({idProduct, idProductTag}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/products/${idProduct}/tags/${idProductTag}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const productTagSlice = createSlice({
    name: 'productTag',
    initialState: {
        list: [],
        tagSuggestions: [],
        suggestionsLoading: false
    },
    reducers: {
        resetSearchAccountProductTags: state => {
            state.tagSuggestions = [];
        },
        resetProductTags: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(searchAccountProductTags.pending, (state) => {
                state.suggestionsLoading = true;
            })
            .addCase(searchAccountProductTags.fulfilled, (state, action) => {
                state.tagSuggestions = action.payload;
                state.suggestionsLoading = false;
            })
            .addCase(searchAccountProductTags.rejected, (state) => {
                state.suggestionsLoading = false;
            })
            .addCase(getProductTags.fulfilled, (state, action) => {
                state.list = action.payload;
            })
    }
});

export const updateAccountProductTags = (idClientAccount, idProduct, productTags) => async (dispatch, getState) => {
    const oldTags = getState().productTag.list.map(t => ({
        idTag: t.idTag,
        tagName: t.tagName,
        idProductTag: t.idProductTag
    }));

    const equal = isEqual(oldTags, productTags);

    if (!equal) {
        // DELETE/REMOVE tag
        const deleteTags = oldTags.filter(old => {
            const exist = some(productTags, old);
            if (!exist) {
                return old;
            }
            return null;
        });

        for (const tag of deleteTags) {
            await dispatch(deleteProductTag({idProduct, idProductTag: tag.idProductTag}));
        }
        // DELETE/REMOVE tag

        // ADD tag
        const addTags = productTags.filter(ref => {
            const exist = some(oldTags, ref);
            if (!exist) {
                return ref;
            }
            return null;
        });

        for (const tag of addTags) {
            const data = {
                idProduct,
                idClientAccount,
                idTag: tag.idTag,
                tagName: tag.tagName
            };
            // Always POST tags. API is responsible to create new tag or reference existing one based ot idTag property
            await dispatch(postProductTag({idProduct, data}));
        }
        // ADD tag
    }
}

export const {resetSearchAccountProductTags, resetProductTags} = productTagSlice.actions;

export default productTagSlice.reducer;