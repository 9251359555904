import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import localStorageService from "../../services/localStorageService";

export const getWarehouses = createAsyncThunk(
    'warehouse/fetchAll',
    async ({ idClient, idClientAccount}, { rejectWithValue }) => {
        try {
            const response = await limApi.get(`/warehouses?IdClient=${idClient}&IdClientAccount=${idClientAccount}&pageSize=200`);
            return response.data.warehouses;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const setActiveWarehouse = createAsyncThunk(
    'warehouse/setActive',
    async (idWarehouse, {rejectWithValue}) => {
        localStorageService.setItem("activeWarehouseId", idWarehouse);

        try {
            const response = await limApi.get(`/warehouses/${idWarehouse}`);
            return response.data.warehouse;
        } catch (e) {
            localStorageService.removeItem("activeWarehouseId");
            return rejectWithValue(e);
        }
    }
)

export const getActiveWarehouse = createAsyncThunk(
    'warehouse/getActive',
    async (_, {rejectWithValue}) => {
        const idWarehouse = localStorageService.getItem("activeWarehouseId");

        let warehouse = null;

        try {
            if (idWarehouse) {
                warehouse = await limApi.get(`/warehouses/${idWarehouse}`).then(res => res.data.warehouse);
            }
            return warehouse;
        } catch (e) {
            localStorageService.removeItem("activeWarehouseId");
            return rejectWithValue(e);
        }
    }
)

export const getAllWarehouses = createAsyncThunk(
    'warehouse/fetchAllAdmin',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/warehouses/all');
            return response.data.warehouses;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const warehouseSlice = createSlice({
    name: 'warehouse',
    initialState: {
        list: [],
        active: null,
        listAllAdmin: []
    },
    reducers: {
        resetWarehouses: state => {
            state.list = [];
        },
        resetActiveWarehouse: state => {
            localStorageService.removeItem("activeWarehouseId");
            state.active = null;
        },
        resetAllWarehouses: state => {
            state.listAllAdmin = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWarehouses.fulfilled, (state, action) => {
                state.list = action.payload;
            })
            .addCase(setActiveWarehouse.fulfilled, (state, action) => {
                state.active = action.payload;
            })
            .addCase(getAllWarehouses.fulfilled, (state, action) => {
                state.listAllAdmin = action.payload;
            })
    }
});

export const {resetAllWarehouses, resetActiveWarehouse, resetWarehouses} = warehouseSlice.actions;

export default warehouseSlice.reducer;