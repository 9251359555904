import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getApplicationRoles = createAsyncThunk(
    'applicationRoles/fetchAll',
    async ({queryParams = "", jsonParams}, {rejectWithValue}) => {
        try {
            const response = jsonParams ? await limApi.put('/v2/application/roles', jsonParams) : await limApi.get(`/v2/application/roles?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getApplicationRole = createAsyncThunk(
    'applicationRoles/fetchOne',
    async (idRole, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/v2/application/roles/${idRole}`);
            return response.data.role;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postApplicationRole = createAsyncThunk(
    'applicationRoles/create',
    async ({data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/v2/application/roles`, data);
            return response.data.role;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putApplicationRole = createAsyncThunk(
    'applicationRoles/update',
    async ({idRole, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/v2/application/roles/${idRole}`, data);
            return response.data.role;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteApplicationRole = createAsyncThunk(
    'applicationRoles/delete',
    async ({idRole}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/v2/application/roles/${idRole}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const applicationRolesSlice = createSlice({
    name: "applicationRoles",
    initialState: {
        list: [],
        listLoading: undefined,
        role: undefined,
        loading: undefined
    },
    reducers: {
        resetApplicationRoles: state => {
            state.list = [];
        },
        resetApplicationRole: state => {
            state.role = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getApplicationRoles.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getApplicationRoles.fulfilled, (state, action) => {
                state.list = action.payload.items;
                state.listLoading = undefined;
            })
            .addCase(getApplicationRoles.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getApplicationRole.pending, state => {
                state.loading = true;
            })
            .addCase(getApplicationRole.fulfilled, (state, action) => {
                state.role = action.payload;
                state.loading = undefined;
            })
            .addCase(getApplicationRole.rejected, state => {
                state.loading = undefined;
            })
    }
});

export const {resetApplicationRoles, resetApplicationRole} = applicationRolesSlice.actions;

export default applicationRolesSlice.reducer;