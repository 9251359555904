import { lazy } from "react";

const UserList = lazy(() => import("./user/UserList"));
const AccountList = lazy(()=> import("./account/AccountList"));
const AddressBookList = lazy(()=> import('./address-book/AddressBookList'));
const CreateUser = lazy(()=> import('./user/CreateUser'));
const EditUser = lazy(()=> import('./user/EditUser'));
const CreateAccount = lazy(()=>import('./account/CreateAccount'));
const EditAccount = lazy(()=>import('./account/EditAccount'));
const CreateAddressBook = lazy(()=>import('./address-book/CreateAddressBook'));
const EditAddressBook = lazy(()=>import('./address-book/EditAddressBook'));
const GroupsList = lazy(()=>import('./groups/GroupsList'));
const CreateGroup = lazy(()=>import('./groups/CreateGroup'));
const EditGroup = lazy(()=>import('./groups/EditGroup'));
const ClientList = lazy(()=>import('./client/ClientList'));
const CreateClient= lazy(()=>import('./client/CreateClient'));
const EditClient= lazy(()=>import('./client/EditClient'));
const ShippingMethodList = lazy(()=>import('./shipping-method/ShippingMethodList'));
const CreateShippingMethod = lazy(()=>import('./shipping-method/CreateShippingMethod'));
const EditShippingMethod = lazy(()=>import('./shipping-method/EditShippingMethod'));
const Hierarchy = lazy(()=>import('./hierarchy/HierarchyList'));
const CreateHierarchy = lazy(()=>import('./hierarchy/CreateHierarchy'));
const EditHierarchy = lazy(()=>import('./hierarchy/EditHierarchy'));
const UserBulkImport = lazy(()=>import('./user/bulk-import/UserBulkImport'));
const CustomReportsList = lazy(() => import("./custom-reports/CustomReportsList"));
const CreateCustomReports = lazy(() => import("./custom-reports/CreateCustomReports"));
const EditCustomReports = lazy(() => import("./custom-reports/EditCustomReports"));
const RolesList = lazy(() => import("./roles/RolesList"));
const CreateRole = lazy(() => import("./roles/CreateRole"));
const EditRole = lazy(() => import("./roles/EditRole"));
const SupplierList = lazy(() => import("./supplier/SupplierList"))
const CreateSupplier = lazy(() => import("./supplier/CreateSupplier"))
const EditSupplier = lazy(() => import("./supplier/EditSupplier"))

const clientAdminRoutes = [
    {
        path: "/admin/user/list",
        component: UserList,
        key: 'View_CoreAdminClientAdministrationUser'
    },
    {
        path: "/admin/user/create",
        component: CreateUser,
        key: 'Create_CoreAdminClientAdministrationUser'
    },
    {
        path: "/admin/user/:idUser([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
        component: EditUser,
        exact: true,
        key: "Edit_CoreAdminClientAdministrationUser"
    },
    {
        path: '/admin/user/bulk-import',
        component: UserBulkImport,
        exact: true,
        key: "BulkImport_CoreAdminClientAdministrationUser"
    },
    {
        path: "/admin/groups/list",
        component: GroupsList,
        key: "View_CoreAdminClientManagerGroups"
    },
    {
        path: "/admin/groups/create",
        component: CreateGroup,
        key: "Create_CoreAdminClientManagerGroups"
    },
    {
        path: "/admin/groups/edit/client/:IdClient/clientAccount/:IdClientAccount/clientAccountGroup/:IdClientAccountGroup",
        component: EditGroup,
        key: "Edit_CoreAdminClientManagerGroups"
    },
    {
        path: '/admin/account/list',
        component: AccountList,
        key: "View_CoreAdminClientAdministrationAccounts"
    },
    {
        path: '/admin/account/create',
        component: CreateAccount,
        key: "Create_CoreAdminClientAdministrationAccounts"
    },
    {
        path: '/admin/account/:idClientAccount/client/:idClient',
        component: EditAccount,
        key: "Edit_CoreAdminClientAdministrationAccounts"
    },
    {
        path: '/admin/address-book/list',
        component: AddressBookList,
        key: "View_CoreAdminClientManagerAddressBook"
    },
    {
        path: '/admin/address-book/create',
        component: CreateAddressBook,
        key: "Create_CoreAdminClientManagerAddressBook"
    },
    {
        path: '/admin/address-book/:idAddressBook/client/:idClient/account/:idClientAccount',
        component: EditAddressBook,
        key: "Edit_CoreAdminClientManagerAddressBook"
    },
    {
        path: '/admin/client/list',
        component: ClientList,
        key: "View_CoreAdminClientAdministrationClients"
    },
    {
        path: '/admin/client/create',
        component: CreateClient,
        key: "Create_CoreAdminClientAdministrationClients"
    },
    {
        path: '/admin/client/:idClient',
        component: EditClient,
        key: "Edit_CoreAdminClientAdministrationClients"
    },
    {
        path: '/admin/shipping-method/list',
        component: ShippingMethodList,
        key: "View_CoreAdminClientAdministrationShippingMethods"
    },
    {
        path: '/admin/shipping-method/create',
        component: CreateShippingMethod,
        key: "Create_CoreAdminClientAdministrationShippingMethods"
    },
    {
        path: '/admin/shipping-method/:idShippingMethod',
        component: EditShippingMethod,
        key: "Edit_CoreAdminClientAdministrationShippingMethods"
    },
    {
        path: '/admin/hierarchy/list',
        component: Hierarchy,
        exact: true,
        key: "View_CoreAdminClientManagerManageHierarchy"
    },
    {
        path: '/admin/hierarchy/create',
        component: CreateHierarchy,
        exact: true,
        key: "Create_CoreAdminClientManagerManageHierarchy"
    },
    {
        path: '/admin/hierarchy/:idHierarchyModel/account/:idClientAccount',
        component: EditHierarchy,
        exact: true,
        key: "Edit_CoreAdminClientManagerManageHierarchy"
    },
    {
        path: '/admin/custom-reports/list',
        component: CustomReportsList,
        exact: true,
        key: "View_CoreAdminClientAdministrationCustomReports"
    },
    {
        path: '/admin/custom-reports/:idCustomReport/account/:idClientAccount',
        component: EditCustomReports,
        exact: true,
        key: "Edit_CoreAdminClientAdministrationCustomReports"
    },
    {
        path: '/admin/custom-reports/create',
        component: CreateCustomReports,
        exact: true,
        key: "Create_CoreAdminClientAdministrationCustomReports"
    },
    {
        path: "/admin/role/list",
        component: RolesList,
        key: 'View_CoreAdminClientAdministrationRoles'
    },
    {
        path: "/admin/role/create",
        component: CreateRole,
        exact: true,
        key: 'Create_CoreAdminClientAdministrationRoles'
    },
    {
        path: "/admin/role/:idRole",
        component: EditRole,
        exact: true,
        key: 'Edit_CoreAdminClientAdministrationRoles'
    },
    {
        path: "/admin/supplier/list",
        component: SupplierList,
        key: 'View_CoreAdminClientAdministrationSuppliers'
    },
    {
        path: "/admin/supplier/create",
        component: CreateSupplier,
        key: 'Create_CoreAdminClientAdministrationSuppliers'
    },
    {
        path: "/admin/supplier/:idClientAccountSupplier",
        component: EditSupplier,
        key: 'Edit_CoreAdminClientAdministrationSuppliers'
    }
];



export default clientAdminRoutes;
