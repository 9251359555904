import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getOutboundOrderTypes = createAsyncThunk(
    'outboundOrderType/fetchAll',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/outboundOrdertypes`);
            return response.data.outboundOrderTypes;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const outboundOrderTypeSlice = createSlice({
    name: 'outboundOrderType',
    initialState: {
        list: [],
        listLoading: undefined
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOutboundOrderTypes.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getOutboundOrderTypes.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload.sort((a, b) => a.displayOrder - b.displayOrder);
            })
            .addCase(getOutboundOrderTypes.rejected, state => {
                state.listLoading = undefined;
            })
    }
});

export default outboundOrderTypeSlice.reducer;