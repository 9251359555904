import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getGroups = createAsyncThunk(
    'groups/fetchAll',
    async ({idClient, idClientAccount}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/groups?pageSize=1000`);

            return response.data.clientAccountGroups;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getGroup = createAsyncThunk(
    'groups/fetchOne',
    async ({idClient, idClientAccount, idClientAccountGroup}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/groups/${idClientAccountGroup}`);
            return response.data.clientAccountGroup;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postGroup = createAsyncThunk(
    'groups/create',
    async ({idClient, idClientAccount, values}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/groups`, {
                enabled: values.enabled,
                idClientAccount: idClientAccount,
                groupName: values.groupName
            });
            return response.data.clientAccountGroup;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putGroup = createAsyncThunk(
    'groups/update',
    async ({idClient, idClientAccount, idClientAccountGroup, values}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/clients/${idClient}/accounts/${idClientAccount}/groups/${idClientAccountGroup}`, {
                idClientAccountGroup,
                idClientAccount,
                enabled: values.enabled,
                groupName: values.groupName
            });
            return response.data.clientAccountGroup;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteGroup = createAsyncThunk(
    'groups/delete',
    async ({idClient, idClientAccount, idClientAccountGroup}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/groups/${idClientAccountGroup}`);
            await dispatch(getGroups({idClient, idClientAccount}));
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const groupsSlice = createSlice({
    name: "groups",
    initialState: {
        list: [],
        listLoading: undefined,
        group: undefined,
        loading: undefined
    },
    reducers: {
        resetGroups: state => {
            state.list = [];
        },
        resetGroup: state => {
            state.group = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getGroups.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getGroups.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getGroups.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getGroup.pending, state => {
                state.loading = true;
            })
            .addCase(getGroup.fulfilled, (state, action) => {
                state.loading = undefined;
                state.group = action.payload;
            })
            .addCase(getGroup.rejected, state => {
                state.loading = undefined;
            })
            .addCase(putGroup.fulfilled, (state, action) => {
                state.group = action.payload;
            })
    }
});

export const {resetGroups, resetGroup} = groupsSlice.actions;

export default groupsSlice.reducer;