import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getAddressBookGroups = createAsyncThunk(
    'addressBookGroup/fetchAll',
    async ({idClient, idClientAccount, idAddressBook}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks/${idAddressBook}/groups`);
            return response.data.addressBookGroups;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postAddressBookGroup = createAsyncThunk(
    'addressBookGroup/create',
    async ({idClient, idClientAccount, idAddressBook, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks/${idAddressBook}/groups`, data)
            return response.data.addressBookGroup;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteAddressBookGroup = createAsyncThunk(
    'addressBookGroup/delete',
    async ({idClient, idClientAccount, idAddressBook, idClientAccountGroup}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks/${idAddressBook}/groups/${idClientAccountGroup}`);
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const addressBookGroupSlice = createSlice({
    name: 'addressBookGroup',
    initialState: {
        list: [],
        listLoading: false
    },
    reducers: {
        resetAddressBookGroups: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAddressBookGroups.pending, state => {
                state.listLoading = true;
            })
            .addCase(getAddressBookGroups.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addCase(getAddressBookGroups.rejected, state => {
                state.listLoading = false;
            })
    }
});

export const {resetAddressBookGroups} = addressBookGroupSlice.actions;

export default addressBookGroupSlice.reducer;