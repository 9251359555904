import React from "react";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {isEmpty} from "lodash";

const useTheme = () => {
    const adminSettings = useSelector(state => state.adminCustomizer.settings);
    const user = useSelector((state) => state.user);
    const [themeName, setThemeName] = useState('theme-slate-gray');

    const checkColor = (presetColor) => {
        try {
            let color = presetColor.trim().replace(' ', '-').toLowerCase()
            color = color === 'default' ? 'slate-gray' : color
            setThemeName('theme-' + color)
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (user && user.client && user.client.presetColor && isEmpty(adminSettings)) {
            checkColor(user.client.presetColor);
        }
        if (!isEmpty(adminSettings)) {
            checkColor(adminSettings.presetColor);
        }        
    }, [adminSettings, user]);

    return themeName;
}

export default useTheme;

export const withTheme = Component => {
    const ComponentWithTheme = props => {
        const themeName = useTheme();
        return React.createElement(Component, Object.assign({}, props, {
            themeName
        }));
    }

    const componentName = Component.displayName || Component.name || "Component";
    ComponentWithTheme.displayName = `withTheme(${componentName})`;
    return ComponentWithTheme;
}