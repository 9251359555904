import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {isEqual} from "lodash";

export const getAllAdminProductLabels = createAsyncThunk(
    'productLabel/fetchAllAdmin',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/products/labels');
            return response.data.labels;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getProductLabels = createAsyncThunk(
    'productLabel/fetchAll',
    async (idProduct, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/products/${idProduct}/labels`);
            return response.data.productLabels;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postProductLabel = createAsyncThunk(
    'productLabel/update',
    async ({idProduct, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/products/${idProduct}/labels`, data);
            return response.data.productLabel;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putProductLabel = createAsyncThunk(
    'productLabel/update',
    async ({idProduct, idProductLabel, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/products/${idProduct}/labels/${idProductLabel}`, data);
            return response.data.productLabel;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const productLabelSlice = createSlice({
    name: 'productLabel',
    initialState: {
        list: [],
        listAllAdmin: []
    },
    reducers: {
        resetAllAdminProductLabels: state => {
            state.listAllAdmin = [];
        },
        resetProductLabels: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAllAdminProductLabels.fulfilled, (state, action) => {
                state.listAllAdmin = action.payload;
            })
            .addCase(getProductLabels.fulfilled, (state, action) => {
                state.list = action.payload;
            })
    }
});

export const updateProductLabels = (idProduct, productLabels) => async (dispatch, getState) => {
    const oldLabels = getState().productLabel.list.reduce((accumulator, label) => {
        return {...accumulator, [label.idLabel]: {
                idProductLabel: label.idProductLabel,
                idLabel: label.idLabel,
                fromDate: label.fromDate,
                toDate: label.toDate,
                enabled: label.enabled
            }};
    }, {});

    const equal = isEqual(oldLabels, productLabels);

    if (!equal) {
        for (const idLabel in productLabels) {
            const productLabel = productLabels[idLabel];
            if (productLabel.idProductLabel) {
                await dispatch(putProductLabel({
                    idProduct,
                    idProductLabel: productLabel.idProductLabel,
                    data: {
                        ...productLabel,
                        idProduct
                    }
                }));
            } else {
                delete productLabel.idProductLabel;

                await dispatch(postProductLabel({
                    idProduct,
                    data: {
                        ...productLabel,
                        idProduct
                    }
                }))
            }
        }
    }
}

export const {resetAllAdminProductLabels, resetProductLabels} = productLabelSlice.actions;

export default productLabelSlice.reducer;