import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getInboundOrders = createAsyncThunk(
    'inboundOrders/fetchAll',
    async ({idBox, queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/inboundorders?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const getInboundOrder = createAsyncThunk(
    'inboundOrders/fetchOne',
    async ({idBox, idInboundOrder}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/inboundorders/${idInboundOrder}`);
            return response.data.inboundOrder;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const putInboundOrderProduct = createAsyncThunk(
    'inboundOrders/updateProduct',
    async ({idBox, data}, {rejectWithValue, getState, dispatch}) => {
        try {
            const idInboundOrder = getState().inboundOrders.order.idInboundOrder;

            for (const idInboundOrderProduct of data.products) {
                await limApi.put(`/box/boxs/${idBox}/inboundorders/${idInboundOrder}/products/${idInboundOrderProduct}`, {
                    idInboundOrderProduct,
                    receiptAccepted: data.receiptAccepted,
                    receiptComment: ""
                });
            }

            await dispatch(getInboundOrder({idBox, idInboundOrder}));
            await dispatch(getInboundOrders(idBox));
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const getInboundOrderStatusTypes = createAsyncThunk(
    'inboundOrders/fetchInboundOrderStatusTypes',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/inboundorderstatustypes`);
            return response.data.inboundOrderStatusTypes;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const initialState = {
    list: [],
    listLoading: undefined,
    order: null,
    orderLoading: undefined,
    statusTypesList: [],
    statusTypesListLoading: undefined
}

const inboundOrdersSlice = createSlice({
    name: 'inboundOrders',
    initialState,
    reducers: {
        resetInboundOrders: (state) => {
            state.list = [];
        },
        resetInboundOrder: (state) => {
            state.order = null;
        },
        resetInboundOrderStatusTypes: (state) => {
            state.statusTypesList = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInboundOrders.pending, (state, action) => {
                // this condition prevents the loading screen when updating the orders list after product reception
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getInboundOrders.fulfilled, (state, action) => {
                state.list = action.payload;
                state.listLoading = undefined;
            })
            .addCase(getInboundOrders.rejected, (state) => {
                state.listLoading = undefined;
            })
            .addCase(getInboundOrder.pending, (state) => {
                // this condition prevents the loading screen when updating the orders list after product reception
                if (!state.order) {
                    state.orderLoading = true;
                }
            })
            .addCase(getInboundOrder.fulfilled, (state, action) => {
                state.order = action.payload;
                state.orderLoading = undefined;
            })
            .addCase(getInboundOrder.rejected, (state) => {
                state.orderLoading = undefined;
            })
            .addCase(getInboundOrderStatusTypes.pending, state => {
                state.statusTypesListLoading = true;
            })
            .addCase(getInboundOrderStatusTypes.fulfilled, (state, action) => {
                state.statusTypesListLoading = undefined;
                state.statusTypesList = action.payload.filter((s) => s.activ).sort((a, b) => a.displayOrder - b.displayOrder);
            })
            .addCase(getInboundOrderStatusTypes.rejected, state => {
                state.statusTypesListLoading = undefined;
            })
    }
});

export const {resetInboundOrders, resetInboundOrder, resetInboundOrderStatusTypes} = inboundOrdersSlice.actions

export default inboundOrdersSlice.reducer;