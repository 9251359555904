import React from "react";
import { languageCodeOnly, supportedLanguages, changeLanguage } from "../../../i18n";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = ({ className, id }) => {
	const { i18n } = useTranslation();
	return (
		<select
			id={id}
			className={className}
			name="language"
			value={languageCodeOnly(i18n.language)}
			onChange={(e) => changeLanguage(e.target.value)}
		>
			{supportedLanguages.map((lang) => (
				<option key={lang.code} value={lang.code}>
					{lang.name}
				</option>
			))}
		</select>
	);
};

export default LanguageSwitcher;
