import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import limApi from "../../apis/limApi";
import {deleteCatalogGroup, getCatalogGroups, postCatalogGroup, putCatalogGroup} from "./catalogGroupSlice";
import {getEcommerceCatalogs} from "./ecommerceSlice";
import {chain, isEqual, filter, some} from "lodash";

// Use this only for amin catalogs list when is selected client account
export const getAllCatalogs = createAsyncThunk(
    'catalogs/fetchAllAdmin',
    async (_, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/all`);
            return response.data.catalogs;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getCatalogs = createAsyncThunk(
    'catalogs/fetchAll',
    async (_, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/catalogs?pageSize=10000`);
            return response.data.catalogs;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getCatalog = createAsyncThunk(
    'catalogs/fetchOne',
    async ({idClient, idClientAccount, idCatalog}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}`);
            return response.data.catalog;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postCatalog = createAsyncThunk(
    'catalogs/create',
    async (catalogObject, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.post(`/clients/${catalogObject.clientName}/accounts/${catalogObject.idClientAccount}/catalogs`, {
                catalogName: catalogObject.catalogName,
                displayPosition: catalogObject.catalogPosition,
                enabled: catalogObject.enabled
            });

            if (catalogObject.groupId.length > 0) {
                for (const groupId of catalogObject.groupId) {
                    await dispatch(postCatalogGroup({
                        idClient: catalogObject.clientName,
                        idClientAccount: catalogObject.idClientAccount,
                        idCatalog: response.data.catalog.idCatalog,
                        idClientAccountGroup: groupId,
                        enabled: catalogObject.enabled
                    }))
                }
            }

            await dispatch(getEcommerceCatalogs('?sort=[{"property":"displayPosition","direction":"asc"}]'));

            return response.data.catalog;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putCatalog = createAsyncThunk(
    'catalogs/update',
    async ({idCatalog, catalogObject}, {rejectWithValue, getState, dispatch}) => {
        try {
            const catalog = await limApi.put(`/clients/${catalogObject.clientName}/accounts/${catalogObject.accountName}/catalogs/${idCatalog}`, {
                idCatalog: idCatalog,
                catalogName: catalogObject.catalogName,
                displayPosition: catalogObject.catalogPosition,
                enabled: catalogObject.enabled
            })
                .then(response => response.data.catalog);

            const catalogGroups = getState().catalogGroup.list;

            const newCatalogGroups = catalogObject.groupId;
            const oldCatalogGroups = chain(catalogGroups)
                .filter((group) => group.enabled)
                .map((group) => group.idClientAccountGroup)
                .value();

            const equal = isEqual(oldCatalogGroups, newCatalogGroups);

            if (!equal) {
                const disableCatalogGroups = oldCatalogGroups.filter((old) => {
                    const exist = newCatalogGroups.includes(old);
                    if (!exist) {
                        return old;
                    }
                    return null;
                });

                for (const idClientAccountGroup of disableCatalogGroups) {
                    const filGroups = filter(catalogGroups, (group) => group.idClientAccountGroup === idClientAccountGroup);
                    if (filGroups.length > 0) {
                        const enabled = filGroups.find(group => group.enabled);
                        if (enabled) {
                            await dispatch(putCatalogGroup({
                                idClient: catalogObject.clientName,
                                idClientAccount: catalogObject.accountName,
                                idCatalog,
                                idClientAccountGroup,
                                idCatalogGroup: enabled.idCatalogGroup,
                                enabled: false
                            }));
                        }
                    }
                }

                const enableCatalogGroups = newCatalogGroups.filter((idCatalogGroup) => {
                    const exist = some(oldCatalogGroups, idCatalogGroup);
                    if (!exist) {
                        return idCatalogGroup;
                    }
                    return null;
                });

                for (const idClientAccountGroup of enableCatalogGroups) {
                    // check new groups exist in Client Account Catalog Group relation
                    const filGroups = filter(catalogGroups, (group) => group.idClientAccountGroup === idClientAccountGroup);

                    if (filGroups.length > 0) {
                        const disabled = filGroups.find(group => !group.enabled);
                        if (disabled) {
                            await dispatch(putCatalogGroup({
                                idClient: catalogObject.clientName,
                                idClientAccount: catalogObject.accountName,
                                idCatalog,
                                idClientAccountGroup,
                                idCatalogGroup: disabled.idCatalogGroup,
                                enabled: true
                            }));
                        }
                    } else {
                        await dispatch(postCatalogGroup({
                            idClient: catalogObject.clientName,
                            idClientAccount: catalogObject.accountName,
                            idCatalog,
                            idClientAccountGroup,
                            enabled: true
                        }))
                    }
                }
            }

            await dispatch(getEcommerceCatalogs('?sort=[{"property":"displayPosition","direction":"asc"}]'));

            await dispatch(getCatalogGroups({idClient: catalogObject.clientName, idClientAccount: catalogObject.accountName, idCatalog}));

            return catalog;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteCatalog = createAsyncThunk(
    'catalogs/delete',
    async ({idClient, idClientAccount, idCatalog }, {rejectWithValue, getState, dispatch}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}`)

            await dispatch(getAllCatalogs())

            await dispatch(getEcommerceCatalogs('?sort=[{"property":"displayPosition","direction":"asc"}]'));

            await dispatch(getCatalogGroups({idClient, idClientAccount, idCatalog}))

            const catalogGroups = getState().catalogGroup.list
            for (const item of catalogGroups) {
                await dispatch(deleteCatalogGroup({idClient, idClientAccount, idCatalog, idClientAccountGroup: item.idClientAccountGroup}))
            }

            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);


const catalogSlice = createSlice({
    name: "catalogs",
    initialState: {
        list: [],
        listAllAdmin: [],
        listLoading: undefined,
        catalog: null,
        loading: undefined,
        selectedCatalog: undefined
    },
    reducers: {
        resetCatalogs: state => {
            state.list = [];
            state.listAllAdmin = [];
        },
        resetCatalog: state => {
            state.catalog = null;
        },
        getSelectedCatalog: (state, action) => {
            state.selectedCatalog = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAllCatalogs.pending, state => {
                if (state.listAllAdmin.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getAllCatalogs.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.listAllAdmin = action.payload;
            })
            .addCase(getAllCatalogs.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getCatalogs.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getCatalogs.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getCatalogs.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getCatalog.pending, state => {
                state.loading = true;
            })
            .addCase(getCatalog.fulfilled, (state, action) => {
                state.loading = undefined;
                state.catalog = action.payload;
            })
            .addCase(getCatalog.rejected, state => {
                state.loading = undefined;
            })
            .addCase(putCatalog.fulfilled, (state, action) => {
                state.catalog = action.payload;
            })
    }
});

export const {resetCatalogs, resetCatalog, getSelectedCatalog} = catalogSlice.actions;

export default catalogSlice.reducer;