import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getPresetColors = createAsyncThunk(
    'presetColor/fetchAll',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/presetcolors');
            return response.data.presetColors;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const presetColorSlice = createSlice({
    name: 'presetColor',
    initialState: {
        list: [],
        listLoading: false
    },
    extraReducers: builder => {
        builder
            .addCase(getPresetColors.pending, state => {
                state.listLoading = true;
            })
            .addCase(getPresetColors.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload.sort((a, b) => a.displayPosition - b.displayPosition);
            })
            .addCase(getPresetColors.rejected, state => {
                state.listLoading = false;
            })
    }
});

export default presetColorSlice.reducer;