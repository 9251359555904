import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {updateClientAccountWarehouses} from "./clientAccountWarehouseSlice";
import {updateClientAccountFeatures} from "./clientAccountFeaturesSlice";
import {selectUserIdClient} from "./userSlice";

export const getAccounts = createAsyncThunk(
    'account/fetchAll',
    async (_, {rejectWithValue, getState}) => {
        let endpoint = '/clients/accounts';

        const idClient = selectUserIdClient(getState());

        if (idClient) {
            endpoint = `/clients/${idClient}/accounts`;
        }

        try {
            const response = await limApi.get(`${endpoint}?pageSize=10000`);
            return response.data.accounts;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getAccount = createAsyncThunk(
    'account/fetchOne',
    async ({idClient, idClientAccount}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}`);
            return response.data.account;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postAccount = createAsyncThunk(
    'account/create',
    async ({idClient, accountData}, {rejectWithValue}) => {
        try {
            delete accountData.idClientAccount
            const response = await limApi.post(`/clients/${idClient}/accounts`, accountData);
            return response.data.account;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putAccount = createAsyncThunk(
    'account/update',
    async ({idClient, idClientAccount, accountData}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.put(`/clients/${idClient}/accounts/${idClientAccount}`, accountData);
            await dispatch(updateClientAccountWarehouses(idClient, idClientAccount, accountData.clientAccountWarehouses));
            await dispatch(updateClientAccountFeatures(idClientAccount, accountData.clientAccountFeatures));

            return response.data.account;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteAccount = createAsyncThunk(
    'account/delete',
    async ({idClient, idClientAccount}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}`);
            await dispatch(getAccounts());
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const accountSlice = createSlice({
    name: 'account',
    initialState: {
        list: [],
        listLoading: false,
        account: null,
        loading: false
    },
    reducers: {
        resetAccounts: state => {
            state.list = [];
        },
        resetAccount: state => {
            state.account = null;
        }
    },

    extraReducers: builder => {
        builder
            .addCase(getAccounts.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getAccounts.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addCase(getAccounts.rejected, state => {
                state.listLoading = false;
            })
            .addCase(getAccount.pending, state => {
                state.loading = true;
            })
            .addCase(getAccount.fulfilled, (state, action) => {
                state.loading = false;
                state.account = action.payload;
            })
            .addCase(getAccount.rejected, state => {
                state.loading = false;
            })
            .addCase(putAccount.fulfilled, (state, action) => {
                state.account = action.payload;
            })
    }
});

export const {resetAccounts, resetAccount} = accountSlice.actions;

export default accountSlice.reducer;