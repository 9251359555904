import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import {ErrorMessage, useField} from "formik";
import TextError from "../TextError";

/**
 * ColorPicker component for selecting colors.
 *
 * @param {Object} props - The component props.
 * @param {string} props.label - The label for the color picker.
 * @param {string} props.name - The name of the color picker.
 * @param {string} props.groupClass - Additional CSS class for the form group.
 * @param {string} props.className - Additional CSS class for the color picker input field.
 * @param {boolean} props.touched - Indicates if the color picker has been touched.
 * @param {string} props.errors - The error message for the color picker.
 * @param {ReactNode} props.children - The child components of the color picker.
 * @param {boolean} props.required - Indicates if the color picker is required.
 * @returns {JSX.Element} The ColorPicker component.
 */
const ColorPicker = (props) => {
    const {label, name, groupClass, className, touched, errors, children, required} = props;
    const classes = `form-group ${groupClass ? groupClass : ""}`;
    const fieldClasses = "form-control " + className + `${touched && errors ? " is-invalid" : ""}`;
    const [field, , helpers] = useField(props);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleColorChange = (color) => {
        helpers.setValue(color.hex);
    };

    const toggleColorPicker = () => {
        setDisplayColorPicker(prevDisplayColorPicker => !prevDisplayColorPicker);
    };

    return (
        <div className={classes}>
            { label && <label htmlFor={name}>{label} { required && <span className="text-danger">*</span> }</label>}
            <div
                onClick={toggleColorPicker}
                className={fieldClasses}
                style={{
                    // width: '100%',
                    // height: 'calc(1.9695rem + 2px)',
                    // borderRadius: '4px',
                    background: field.value,
                    cursor: 'pointer',
                    // border: '1px solid #ccc', // Border style
                    // padding: '8px', // Optional: Add padding for aesthetics
                }}
            />
            {displayColorPicker && (
                <div style={{ position: 'absolute', top: '42px', left: '0', zIndex: '2' }}>
                    <div
                        style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }}
                        onClick={toggleColorPicker}
                    />
                    <SketchPicker
                        color={field.value}
                        onChange={handleColorChange}
                    />
                </div>
            )}
            <ErrorMessage component={TextError} name={name}/>
            {children}
        </div>
    );
};

export default ColorPicker;
