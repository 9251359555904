import React from "react";
import {ErrorMessage, Field} from "formik";
import TextError from "../TextError";

const InputAppend = (props) => {
    const {label, name, options, groupClass, className, touched, errors, children, required, append,  ...rest} = props;
    const classes = `form-group ${groupClass ? groupClass : ""}`;
    const fieldClasses = "form-control " + className + `${touched && errors ? " is-invalid" : ""}`;

    return (
        <div className={classes}>
            { label && <label htmlFor={name}>{label} { required && <span className="text-danger">*</span> }</label>}
            <div className="input-group bg-transparent">
                <Field id={name} name={name} className={fieldClasses} {...rest}>
                    {options && options.map((option) => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        );
                    })}
                </Field>
                <div className="input-group-append rounded-right">
                    {append}
                </div>
                <ErrorMessage component={TextError} name={name}/>
            </div>
            {children}
        </div>
    );
};

export default InputAppend