import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getImage = createAsyncThunk(
    'image/fetchOne',
    async (idImage, {rejectWithValue, requestId, getState }) => {
        const { currentRequestId, loading } = getState().image;

        if (!loading || requestId !== currentRequestId) {
            return;
        }

        try {
            const response = await limApi.get(`/images/${idImage}`);
            return {
                idImage,
                data:  response.data
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
    {
        condition(idImage, {getState}) {
            const { image } = getState();
            const fetchStatus = image.images[idImage];

            if (fetchStatus) {
                // Already fetched or in progress, don't need to re-fetch
                return false;
            }
        }
    }
);

const imageSlice = createSlice({
    name: 'image',
    initialState: {
        images: {},
        loading: false,
        currentRequestId: undefined
    },
    reducers: {
        unsetImagesById: (state, action) => {
            state.images = Object.keys(state.images)
                .filter(key => !action.payload.includes(key))
                .reduce((obj, key) => {
                    obj[key] = state.images[key];
                    return obj;
                }, {})
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getImage.pending, (state, action) => {
                state.loading = true;
                state.currentRequestId = action.meta.requestId;
            })
            .addCase(getImage.fulfilled, (state, action) => {
                state.images[action.payload.idImage] = action.payload.data;
                state.loading = false;
                state.currentRequestId = undefined;
            })
            .addCase(getImage.rejected, state => {
                state.loading = false;
                state.currentRequestId = undefined;
            })
    }
});

export const reFetchImagesById = (images) => async (dispatch) => {
   await dispatch(unsetImagesById(images));
   await Promise.all(images.map(idImage => dispatch(getImage(idImage))));
}

export const {unsetImagesById} = imageSlice.actions;

export default imageSlice.reducer;