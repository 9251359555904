import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getShippingMethodGroups = createAsyncThunk(
    'shippingMethodGroup/fetchAll',
    async ({idClient, idClientAccount, idShippingMethod}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/shippingmethod/${idShippingMethod}/groups`);
            return response.data.shippingMethodUserGroups;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postShippingMethodGroup = createAsyncThunk(
    'shippingMethodGroup/create',
    async ({idClient, idClientAccount, idShippingMethod, data}, {rejectWithValue,}) => {
        try {
            const response = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/shippingmethod/${idShippingMethod}/groups`, data);
            return response.data.shippingMethodUserGroup;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteShippingMethodGroup = createAsyncThunk(
    'shippingMethodGroup/delete',
    async ({idClient, idClientAccount, idShippingMethod, idClientAccountGroup}, {rejectWithValue,}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/shippingmethod/${idShippingMethod}/groups/${idClientAccountGroup}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

// idShippingMethodUserGroup

const shippingMethodGroupSlice = createSlice({
    name: 'shippingMethodGroup',
    initialState: {
        list: [],
        listLoading: false
    },
    reducers: {
        resetShippingMethodGroups: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getShippingMethodGroups.pending, state => {
                state.listLoading = true;
            })
            .addCase(getShippingMethodGroups.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addCase(getShippingMethodGroups.rejected, state => {
                state.listLoading = false;
            })
    }
});

export const {resetShippingMethodGroups} = shippingMethodGroupSlice.actions;

export default shippingMethodGroupSlice.reducer;