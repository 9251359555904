import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getAddressBookAddresses = createAsyncThunk(
    'addressBookAddress/fetchAll',
    async ({idClient, idClientAccount, idAddressBook, queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks/${idAddressBook}/addresses?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getAddressBookAddress = createAsyncThunk(
    'addressBookAddress/fetchOne',
    async ({idClient, idClientAccount, idAddressBook, idAddressBookAddress}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks/${idAddressBook}/addresses/${idAddressBookAddress}`)
            return response.data.addressBookAddress;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postAddressBookAddresses = createAsyncThunk(
    'addressBookAddress/create',
    async ({idClient, idClientAccount, idAddressBook, addressBookAddress}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks/${idAddressBook}/addresses`, addressBookAddress);
            return response.data.addressBookAddress;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putAddressBookAddress = createAsyncThunk(
    'addressBookAddress/update',
    async ({idClient, idClientAccount, idAddressBook, idAddressBookAddress, addressBookAddress}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.put(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks/${idAddressBook}/addresses/${idAddressBookAddress}`, addressBookAddress);
            return response.data.addressBookAddress;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteAddressBookAddress = createAsyncThunk(
    'addressBookAddress/delete',
    async ({idClient, idClientAccount, idAddressBook, idAddressBookAddress}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks/${idAddressBook}/addresses/${idAddressBookAddress}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const addressBookAddressSlice = createSlice({
    name: "addressBookAddress",
    initialState: {
        list: [],
        listLoading: undefined,
        address: undefined,
        loading: undefined,
        editAddressBookAddressId: undefined,
        showModal: undefined
    },
    reducers: {
        setEditAddressBookAddressId: (state, action) => {
            state.editAddressBookAddressId = action.payload;
        },
        setShowAddressModal: (state, action) => {
            state.showModal = action.payload;
        },
        resetAddressBookAddresses: state => {
            state.list = [];
        },
        resetAddressBookAddress: state => {
            state.address = undefined;
            state.editAddressBookAddressId = undefined;
        },
        triggerFetchListData: (state, action) => {
            state.fetchListData = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAddressBookAddresses.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getAddressBookAddresses.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getAddressBookAddresses.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getAddressBookAddress.pending, state => {
                state.loading = true;
            })
            .addCase(getAddressBookAddress.fulfilled, (state, action) => {
                state.loading = undefined;
                state.address = action.payload;
            })
            .addCase(getAddressBookAddress.rejected, state => {
                state.loading = undefined;
            })
            .addCase(putAddressBookAddress.fulfilled, (state, action) => {
                state.address = action.payload;
            })
    }
});

export const {setEditAddressBookAddressId, setShowAddressModal, resetAddressBookAddresses, resetAddressBookAddress, triggerFetchListData} = addressBookAddressSlice.actions;

export default addressBookAddressSlice.reducer;