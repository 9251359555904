import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getProductDocuments = createAsyncThunk(
    "productDocument/fetchAll",
    async (idProduct, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/products/${idProduct}/documents`);
            return response.data.documents;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postProductDocument = createAsyncThunk(
    "productDocument/create",
    async ({idProduct, documentBase64, values}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/products/${idProduct}/documents`,{
                idProduct: idProduct,
                document: `${documentBase64}`,
                documentDescription: values.fileTitle,
                documentName: values.name,
                documentFileName: values.name,
                documentSize: parseInt(values.size),
                displayPosition: values.displayPosition,
                fileType: values.ext
            });

            return response.data.document;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putProductDocument = createAsyncThunk(
    "productDocument/update",
    async ({idProduct, idProductDocument, documentBase64, values}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/products/${idProduct}/documents/${idProductDocument}`,{
                idProduct,
                idProductDocument,
                idDocument: values.fileKey,
                document: documentBase64,
                documentDescription: values.fileTitle,
                documentName: values.name,
                documentFileName: values.name,
                documentSize: parseInt(values.size),
                displayPosition: values.displayPosition,
                fileType: values.ext
            });

            return response.data.document;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProductDocument = createAsyncThunk(
    "productDocument/delete",
    async ({idProduct, idProductDocument}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/products/${idProduct}/documents/${idProductDocument}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const productDocumentSlice = createSlice({
    name: "productDocument",
    initialState: {
        list: []
    },
    reducers: {
        resetProductDocuments: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProductDocuments.fulfilled, (state, action) => {
                state.list = action.payload;
            })
    }
});

export const updateProductDocuments = (idProduct, documents) => async (dispatch, getState) => {
    const productDocuments = getState().productDocument.list;

    const newDocuments = documents.filter(doc => doc.isNew);
    const existingDocuments = documents.filter(doc => !doc.isNew && productDocuments.length > 0);

    const newDocumentPromises = newDocuments.map(doc => {
        return dispatch(postProductDocument({
            idProduct,
            documentBase64: doc.base64.split(',')[1],
            values: doc
        }));
    });

    const existingDocumentPromise = existingDocuments.map(doc => {
        const documentDetails = productDocuments.find(item => item.idDocument === doc.fileKey);
        if (documentDetails &&
            (
                documentDetails.displayPosition !== doc.displayPosition ||
                documentDetails.documentName !== doc.fileTitle ||
                documentDetails.documentFileName !== doc.name
            )
        ) {
            return dispatch(putProductDocument({
                idProduct: documentDetails.idProduct,
                idProductDocument: documentDetails.idProductDocument,
                documentBase64: doc.base64.split(',')[1],
                values: doc
            }));
        }
    });

    const productDocumentKeys = productDocuments.map(value => value.idDocument);
    const fieldDocumentKeys = documents.map(value => value.fileKey);
    const deletedDocuments = productDocumentKeys.filter(x => !fieldDocumentKeys.includes(x));

    const deletePromises = deletedDocuments.map(idDocument => {
        const deletedDocument = productDocuments.find(item => item.idDocument === idDocument);
        if (deletedDocument) {
            return dispatch(deleteProductDocument({idProduct: deletedDocument.idProduct, idProductDocument: deletedDocument.idProductDocument}));
        }
    });

    await Promise.all([...newDocumentPromises, ...existingDocumentPromise, ...deletePromises]);
}

export const {resetProductDocuments} = productDocumentSlice.actions;

export default productDocumentSlice.reducer;