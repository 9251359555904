import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getQuotaHierarchy = createAsyncThunk(
    'quotaHierarchy/fetchOne',
    async ({idClientAccount, idQuota}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/quota/${idQuota}/hierarchy`);
            return response.data.quotaHierarchy;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postQuotaHierarchy = createAsyncThunk(
    'quotaHierarchy/create',
    async ({idClientAccount, idQuota, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/client/account/${idClientAccount}/quota/${idQuota}/hierarchy`, {quotaHierarchy: {...data}});
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putQuotaHierarchy = createAsyncThunk(
    'quotaHierarchy/update',
    async ({idClientAccount, idQuota, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/client/account/${idClientAccount}/quota/${idQuota}/hierarchy`, {quotaHierarchy: {...data}});
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getQuotaHierarchyModel = createAsyncThunk(
    'quotaHierarchy/fetchHierarchyModel',
    async ({idClientAccount, idHierarchyModel}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/quota/hierarchy/${idHierarchyModel}`);
            return response.data.quotaHierarchy;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const quotaHierarchySlice = createSlice({
    name: "quotaHierarchy",
    initialState: {
        tree: [],
        loading: false,
        hierarchyModelLoading: false,
    },
    reducers: {
        resetQuotaHierarchy: state => {
            state.tree = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getQuotaHierarchy.pending, state => {
                state.loading = true;
            })
            .addCase(getQuotaHierarchy.fulfilled, (state, action) => {
                state.loading = false;
                state.tree = [action.payload];
            })
            .addCase(getQuotaHierarchy.rejected, state => {
                state.loading = false;
            })
            .addCase(putQuotaHierarchy.fulfilled, (state, action) => {
                state.tree = [action.payload];
            })
            .addCase(getQuotaHierarchyModel.pending, state => {
                state.hierarchyModelLoading = true;
            })
            .addCase(getQuotaHierarchyModel.fulfilled, (state, action) => {
                state.hierarchyModelLoading = false;
                state.tree = [action.payload]
            })
            .addCase(getQuotaHierarchyModel.rejected, state => {
                state.hierarchyModelLoading = false;
            })
    }
});

export const {resetQuotaHierarchy} = quotaHierarchySlice.actions;

export default quotaHierarchySlice.reducer;