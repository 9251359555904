import {createListenerMiddleware, isAnyOf} from "@reduxjs/toolkit";
import {selectUserEventAccount, selectUserIdClientAccount} from "../slices/userSlice";
import {
    addCartItem,
    getCart,
    removeCartItem,
    updateCartItem
} from "../slices/cartSlice";
import {setActiveWarehouse} from "../slices/warehouseSlice";
import {completeCheckout, setCheckoutData, updateCheckout} from "../slices/checkoutSlice";
import {setAppNotification} from "../slices/globalSlice";
import {cloneDeep} from "lodash";
import {setActiveEvent} from "../slices/eventsSlice";

const cartCheckoutListenerMiddleware = createListenerMiddleware();

cartCheckoutListenerMiddleware.startListening({
    matcher: isAnyOf(setActiveWarehouse.fulfilled, addCartItem.fulfilled, updateCartItem.fulfilled, removeCartItem.fulfilled, completeCheckout.fulfilled, setActiveEvent),
    effect: async (action, {cancelActiveListeners, delay, dispatch, getState}) => {
        // Cancel any in-progress instances of this listener
        cancelActiveListeners()

        // Delay before starting actual work
        await delay(250);
        const idClientAccount = selectUserIdClientAccount(getState());
        const activeWarehouse = getState().warehouse.active;

        if (idClientAccount && activeWarehouse) {
            const eventAccount = selectUserEventAccount(getState());
            const activeEvent = getState().events.active;

            // don't get cart if there is no active event
            // this will prevent empty warehouse carts that will never used
            if (eventAccount && !activeEvent) return;

            const cartParams = {
                idClientAccount,
                idWarehouse: activeWarehouse.idWarehouse,
                type: eventAccount && activeEvent ? "EVENT" : activeWarehouse.warehouseType,
                idEvent: eventAccount && activeEvent ? activeEvent.idEvent : undefined
            }

            await dispatch(getCart(cartParams));
        }
    }
});

cartCheckoutListenerMiddleware.startListening({
    matcher: isAnyOf(setCheckoutData),
    effect: async (action, {getState, dispatch} ) => {
        const checkout = cloneDeep(getState().checkout);
        checkout.addresses = checkout.selectedAddresses.map(({idAddressBookAddress}) => idAddressBookAddress)
        checkout.deliveryDate = checkout.deliveryDate || null
        // remove helper shippingMethod property from request object
        delete checkout.shippingMethod;

        await dispatch(updateCheckout({idCart: checkout.idCart, data: checkout}))
    }
});

cartCheckoutListenerMiddleware.startListening({
    matcher: isAnyOf(setActiveWarehouse.rejected, addCartItem.rejected, updateCartItem.rejected, removeCartItem.rejected, updateCheckout.rejected),
    effect: async (_, {dispatch}) => {
        dispatch(setAppNotification({message: "Something happened", type: "error"}));
    }
});

export default cartCheckoutListenerMiddleware;