import { lazy } from "react";

const ProductList = lazy(() => import("./catalog/ProductList"));
const ProductDetails = lazy(() => import("./catalog/ProductDetails"));
const Cart = lazy(() => import("./cart/Cart"));
const Checkout = lazy(() => import("./checkout/Checkout"));
const Complete = lazy(() => import("./checkout/Complete"));


const ecommerceRoutes = [
  {
    path: "/catalog/:idCatalog/category/:idCategory",
    component: ProductList,
    key: "View_CatalogCatalogCatalogCategory",
    exact: true
  },
  {
    path: "/product/:idProduct",
    component: ProductDetails,
    key: "View_CatalogCatalogCatalogCategory",
    exact: true
  },
  {
    path: "/catalog/:idCatalog/category/:idCategory/product/:idProduct",
    component: ProductDetails,
    key: "View_CatalogCatalogCatalogCategory",
    exact: true
  },
  {
    path: "/checkout/cart",
    component: Cart,
    key: "View_CoreGeneralProductCart"
  },
  {
    path: "/checkout",
    component: Checkout,
    exact: true,
    key: "View_CoreGeneralCheckout"
  },
  {
    path: "/checkout/:idOrder/complete",
    component: Complete,
    key: "View_CoreGeneralCheckout"
  }
];

export default ecommerceRoutes;
