import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import {productTypes} from "../../views/app/catalog-admin/products/form-model/formInitialValues";

export const postStockRegularizationDocumentProduct = createAsyncThunk(
    'stockRegularizationDocumentProduct/create',
    async ({idBox, idStockRegularizationDocument, product}, {rejectWithValue}) => {
        const data = {
            idStockRegularizationDocument,
            idProduct: product.idProduct,
            quantity: product.quantity,
            bestBeforeDate: product.bestBeforeDate instanceof Date ? product.bestBeforeDate : null,
            lotNumber: product.lotNumber,
            serialNumber: product.serialNumber
        }

        try {
            const response = await limApi.post(`/box/boxs/${idBox}/stockregularizationdocuments/${idStockRegularizationDocument}/products`, data)
            return response.data.stockRegularizationDocumentProduct;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const stockRegularizationDocumentSearchProduct = createAsyncThunk(
    'stockRegularizationDocumentProduct/searchProduct',
    async (query, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products/search/${query}?types=${productTypes['simple'].value}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getStockRegulationProductBoxStock = createAsyncThunk(
    'stockRegularizationDocumentProduct/fetchBoxStock',
    async ({idBox, idProduct}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/stocks/${idProduct}`);
            return response.data.boxStock;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const stockRegularizationDocumentProductSlice = createSlice({
    name: "stockRegularizationDocumentProduct",
    initialState: {
        list: [],
        listLoading: false,
        products: [],
        productsBoxStock: [],
        productsSuggestions: [],
        suggestionsLoading: false
    },
    reducers: {
        stockRegularizationDocumentProductSuggestionsReset: state => {
            state.productsSuggestions = [];
        },
        stockRegularizationDocumentProductsBoxStockReset: state => {
            state.productsBoxStock = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(stockRegularizationDocumentSearchProduct.pending, (state) => {
                state.suggestionsLoading = true;
            })
            .addCase(stockRegularizationDocumentSearchProduct.fulfilled, (state, action) => {
                state.productsSuggestions = action.payload;
                state.suggestionsLoading = false;
            })
            .addCase(stockRegularizationDocumentSearchProduct.rejected, (state) => {
                state.suggestionsLoading = false;
            })
            .addCase(getStockRegulationProductBoxStock.fulfilled, (state, action) => {
                if (action.payload && action.payload.idProduct) {
                    state.productsBoxStock.push(action.payload)
                }
            })
    }
});

export const {stockRegularizationDocumentProductSuggestionsReset, stockRegularizationDocumentProductsBoxStockReset} = stockRegularizationDocumentProductSlice.actions;

export default stockRegularizationDocumentProductSlice.reducer;