import React, {useEffect} from 'react';
import Zendesk, {ZendeskAPI} from "react-zendesk";
import * as JWT from "jsonwebtoken";
import {useSelector} from "react-redux";
import crypto from "crypto";
import {useTranslation} from "react-i18next";
import {replace} from "lodash";
import {languageCodeOnly} from "../../i18n";
import {selectUserIdClient} from "../redux/slices/userSlice";

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
const ZENDESK_WEB_WIDGET_SSO_SHARED_SECRET = process.env.REACT_APP_ZENDESK_WEB_WIDGET_SSO_SHARED_SECRET;
const ZENDESK_WEB_WIDGET_SSO_SHARED_SECRET_HC = process.env.REACT_APP_ZENDESK_WEB_WIDGET_SSO_SHARED_SECRET_HC;

let zESettings;

const WebWidget = () => {
    const now = Math.floor(Date.now() / 1000);
    const user = useSelector(state => state.user);
    const userIdClient = useSelector(selectUserIdClient);
    const { i18n } = useTranslation();

    useEffect(() => {
        ZendeskAPI('webWidget', 'helpCenter:setSuggestions', { url: true });
        ZendeskAPI('webWidget', 'helpCenter:reauthenticate');
        ZendeskAPI('webWidget', 'chat:reauthenticate');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.href])

    if (user.idUser) {
        const clientName = user.accounts.find(account => account.idClient === userIdClient)?.clientName;

        const jti = crypto.createHash('md5').update(`${now}${Math.random()}`).digest('hex');
        const tags = clientName ? [replace(clientName, /\s+/g, '_')] : [];

        const payload = {
            iat: now,
            jti,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            external_id: user.idUser,
            tags
        };

        const jwt = JWT.sign(payload, ZENDESK_WEB_WIDGET_SSO_SHARED_SECRET);

        const jwtHc = JWT.sign(payload, ZENDESK_WEB_WIDGET_SSO_SHARED_SECRET_HC);

        zESettings = {
            webWidget: {
                authenticate: {
                    jwtFn: function(callback) {
                        callback(jwtHc); // Help center authenticate
                    },
                    chat: {
                        jwtFn: function(callback) {
                            callback(jwt);
                        }
                    }
                },
                contactForm: {
                    fields: [
                        { id: "name", prefill: { '*': payload.name }},
                        { id: "email", prefill: { '*': payload.email }}
                    ]
                }
            },

        };

        ZendeskAPI("webWidget", "prefill", {
            name: {
                value: payload.name,
                readOnly: true // optional
            },
            email: {
                value: payload.email,
                readOnly: true // optional
            }
        });

        ZendeskAPI("webWidget", "identify", {
            name: payload.name,
            email: payload.email,
            // organization: 'WAPI Bulgaria'
        });

        if (!window.zESettings) {
            ZendeskAPI('webWidget', 'chat:addTags', tags);
        }

        ZendeskAPI(() => {
            ZendeskAPI('webWidget', 'updateSettings', {
                webWidget: {
                    authenticate: {
                        jwtFn: function(callback) {
                            callback(jwtHc); // Help center authenticate
                        },
                        chat: {
                            jwtFn: function(callback) {
                                callback(jwt);
                            }
                        }
                    }
                },
            });
        });
        // ZendeskAPI('webWidget', 'helpCenter:reauthenticate')
        // ZendeskAPI('webWidget', 'chat:reauthenticate'); // can be use if chat authentication expire during the user session
    }
    

    // if (!zESettings) {
    //     return null;
    // }

    if (i18n.language) {
        ZendeskAPI("webWidget", "setLocale", languageCodeOnly(i18n.language));
    }

    return (
        <>
            <Zendesk
                defer
                zendeskKey={ZENDESK_KEY}
                {...zESettings}
                onLoaded={() => {}}
            />
        </>
    );
};

export default WebWidget;
