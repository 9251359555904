import {lazy} from "react";

const QuotaList = lazy(() =>
    import("./QuotaList")
);
const EditQuota = lazy(() =>
    import("./EditQuota")
);

const quotasRoutes = [
    {
        path: "/quotas/list",
        component: QuotaList,
        exact: true,
        key: "View_QuotasQuotas"
    },
    {
        path: "/quotas/:idQuota/account/:idClientAccount",
        component: EditQuota,
        exact: true,
        key: "Edit_QuotasQuotas"
    },
];

export default quotasRoutes;