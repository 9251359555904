import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {RECORD_STATUS_NEW} from "../../apis/limApi";
import {assignDepthChildren} from "../../../@utils";

export const getHierarchy = createAsyncThunk(
    'hierarchy/fetchOne',
    async ({idClientAccount, idHierarchyModel}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/hierarchy/model/${idHierarchyModel}/tree`);
            return response.data.hierarchy;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postHierarchy = createAsyncThunk(
    'hierarchy/create',
    async ({idClientAccount, idHierarchyModel, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/client/account/${idClientAccount}/hierarchy/model/${idHierarchyModel}/tree`, {hierarchy: {...data}});
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putHierarchy = createAsyncThunk(
    'hierarchy/update',
    async ({idClientAccount, idHierarchyModel, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/client/account/${idClientAccount}/hierarchy/model/${idHierarchyModel}/tree`, {hierarchy: {...data}});
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const hierarchySlice = createSlice({
    name: "hierarchy",
    initialState: {
        tree: [],
        loading: false
    },
    reducers: {
        resetHierarchy: state => {
            state.tree = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getHierarchy.pending, state => {
                state.loading = true;
            })
            .addCase(getHierarchy.fulfilled, (state, action) => {
                state.loading = false;
                state.tree = assignDepthChildren([action.payload || {children: [], recordStatus: RECORD_STATUS_NEW}]);
            })
            .addCase(getHierarchy.rejected, state => {
                state.loading = false;
            })
            .addCase(putHierarchy.fulfilled, (state, action) => {
                state.tree = [action.payload];
            })
    }
});

export const {resetHierarchy} = hierarchySlice.actions;

export default hierarchySlice.reducer;