import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {quotaTypes, validPeriodTypes} from "../../views/app/catalog-admin/quota/form-model/formInitialValues";
import moment from "moment/moment";
import {getQuotaHierarchy, postQuotaHierarchy, putQuotaHierarchy} from "./quotaHierarchySlice";
import {getQuotaPersonal, postQuotaPersonal, putQuotaPersonal} from "./quotaPersonalSlice";
import {getQuotaUserGroup, putQuotaUserGroup} from "./quotaUserGroupSlice";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import {productTypes} from "../../views/app/catalog-admin/products/form-model/formInitialValues";

export const getQuotas = createAsyncThunk(
    'quota/fetchAll',
    async ({idClientAccount, queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/quota?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getQuota = createAsyncThunk(
    'quota/fetchOne',
    async ({idClientAccount, idQuota}, {rejectWithValue, dispatch}) => {
        try {
            const quota = await limApi.get(`/client/account/${idClientAccount}/quota/${idQuota}`).then(response => response.data.quota);

            if (quota && quota.idHierarchyModel && quotaTypes[quota.quotaType] === quotaTypes[1]) {
                await dispatch(getQuotaHierarchy({idClientAccount, idQuota}))
                    .unwrap()
                    .then(hierarchy => {
                        if (hierarchy) {
                            quota.quotaTree = [hierarchy];
                        } else {
                            quota.quotaTree = [];
                        }
                    });
            }

            if (quota && quota.idUserGroup && quotaTypes[quota.quotaType] === quotaTypes[2]) {
                await dispatch(getQuotaUserGroup({idClientAccount, idQuota}))
                    .unwrap()
                    .then(group => {
                        if (group) {
                            quota.group = group;
                        } else {
                            quota.group = [];
                        }
                    })
            }

            if (quota && quotaTypes[quota.quotaType] === quotaTypes[3]) {
                await dispatch(getQuotaPersonal({idClientAccount, idQuota}))
                    .unwrap()
                    .then(personal => {
                        if (personal.length ) {
                            quota.user = personal[0];
                        } else {
                            quota.user = null;
                        }
                    });
            }

            return quota;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postQuota = createAsyncThunk(
    'quota/create',
    async ({idClientAccount, data}, {rejectWithValue, dispatch}) => {
        try {
            if (parseInt(data.validPeriodType) === validPeriodTypes.annual.value) {
                data.validTo = moment(data.validTo).utcOffset(0, true).startOf('day').toISOString()
            } else if (validPeriodTypes.custom.value) {
                data.validTo = moment(data.validTo).utcOffset(0, true).endOf('day').toISOString()
            }

            const quota = await limApi.post(`/client/account/${idClientAccount}/quota`, data).then(response => response.data.quota);
            if (data.quotaTree && data.quotaTree.length) {
                await dispatch(postQuotaHierarchy({idClientAccount, idQuota: quota.idQuota, data: data.quotaTree[0]}));
            }

            if (data.idUserGroup && data.group && data.group.length) {
                await dispatch(putQuotaUserGroup({idClientAccount, idQuota: quota.idQuota, idClientAccountGroup: data.idUserGroup, data: data.group}));
            }

            if (data.user) {
                await dispatch(postQuotaPersonal({idClientAccount, idQuota: quota.idQuota, data: data.user}));
            }

            return quota;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putQuota = createAsyncThunk(
    'quota/update',
    async ({idClientAccount, idQuota, data}, {rejectWithValue, dispatch}) => {
        try {
            // if (parseInt(data.validPeriodType) === validPeriodTypes.annual.value) {
            //     data.validTo = moment(data.validTo).utcOffset(0, true).startOf('day').toISOString()
            // } else if (validPeriodTypes.custom.value) {
            //     data.validTo = moment(data.validTo).utcOffset(0, true).endOf('day').toISOString()
            // }
            //
            await limApi.put(`/client/account/${idClientAccount}/quota/${idQuota}`, {
                enabled: data.enabled,
                idQuota,
                idClientAccount,
                priority: data.priority
            })
                .catch(e => {
                    throw Error(e.message);
                });
            if (data.quotaTree && data.quotaTree.length && quotaTypes[data.quotaType] === quotaTypes[1]) {
                await dispatch(putQuotaHierarchy({idClientAccount, idQuota, data: data.quotaTree[0]}))
                    .unwrap()
                    .catch(e => {
                        throw Error(e?.response?.data || e.message);
                    });
            }

            if (data.group && data.group.length) {
                await dispatch(putQuotaUserGroup({idClientAccount, idQuota, idClientAccountGroup: data.idUserGroup, data: data.group}));
            }

            if (data.user && quotaTypes[data.quotaType] === quotaTypes[3]) {
                await dispatch(putQuotaPersonal({idClientAccount, idQuota, idQuotaPersonal: data.user.idQuotaPersonal, data: data.user}));
            }

            await dispatch(getQuota({idClientAccount, idQuota}))
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteQuota = createAsyncThunk(
    'quota/delete',
    async ({idClientAccount, idQuota}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/client/account/${idClientAccount}/quota/${idQuota}`);
            await dispatch(getQuotas(idClientAccount));
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const quotaSearchProduct = createAsyncThunk(
    'quota/searchProduct',
    async (query, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products/search/${query}?types=${productTypes['simple'].value}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const quotaGetProductStock = createAsyncThunk(
    'quota/getProductStock',
    async ({idProduct, activeWarehouse}, {rejectWithValue}) => {
        try {
            let url = `/products/${idProduct}/stocks`;

            if (activeWarehouse.warehouseType === 'WAREHOUSE') {
                url += `?idWarehouse=${activeWarehouse.idWarehouse}`;
            }
            const response = await limApi.get(url);
            return response.data.productStocks;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const initialState = {
    list: [],
    listLoading: false,
    quota: null,
    loading: false,
    productsSuggestions: [],
    suggestionsLoading: false,
    productStock: 0
};

const quotaSlice = createSlice({
    name: 'quota',
    initialState,
    reducers: {
        resetQuotaList: state => {
            state.list = [];
        },
        resetQuota: state => {
            state.quota = null;
        },
        quotaProductsSuggestionsReset: state => {
            state.productsSuggestions = [];
        },
        quotaProductStockReset: state => {
            state.productStock = 0;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getQuotas.pending, (state) => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getQuotas.fulfilled, (state, action) => {
                state.list = action.payload;
                state.listLoading = false;
            })
            .addCase(getQuotas.rejected, (state) => {
                state.listLoading = false;
            })
            .addCase(getQuota.pending, (state) => {
                if (!state.quota) {
                    state.loading = true;
                }
            })
            .addCase(getQuota.fulfilled, (state, action) => {
                state.quota = action.payload;
                state.loading = false;
            })
            .addCase(getQuota.rejected, (state) => {
                state.loading = false;
            })
            .addCase(quotaSearchProduct.pending, (state) => {
                state.suggestionsLoading = true;
            })
            .addCase(quotaSearchProduct.fulfilled, (state, action) => {
                state.productsSuggestions = action.payload;
                state.suggestionsLoading = false;
            })
            .addCase(quotaSearchProduct.rejected, (state) => {
                state.suggestionsLoading = false;
            })
            .addCase(quotaGetProductStock.fulfilled, (state, action) => {
                state.productStock = action.payload[0] ? action.payload[0].physicalQuantity - action.payload[0].reservedQuantity : 0;
            })
    }
});

export const {resetQuotaList, resetQuota, quotaProductsSuggestionsReset, quotaProductStockReset} = quotaSlice.actions;

export default quotaSlice.reducer;