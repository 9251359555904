export const unitTypes = {
    1: 'Unit',
    // 2: 'Amount'
}

export const quotaTypes = {
    1: 'Hierarchy',
    2: 'Group',
    3: 'Personal'
}

export const quotaStatuses = {
    1: 'Active',
    2: 'Utilized',
    3: 'Expired',
    4: 'Inactive'
}

// 2 - Semi-annual, 3 - Quarterly
export const validPeriodTypes = {
    annual: {
        label: 'Annual',
        value: 1,
    },
    custom: {
        label: 'Custom',
        value: 4,
    }
}

export default {
    enabled: true,
    unitType: "",
    validPeriodType: "",
    validFrom: null,
    validTo: null,
    quotaType: "",
    idImage: null,
    idProduct: null,
    productName: '',
    productSKUClient: '',
    quota: 0,
    quotaUsed: 0,
    idHierarchyModel: null,
    quotaTypeLabel: '',
    searchEntity: {name: '', idEntity: null},
    quotaTree: [],
    user: null,
    idUserPersonal: null,
    idUserGroup: null,
    group: [],
    priority: ''
}