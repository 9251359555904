import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getCustomReports = createAsyncThunk(
    'customReports/fetchAll',
    async (idClientAccount, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/reports`);
            return response.data.customReports;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getCustomReport = createAsyncThunk(
    'customReports/fetchOne',
    async ({idClientAccount, idCustomReport}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/${idCustomReport}`);
            return response.data.customReport;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postCustomReports = createAsyncThunk(
    'customReports/create',
    async ({idClientAccount, data}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.post(`/client/account/${idClientAccount}/reports`, {
                name: data.name,
                url: data.url,
                comment: data.comment,
                enabled: data.enabled,
                displayPosition: data.displayPosition
            });

            return response.data.customReport;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putCustomReports = createAsyncThunk(
    'customReports/create',
    async ({idClientAccount, idCustomReport, data}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.put(`/client/account/${idClientAccount}/reports/${idCustomReport}`, {
                idClientAccount,
                idCustomReport,
                name: data.name,
                url: data.url,
                comment: data.comment,
                enabled: data.enabled,
                displayPosition: data.displayPosition
            });
            return response.data.customReport;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteCustomReports = createAsyncThunk(
    'customReports/delete',
    async ({IdClientAccount, IdCustomReport}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/client/account/${IdClientAccount}/reports/${IdCustomReport}`);
            await dispatch(getCustomReports(IdClientAccount));
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const customReportsSlice = createSlice({
    name: 'customReports',
    initialState: {
        list: [],
        listLoading: false,
        report: null,
        loading: false
    },
    reducers: {
        resetCustomReportsList: state => {
            state.list = [];
        },
        resetCustomReport: state => {
            state.report = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCustomReports.pending, (state) => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getCustomReports.fulfilled, (state, action) => {
                state.list = action.payload;
                state.listLoading = false;
            })
            .addCase(getCustomReports.rejected, (state) => {
                state.listLoading = false;
            })
            .addCase(getCustomReport.pending, state => {
                state.loading = true;
            })
            .addCase(getCustomReport.fulfilled, (state, action) => {
                state.loading = false;
                state.report = action.payload;
            })
            .addCase(getCustomReport.rejected, state => {
                state.loading = false;
            })
            .addCase(putCustomReports.fulfilled, (state, action) => {
                state.report = action.payload;
            })
    }
});

export const {resetCustomReportsList, resetCustomReport} = customReportsSlice.actions;

export default customReportsSlice.reducer;