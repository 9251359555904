import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import limApi, {SYSTEM_ADMIN_ROLE} from "../../apis/limApi";
import {getClients} from "./clientSlice";
import {getWarehouses, setActiveWarehouse} from "./warehouseSlice";
import {find, isEmpty} from "lodash";
import localStorageService from "../../services/localStorageService";
import graphClient from "../../apis/graphClient";
import {activeLanguage, changeLanguage} from "../../../i18n";
import {getAdminCustomizerSettings} from "./adminCustomizerSlice";
import {getCustomReports} from "./customReportsSlice";
import {getEcommerceCatalogs} from "./ecommerceSlice";
import {gaEvents} from "../../services/googleAnalytics";
import {defineAbilitiesForAccount, getAbilitiesForAccount} from "../../casl/Ability";
import {getActiveEvent} from "./eventsSlice";
import {getImage} from "./imageSlice";

export const setUserData = createAsyncThunk(
    "user/getUserInfo",
    async (_, thunkApi) => {
        const {rejectWithValue, dispatch, getState} = thunkApi;

        try {
            const apiUser = await limApi.get('/userinfo').then(res => res.data.user);
            await dispatch(getClients());

            const clients = getState().clients.clientsList;
            apiUser.client = find(clients, (client) => client.idClient === apiUser.idClient) || null;

            if (!activeLanguage && !isEmpty(apiUser.cultureLanguageCode)) {
                changeLanguage(apiUser.cultureLanguageCode);
            }

            return apiUser;
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const putUser = createAsyncThunk(
    'user/put',
    async ({userData}, thunkApi) => {
        const {rejectWithValue, getState} = thunkApi;
        userData.idUser = getState().user.idUser;
        try {
            if (userData.firstName !== getState().user.firstName || userData.lastName !== getState().user.lastName) {
                const user = {
                    displayName: userData.firstName + ' ' + userData.lastName,
                    givenName: userData.firstName,
                    surname: userData.lastName,
                };

                await graphClient.api(`/users/${getState().user.idUser}`).update(user);
            }

            const response = await limApi.put('/userinfo', userData);
            return response.data.user;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const changePassword = createAsyncThunk(
    'user/changePassword',
    async ({formData}, thunkApi) => {
        const {rejectWithValue, getState} = thunkApi;
        const user = {
            passwordProfile: {
                forceChangePasswordNextSignIn: false,
                password: formData.password
            }
        };

        try {
            await graphClient.api(`/users/${getState().user.idUser}`).update(user);
        } catch (e) {
            rejectWithValue(e);
        }
    }
);

export const getUserClientLogoId = () => async (dispatch, getState) => {
    const state = getState();
    let idLogo, idCover;

    const idClient = selectUserIdClient(state);
    if (idClient) {
        const clients = state.clients.clientsList;
        const client = clients.find(client => client.idClient === idClient);
        if (client) {
            if (client.idLogoImage) {
                idLogo = client.idLogoImage;
            }
            if (client.idCoverImage) {
                idCover = client.idCoverImage;
                dispatch(getImage(idCover))
            }
        }
    } else {
        idLogo = state.user?.client?.idLogoImage;
        idCover = state.user?.client?.idCoverImage;
    }

    dispatch(setUserClientLogoId(idLogo));
    dispatch(setUserClientCoverId(idCover));
}

export const initUserData = () => async (dispatch, getState) => {
    const lsActive = localStorageService.getItem("activeWarehouseId");
    const adminCustomizerSettings = localStorageService.getItem("adminCustomizerSettings");

    await dispatch(setUserData());
    const user = getState().user;

    if (user.idUser) {
        const idClient = selectUserIdClient(getState());
        const idClientAccount = selectUserIdClientAccount(getState());

        defineAbilitiesForAccount(user.accountPermissions, idClientAccount);

        if (idClient && idClientAccount) {
            if (selectUserEventAccount(getState())) {
                await dispatch(getActiveEvent());
            }

            await dispatch(getWarehouses({ idClient, idClientAccount }));
            const warehouses = getState().warehouse.list;

            if (user.accounts.length === 1 && isEmpty(lsActive) && !isEmpty(warehouses)) {
                await dispatch(setActiveWarehouse(warehouses[0].idWarehouse));
            }

            if (!isEmpty(lsActive) && !isEmpty(warehouses)) {
                await dispatch(setActiveWarehouse(lsActive));
            }
        }

        if (!isEmpty(adminCustomizerSettings)) {
            await dispatch(getAdminCustomizerSettings());
            await dispatch(getCustomReports(idClientAccount));
        }

        gaEvents.setUserData(user, getState().clients, getState().adminCustomizer);

        await dispatch(getUserClientLogoId());

        const accountAbilities = getAbilitiesForAccount(user.accountPermissions, idClientAccount, 'View');

        if (accountAbilities?.subject.includes('CatalogCatalogCatalogCategory') && getState().warehouse.active) {
            await dispatch(getEcommerceCatalogs());
        }
    }

    await dispatch(setUserInitialLoading(false));
}

const initialState = {
    initialLoading: false,
    clientIdLogoImage: undefined,
    clientIdCoverImage: undefined,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserClientLogoId: (state, action) => {
            state.clientIdLogoImage = action.payload
        },
        setUserClientCoverId: (state, action) => {
            state.clientIdCoverImage = action.payload
        },
        setUserInitialLoading: (state, action) => {
            state.initialLoading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setUserData.pending, (state) => {
                state.initialLoading = true;
            })
            .addCase(setUserData.fulfilled, (state, action) => {
                // state.data = action.payload;
                return {...state, ...action.payload}
            })
            .addCase(setUserData.rejected, (state, action) => {
                state.initialLoading = false;
            })
            .addCase(putUser.fulfilled, (state, action) => {
                // this is fix due to wrong initial state structure
                state.firstName =  action.payload.firstName;
                state.lastName =  action.payload.lastName;
                state.mobile =  action.payload.mobile;
                state.phone =  action.payload.phone;
                state.cultureLanguageCode =  action.payload.cultureLanguageCode;
                state.company =  action.payload.company;
                state.companySite =  action.payload.companySite;
                state.department =  action.payload.department;
                state.jobTitle =  action.payload.jobTitle;
                // this is fix due to wrong initial state structure
            })
    }
});

export const {setUserClientLogoId, setUserClientCoverId, setUserInitialLoading} = userSlice.actions

export const selectUserIdClient = state => {
    const lsAdminCustomizerSettings = localStorageService.getItem("adminCustomizerSettings");
    let idClient = state.adminCustomizer?.settings?.idClient || lsAdminCustomizerSettings?.idClient;

    try {
        const userAccounts = state.user.accounts.filter(account => account.idClientAccount !== SYSTEM_ADMIN_ROLE);

        if (!idClient && userAccounts.length === 1) {
            idClient = userAccounts[0].idClient;
        }
    } catch (e) {}

    return idClient;
}

export const selectUserIdClientAccount = state => {
    const lsAdminCustomizerSettings = localStorageService.getItem("adminCustomizerSettings");
    let idClientAccount = state.adminCustomizer?.settings?.idClientAccount || lsAdminCustomizerSettings?.idClientAccount;

    try {
        const userAccounts = state.user.accounts.filter(account => account.idClientAccount !== SYSTEM_ADMIN_ROLE);

        if (!idClientAccount && userAccounts.length === 1) {
            idClientAccount = userAccounts[0].idClientAccount;
        }
    } catch (e) {}

    return idClientAccount;
}

export const selectUserEventAccount = state => {
    const idClientAccount = selectUserIdClientAccount(state);
    const eventAccounts = state.user.eventAccounts;

    return eventAccounts.find(eventAccount => eventAccount.idClientAccount === idClientAccount);
}


export default userSlice.reducer;