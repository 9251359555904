import React, {useEffect, useState} from "react";
import {ErrorMessage, useField, useFormikContext} from "formik";
import TextError from "../TextError";
import {ReactSortable} from "react-sortablejs";
import PopupModal from "../../views/modal/PopupModal";
import FormikControl from "../FormikControl";
import {getFileType, getFileExtension} from "../../../@utils";

import localStorageService from "../../services/localStorageService";
import {useTranslation} from "react-i18next";

// HOW TO USE IT ?
// <FormikControl
//     groupClass="form-group mb-4"
//     control="file"
//     label="Browse Image"
//     name="images"
//     touched={touched.images}
//     errors={errors.images}
// Not required
//     accept=".png, .jpg, .jpeg"    // Accepted file types
//     multiple="multiple"           // Upload multiple files and also adds sorting
//     hasdefault={"yes"}            // Default tag
//     allowedit={"yes"}             // Enable editing eg. File name, title, etc.
// />

const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
}

export const buildFileObject = (uploadedFiles, file, i, fileDefails) => {
    let fileInfo = '';
    let findDefault = uploadedFiles.find(o => o.defaultFile);
    let hasDefault = false;
    if(findDefault) {
        hasDefault = true;
    }

    if (typeof file === 'object') {
        fileInfo = Object.entries(file)[0][1];
        if(typeof fileInfo === 'string') {
            fileInfo = file
        }
    } else {
        if(fileDefails) {
            fileInfo = {
                name: fileDefails.name,
                size: fileDefails.size,
                type: fileDefails.type,
                base64: file
            }
        } else {
            var decodedFile = dataURLtoFile(file, `${Date.now()}_${i}`);
            const fileExtensions = getFileExtension(decodedFile.type);

            decodedFile.file = `${Date.now()}_${i}.${fileExtensions}`
            decodedFile.base64 = file
            fileInfo = decodedFile;
        }
    }

    if(fileInfo) {
        return {
            name: fileInfo.name,
            type: fileInfo.type,
            size: Math.round(fileInfo.size / 1000) + ' kb',
            base64: fileInfo.base64,
            displayPosition: i + 1,
            fileKey: `${Date.now()}_${i}`,
            fileTitle: fileInfo.name,
            defaultFile: i === 0 && !hasDefault ? true : false
        };
    } else {
        return false;
    }
}
/**
 *
 * @deprecated use FileField3 instead
 * @todo remove component and rename FileField3 to FileField
 */
const FileField = (props) => {
    const {label, name, groupClass, className, value, ...rest} = props;
    const [field, meta, helpers] = useField(props.name);
    const formik = useFormikContext();
    const [uploadedFiles, setUploadedFiles] = useState([].sort((a, b) => a.displayPosition - b.displayPosition));
    const {t} = useTranslation();

    const classes = `form-group ${groupClass ? groupClass : ""}`;
    const fieldClasses = `custom-file-input border p-2 ${className} ${meta.touched && meta.errors ? " is-invalid" : ""}`;
    const itemClasses = `mt-2 ${props.itemclass ? props.itemclass : ""}`;
    const browsClasses = `${props.browsclass ? props.browsclass : ''}`

    const [showModalFile, setShowModalFile] = useState(false);
    const [modalFile, setModalFile] = useState([]);

    const isMultiple = props.multiple ? true : false;
    const hasDefault = props.hasdefault ? true : false;
    const allowedit = props.allowedit ? true : false;

    useEffect(() => {
        const fieldValues = async () => {
            let res = await meta.value;
            return res
        }

        fieldValues().then(result => {
            if (localStorageService.getItem(props.name) && (meta.value.length < localStorageService.getItem(props.name).length)) {
                setUploadedFiles(localStorageService.getItem(props.name));
                // helpers.setValue(localStorageService.getItem('test'));
                localStorageService.setItem(props.name, [])
            } else {
                // eslint-disable-next-line array-callback-return
                const filesObj = result?.map((res, i) => {
                    if(typeof res !== 'object') {
                        const builderFileObject = buildFileObject(uploadedFiles, res, i);
                        if(builderFileObject) {
                            return builderFileObject;
                        }
                    } else {
                        return res;
                    }
                })

                if(filesObj.length > 0 && !filesObj.find(o => o.defaultFile)) {
                    filesObj[0]['defaultFile'] = true;
                }

                setUploadedFiles(filesObj);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.idfile]);

    const handleChange = (e) => {
        let files = e.target.files;
        const filesArr = Object.values(files)
        let defaultFiles = uploadedFiles;
        let i = defaultFiles.length;

        // eslint-disable-next-line array-callback-return
        filesArr.map(file => {
            // Make new FileReader
            let reader = new FileReader();
            // Convert the file to base64 text
            reader.readAsDataURL(file);
            let promise = new Promise( (resolve, reject) => {
                reader.onload = () => {
                    resolve(reader.result)
                }
            })

            promise.then(res => {
                const builderFileObject = buildFileObject(uploadedFiles, res, i, file);
                if(builderFileObject) {
                    helpers.setValue([...defaultFiles, builderFileObject]);
                    defaultFiles.push(builderFileObject)


                    localStorageService.setItem(props.name, [...defaultFiles, builderFileObject])
                    i++;
                }
            })
        })

        // handleMove(e)
    }

    const handleMove = (e) => {
        const newItemState = uploadedFiles.map((item, index) => {
            item.displayPosition = index + 1
            return item
        })

        if (localStorageService.getItem(props.name) && (newItemState.length < localStorageService.getItem(props.name).length)) {
            setUploadedFiles(localStorageService.getItem(props.name));
            localStorageService.setItem(props.name, [])
        } else {
            setUploadedFiles(newItemState)
        }
        helpers.setValue(newItemState);
    }

    const handleOpenModalFile = () => {
        setShowModalFile(true)
    }

    const handleCloseModalFile = () => {
        setModalFile([])
        setShowModalFile(false)
    }

    const handleDefaultChange = (e, modalFile) => {
        setUploadedFiles(uploadedFiles.filter((item) => {
            if(item.defaultFile) {
                item.defaultFile = false;
            }
            return item;
        }))

        setUploadedFiles(uploadedFiles.filter(item => item.fileKey !== modalFile.fileKey))

        modalFile.defaultFile = true;
        setUploadedFiles(oldState => {
            return [...oldState, modalFile]
        })

        handleMove(e)
    }

    const handleFileTitleChange = (e, modalFile) => {
        setUploadedFiles(uploadedFiles.filter((item) => {
            if(item.fileKey === modalFile.fileKey) {
                item.fileTitle = e.target.value;
            }
            return item;
        }))
    }

    const handleFileDelete = (file) => {
        setUploadedFiles(uploadedFiles.filter(item => item.fileKey !== file.fileKey));

        const remainingFiles = uploadedFiles.filter(item => item.fileKey !== file.fileKey);
        if(uploadedFiles.length > 1) {
            const defFile = remainingFiles.find(o => o.defaultFile);

            if (!defFile) {
                setUploadedFiles(remainingFiles.filter((item,i) => {
                    if(i === 0) {
                        item.defaultFile = true;
                    }
                    return item;
                }))
            }
            if (localStorageService.getItem(props.name) && (uploadedFiles.length < localStorageService.getItem(props.name).length)) {
                localStorageService.setItem(props.name, remainingFiles)
            }
        }

        helpers.setValue(remainingFiles);
    }

    return (
        <div className={classes}>
            <PopupModal showModal={showModalFile} closeModal={handleCloseModalFile}>
                <div className="popup-container border rounded p-4">
                    <div onClick={handleCloseModalFile} className="text-right"><i className="i-Close-Window text-24 text-danger font-weight-700"></i></div>
                    {
                        modalFile &&
                        <>
                            <div className="form-group">
                                <h1>{t('File Details')}</h1>
                            </div>

                            <div className="row">
                                {
                                    modalFile?.base64?.includes("image") &&
                                        <div className="col-md-8">
                                            <img src={modalFile.base64} alt=""/>
                                        </div>
                                }
                                <div className="col-md-4">
                                    <FormikControl
                                        groupClass="form-group mb-3"
                                        control="input"
                                        type="text"
                                        label={t('File Title')}
                                        name={`fileTitle_${modalFile.fileKey}`}
                                        defaultValue={modalFile.fileTitle}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            handleFileTitleChange(e, modalFile)
                                        }}
                                    />
                                    {
                                        !modalFile?.base64?.includes("image") &&
                                        <FormikControl
                                            groupClass="form-group mb-3"
                                            control="textarea"
                                            rows="8"
                                            type="text"
                                            label={t('File Description')}
                                            name="comment"
                                            defaultValue={modalFile.fileTitle}
                                            onChange={(e) => {
                                                // formik.handleChange(e);
                                                handleFileTitleChange(e, modalFile)
                                            }}
                                        />
                                    }
                                    {
                                        hasDefault &&
                                        <FormikControl
                                            groupClass="form-group mb-3"
                                            control="checkbox"
                                            name="defaultFile"
                                            label={t("Default")}
                                            checked={modalFile.defaultFile}
                                            disabled={modalFile.defaultFile}
                                            onChange={(e) => {
                                                // formik.handleChange(e);
                                                handleDefaultChange(e, modalFile)
                                            }}
                                        />
                                    }
                                    {
                                        isMultiple &&
                                        <div className="form-group">
                                            <label htmlFor="">{t('File Position')}</label>
                                            <p className="text-break">{modalFile.displayPosition}</p>
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="">{t('File Name')}</label>
                                        <p className="text-break">{modalFile.name}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">{t('File Size')}</label>
                                        <p className="text-break">{modalFile.size}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">{t('File Type')}</label>
                                        <p className="text-break">{modalFile.type}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </PopupModal>

            {/*{ label && <label htmlFor={name}>{label}</label>}*/}
            <div className={`${browsClasses} custom-file`}>
                <input
                    {...rest}
                    name={field.name}
                    id={field.name}
                    type="file"
                    files={uploadedFiles}
                    onChange={ (e) => handleChange(e)}
                    className={fieldClasses}
                />
                <label className="custom-file-label" htmlFor={field.name}>{t('Choose file')}</label>
            </div>
            <ErrorMessage component={TextError} name={name}/>

            <ReactSortable
                list={uploadedFiles}
                setList={setUploadedFiles}
                className="row mb-3 filesBox"
                id={'filesBox'}
            >
                {
                    uploadedFiles &&
                    uploadedFiles.map((uploadedFile, i) => {
                        let displayImage = <i className="i-File text-center text-72 pt-4 pb-4"></i>

                        let fileType = ''
                        if(!uploadedFile.type) {
                            fileType = getFileType(uploadedFile.name);
                        } else {
                            fileType = uploadedFile.type;
                        }

                        const fileExtensions = getFileExtension(fileType);
                        if(fileExtensions && uploadedFile.base64 && (
                            fileExtensions === 'tif' ||
                            fileExtensions === 'bmp' ||
                            fileExtensions === 'jpeg' ||
                            fileExtensions === 'jpg' ||
                            fileExtensions === 'gif' ||
                            fileExtensions === 'png' ||
                            fileExtensions === 'eps'
                        )
                        ) {
                            let base64 = uploadedFile.base64;
                            if(!uploadedFile.base64.includes("data:")) {
                                base64 = `data:image/${fileExtensions};base64,${uploadedFile.base64}`
                            }
                            displayImage = <img src={base64}  alt=""/>
                        } if(fileExtensions && fileExtensions === 'doc') {
                            displayImage = <i className="i-File-Word text-center text-72 pt-4 pb-4"></i>
                        } if(fileExtensions && (fileExtensions === 'xls' || fileExtensions === 'xlsx')) {
                            displayImage = <i className="i-File-Excel text-center text-72 pt-4 pb-4"></i>
                        } if(fileExtensions && (fileExtensions === 'zip' || fileExtensions === '.rar')) {
                            displayImage =<i className="i-File-Zip text-center text-72 pt-4 pb-4"></i>
                        }

                        return (
                            <div className={itemClasses} key={uploadedFile.fileKey}
                                 onDragEnd={handleMove}
                            >
                                <div className="card border border-light overflow-hidden position-relative">
                                    {displayImage}
                                    <span
                                        className='mt-1 remove-image'
                                        onClick={(e) => {
                                            handleFileDelete(uploadedFile)
                                        }}
                                    ><i className="bg-white cursor-pointer border rounded p-1 i-Close-Window text-19 text-danger font-weight-700"></i></span>
                                    <span
                                        className='mt-1 download-image'
                                    ><a href={uploadedFile.base64} download={uploadedFile.name}><i className="bg-white cursor-pointer border rounded p-1 text-dark i-Data-Download text-19 text-info font-weight-700"></i></a></span>
                                    {
                                        allowedit &&
                                        <span
                                            className='mt-1 edit-image'
                                            onClick={() => {
                                                handleOpenModalFile()
                                                setModalFile(uploadedFile)
                                            }}
                                        ><i className="bg-white cursor-pointer border rounded p-1 i-Pen-4 text-19 text-success font-weight-700"></i></span>
                                    }
                                    {
                                        isMultiple && uploadedFiles.length > 1 &&
                                        <span
                                            className='mt-1 move-image'
                                        ><i className="bg-white cursor-move border rounded p-1 text-primary i-Full-Screen text-19 text-dark font-weight-700"></i></span>
                                    }
                                </div>
                                <p className="mb-0 mt-1">{t("File Title")}: {uploadedFile.fileTitle}</p>
                                <p className="mb-0 mt-1">{t("Size")}: {uploadedFile.size}</p>
                                {
                                    isMultiple &&
                                    <p>Position: {uploadedFile.displayPosition}</p>
                                }
                                {
                                    uploadedFile.defaultFile && hasDefault &&
                                    <p className="badge badge-primary text-16 mr-2">{t('Default')}</p>
                                }
                            </div>
                        )
                    })
                }
            </ReactSortable>
        </div>
    );
};

export default FileField