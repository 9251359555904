import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {isEqual, some} from "lodash";
import {selectUserIdClientAccount} from "./userSlice";

export const searchAccountEventTags = createAsyncThunk(
    'eventTag/searchAccountTags',
    async ({query}, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/client/account/${idClientAccount}/events/tags/${query}`);
            return response.data.tags;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getEventTags = createAsyncThunk(
    'eventTag/fetchAll',
    async (idEvent, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/events/${idEvent}/tags`);
            return response.data.eventTags;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postEventTag = createAsyncThunk(
    'eventTag/create',
    async ({idEvent, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/events/${idEvent}/tags`, data);
            return response.data.eventTag;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteEventTag = createAsyncThunk(
    'eventTag/delete',
    async ({idEvent, idEventTag}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/events/${idEvent}/tags/${idEventTag}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const eventTagSlice = createSlice({
    name: 'eventTag',
    initialState: {
        list: [],
        tagSuggestions: [],
        suggestionsLoading: undefined
    },
    reducers: {
        resetSearchAccountEventTags: state => {
            state.tagSuggestions = [];
        },
        resetEventTags: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(searchAccountEventTags.pending, (state) => {
                state.suggestionsLoading = true;
            })
            .addCase(searchAccountEventTags.fulfilled, (state, action) => {
                state.tagSuggestions = action.payload;
                state.suggestionsLoading = undefined;
            })
            .addCase(searchAccountEventTags.rejected, (state) => {
                state.suggestionsLoading = undefined;
            })
            .addCase(getEventTags.fulfilled, (state, action) => {
                state.list = action.payload;
            })
    }
});

export const updateEventTags = (idClientAccount, idEvent, eventTags) => async (dispatch, getState) => {
    const oldTags = getState().eventTag.list.map(t => ({
        idTag: t.idTag,
        tagName: t.tagName,
        idEventTag: t.idEventTag
    }));

    const equal = isEqual(oldTags, eventTags);

    if (!equal) {
        // DELETE/REMOVE tag
        const deleteTags = oldTags.filter(old => {
            const exist = some(eventTags, old);
            if (!exist) {
                return old;
            }
            return null;
        });

        for (const tag of deleteTags) {
            await dispatch(deleteEventTag({idEvent, idEventTag: tag.idEventTag}));
        }
        // DELETE/REMOVE tag

        // ADD tag
        const addTags = eventTags.filter(ref => {
            const exist = some(oldTags, ref);
            if (!exist) {
                return ref;
            }
            return null;
        });

        for (const tag of addTags) {
            const data = {
                idEvent,
                idClientAccount,
                idTag: tag.idTag,
                tagName: tag.tagName
            };
            // Always POST tags. API is responsible to create new tag or reference existing one based ot idTag property
            await dispatch(postEventTag({idEvent, data}));
        }
        // ADD tag
        await dispatch(getEventTags(idEvent));
    }
}

export const {resetSearchAccountEventTags, resetEventTags} = eventTagSlice.actions;

export default eventTagSlice.reducer;