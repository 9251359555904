import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getProductImages = createAsyncThunk(
    "productImage/fetchAll",
    async (idProduct, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/products/${idProduct}/images`);
            return response.data.images;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postProductImage = createAsyncThunk(
    "productImage/create",
    async ({idProduct, imageBase64, values}, {rejectWithValue}) => {
        try {
            const img = new Image();
            img.src = values.base64;

            const response = await limApi.post(`/products/${idProduct}/images`,{
                idProduct: idProduct,
                image: imageBase64,
                imageName: values.fileTitle,
                imageFileName: values.name,
                width: img.width,
                heigth: img.height,
                displayPosition: values.displayPosition,
                defaultImage: values.defaultFile
            });

            return response.data.image;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putProductImage = createAsyncThunk(
    "productImage/update",
    async ({idProduct, idProductImage, imageBase64, values}, {rejectWithValue}) => {
        try {
            const img = new Image();
            img.src = values.base64;

            const response = await limApi.put(`/products/${idProduct}/images/${idProductImage}`,{
                idProduct: idProduct,
                idProductImage: idProductImage,
                idImage: values.fileKey,
                image: imageBase64,
                imageName: values.fileTitle,
                imageFileName: values.name,
                width: img.width,
                heigth: img.height,
                displayPosition: values.displayPosition,
                defaultImage: values.defaultFile
            });

            return response.data.image;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProductImage = createAsyncThunk(
    "productImage/delete",
    async ({idProduct, idProductImage}, {rejectWithValue}) => {
        try {
           await limApi.delete(`/products/${idProduct}/images/${idProductImage}`);
           return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const productImageSlice = createSlice({
    name: "productImage",
    initialState: {
        list: []
    },
    reducers: {
        resetProductImages: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProductImages.fulfilled, (state, action) => {
                state.list = action.payload;
            })
    }
});

export const updateProductImages = (idProduct, images) => async (dispatch, getState) => {
    const productImages = getState().productImage.list;

    const newImages = images.filter(image => image.isNew);
    const existingImages = images.filter(image => !image.isNew && productImages.length > 0);

    const newImagePromises = newImages.map(image => {
        return dispatch(postProductImage({
            idProduct: idProduct,
            imageBase64: image.base64.split(',')[1],
            values: image
        }));
    });

    const existingImagePromises = existingImages.map(image => {
        const imageDetails = productImages.find(item => item.idImage === image.fileKey);
        if (imageDetails &&
            (
                imageDetails.defaultImage !== image.defaultFile ||
                imageDetails.displayPosition !== image.displayPosition ||
                imageDetails.imageName !== image.fileTitle ||
                imageDetails.imageFileName !== image.name
            )
        ) {
            return dispatch(putProductImage({
                idProduct: imageDetails.idProduct,
                idProductImage: imageDetails.idProductImage,
                imageBase64: image.base64.split(',')[1],
                values: image
            }));
        }
    });

    const productImageKeys = productImages.map(value => value.idImage);
    const fieldImageKeys = images.map(value => value.fileKey);
    const deletedImages = productImageKeys.filter(x => !fieldImageKeys.includes(x));

    const deletePromises = deletedImages.map(idImage => {
        const imageDelete = productImages.find(item => item.idImage === idImage);
        if (imageDelete) {
            return dispatch(deleteProductImage({ idProduct: imageDelete.idProduct, idProductImage: imageDelete.idProductImage }));
        }
    });

    await Promise.all([...newImagePromises, ...existingImagePromises, ...deletePromises]);
};

export const {resetProductImages} = productImageSlice.actions;

export default productImageSlice.reducer;