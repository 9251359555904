import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getActivities = createAsyncThunk(
    'activity/fetchAll',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/activities');
            return response.data.activities;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const activitySlice = createSlice({
    name: 'activity',
    initialState: {
        list: []
    },
    extraReducers: builder => {
        builder.addCase(getActivities.fulfilled, (state, action) => {
            state.list = action.payload
        })
    }
});

export default activitySlice.reducer;