import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const refreshProductStock = createAsyncThunk(
  'support/refreshProductStock',
  async (sku, {rejectWithValue}) => {
      try {
          const response = await limApi.get(`/support/product/${sku}/refreshstock`);
          return response.data;
      } catch (e) {
          return rejectWithValue(e);
      }
  }
);

const supportSlice = createSlice({
    name: 'support',
    initialState: {
        refreshStockLoading: []
    },
    extraReducers: (builder) => {
        builder
            .addCase(refreshProductStock.pending, (state, action) => {
                state.refreshStockLoading.push(action.meta.arg);
            })
            .addCase(refreshProductStock.fulfilled, (state, action) => {
                state.refreshStockLoading = state.refreshStockLoading.filter(value => value !== action.meta.arg);
            })
            .addCase(refreshProductStock.rejected, (state, action) => {
                state.refreshStockLoading = state.refreshStockLoading.filter(value => value !== action.meta.arg);
            })
    }
})

export default supportSlice.reducer;