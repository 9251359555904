import {createSlice} from "@reduxjs/toolkit";

const globalSlice = createSlice({
    name: "global",
    initialState: {
        appGlobalError: undefined,
        appNotification: undefined,
    },
    reducers: {
        setAppGlobalError: (state, action) => {
            state.appGlobalError = action.payload;
        },
        /**
         * Reducer function to set the app notification state.
         *
         * @param {Object} state - The current state.
         * @param {Object} action - The action object.
         * @param {Object} action.payload - The payload object containing the app notification message and type.
         * @param {string} action.payload.message - The app notification message.
         * @param {string} action.payload.type - The app notification type.
         */
        setAppNotification: (state, action) => {
            state.appNotification = action.payload;
        }
    }
});

export const {setAppGlobalError, setAppNotification} = globalSlice.actions;

export default globalSlice.reducer;

