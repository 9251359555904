import { lazy } from "react";

const BoxAdmin = lazy(()=> import("./box/BoxAdminList"));
const CreateBox = lazy(()=> import('./box/CreateBox'));
const ProviderSites = lazy(()=> import('./provider-site/ProviderSites'));
const CreateProviderSite = lazy(()=> import('./provider-site/CreateProviderSite'));
const Provider = lazy(()=> import('./provider/Provider'));
const CreateProvider = lazy(()=> import('./provider/CreateProvider'));
const EditProvider = lazy(() => import('./provider/EditProvider'));
const EditBox = lazy( () => import('./box/EditBox'));
const EditProviderSite = lazy( () => import('./provider-site/EditProviderSite'));

const clientAdminRoutes = [
    {
        path: "/admin/box-administration",
        component: BoxAdmin,
        key: "View_BoxAdminBoxAdministrationBoxAdministration"
    },
    {
        path: "/admin/create-box",
        component: CreateBox,
        key: "Create_BoxAdminBoxAdministrationBoxAdministration"
    },
    {
        path: '/admin/edit-box/:id',
        component: EditBox,
        key: "Edit_BoxAdminBoxAdministrationBoxAdministration"
    },
    {
        path: '/admin/provider-sites',
        component: ProviderSites,
        key: "View_BoxAdminBoxAdministrationProviderSite"
    },
    {
        path: '/admin/create-provider-site',
        component: CreateProviderSite,
        key: "Create_BoxAdminBoxAdministrationProviderSite"
    },
    {
        path: '/admin/edit-provider-site/:idProvider/:idProviderSite',
        component: EditProviderSite,
        key: "Edit_BoxAdminBoxAdministrationProviderSite"
    },
    {
        path: '/admin/provider',
        component: Provider,
        key: "View_BoxAdminBoxAdministrationProvider"
    },
    {
        path: '/admin/create-provider',
        component: CreateProvider,
        key: "Create_BoxAdminBoxAdministrationProvider"
    },
    {
        path: '/admin/edit-provider/:id',
        component: EditProvider,
        key: "Edit_BoxAdminBoxAdministrationProvider"
    },

];



export default clientAdminRoutes;
