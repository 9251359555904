import React, {useCallback, useMemo, useRef, useState} from 'react';
import {ErrorMessage, useField, useFormikContext} from "formik";
import TextError from "../TextError";
import PhoneInput, {getCountryDataByCountry } from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import {useTranslation} from "react-i18next";
import {debounce} from "lodash";
import axios from "axios";

/**
 * Phone component to render phone input field with validation.
 *
 * @see https://github.com/bl00mber/react-phone-input-2
 * @param {string} name - name of the input field
 * @param {string} label - optional label for the input field
 * @param {boolean} required - flag to mark the input field as required or not (default: false)
 * @param {string} groupClass - optional css class for the form group container
 * @param {string} className - optional css class for the input field
 * @param {boolean} disabled - flag to disable the input field (default: false)
 * @param {...any} rest - any additional props to pass to the PhoneInput component
 * @returns {JSX.Element} - returns the JSX element
 */
const Phone = ({ name, label, required = false, groupClass, className, disabled = false, ...rest }) => {
    const { t } = useTranslation();
    const formik = useFormikContext();
    const fieldRef = useRef("");

    /**
     * Async function to validate the phone number using the Crafty Clicks API
     *
     * @param {string} phone - the phone number to validate
     * @returns {string} - error message if invalid phone number or API error, undefined otherwise
     */
    const validatePhone = useMemo(() => async (value) => {
        const phone = value.replace(/\D/g, '')

        try {
            const response = await axios.post('https://api.craftyclicks.co.uk/phone/1.0/validate', {
                key: process.env.REACT_APP_FETCHIFY_API_KEY,
                // country,
                phone_number: `+${phone}`
            });

            if (!response.data.result) {
                return t('Please enter valid phone number');
            }
        } catch (error) {
            return t("Error checking phone number. Please try again later.");
        }
    }, [t]);

    /**
     * Function to validate the input value
     *
     * @param {string} value - the value of the input field
     * @param {object} meta - the metadata of the input field (touched and error)
     * @returns {string|undefined} - error message if validation fails, undefined otherwise
     */
    const validate = useCallback((value, meta) => {
        if ((!value || value.length < 5) && required) {
            return t('Phone field is required');
        }

        if (value && value.length >= 5 && fieldRef.current !== value && meta.touched) {
            fieldRef.current = value;
            return validatePhone(value);
        }

        if (fieldRef.current === value && meta.error) {
            return meta.error;
        }
    }, [required, t, validatePhone]);

    const [field, meta] = useField({
        name,
        validate: (value) => !disabled ? validate(value, meta) : false,
    });

    const classes = `form-group ${groupClass ?? ""}`;
    const fieldClasses = `w-100 ${className ?? ""} ${meta.touched && meta.error ? " is-invalid" : ""}`;

    const handleChange = debounce((phone, country, e) => {
        formik.handleChange(e);
    }, 500)

    return (
        <div className={classes}>
            { label && <label htmlFor={name}>{label} { required && <span className="text-danger">*</span> }</label>}
            <PhoneInput
                {...field}
                {...rest}
                isValid={() => !meta.touched || !meta.error}
                inputProps={{ name, id: name + "_field" }}
                inputClass={fieldClasses}
                countryCodeEditable={true}
                enableSearch
                onChange={handleChange}
                enableClickOutside={false}
                disabled={disabled}
            />
            <ErrorMessage component={TextError} name={name} />
        </div>
    );
};

export default Phone;
