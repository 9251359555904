import React, {useEffect, useState} from 'react';
import { ErrorMessage, useField, useFormikContext } from 'formik'
import TextError from "../TextError";
import DatePicker, {registerLocale} from "react-datepicker";
import fr from "date-fns/locale/fr";
import moment from "moment";
import {languageCodeOnly} from "../../../i18n";
import {useTranslation} from "react-i18next";

const DateField = (props) => {
    const {i18n} = useTranslation();
    const {label, name, groupClass, className, touched, errors, dateFormat, placeholderText, labelClass, required} = props;
    const classes = `form-group ${groupClass ? groupClass : ""}`;
    const fieldClasses = "form-control " + className + `${touched && errors ? " is-invalid" : ""}`;
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    const [customError, setCustomError] = useState(false)

    useEffect(() => {
        registerLocale(languageCodeOnly(i18n.language), fr)
    }, [i18n.language]);

    const onChangeRaw = (date) => {
        let m = moment(date, dateFormat || 'dd/MM/yyyy',true).isValid()

        if (!m && date) {
            setCustomError(true)
        } else {
            setCustomError(false)
        }
    }

    return (
        <div className={classes}>
            { label && <label htmlFor={name} className={labelClass && labelClass}>{label} { required && <span className="text-danger">*</span> }</label>}
            <DatePicker
                {...field}
                onChange={val => {
                    if (val) {
                        const value = new Date(val.getTime() + Math.abs(val.getTimezoneOffset()*60000));
                        setFieldValue(field.name, value);
                        if(typeof  props.onChange === "function"){
                            props.onChange(value);
                        }
                        onChangeRaw(value)
                    } else {
                        setFieldValue(field.name, val);
                        if(typeof  props.onChange === "function"){
                            props.onChange(val);
                        }
                        onChangeRaw(val)
                    }
                }}
                {...props}
                isClearable={!props.disabled}
                utcOffset={0}
                selected={( field.value && new Date(field.value)) || null}
                dateFormat={dateFormat || "dd/MM/yyyy"}
                placeholderText={i18n.t(placeholderText || dateFormat || "dd/mm/yyyy")}
                locale={languageCodeOnly(i18n.language)}
                autoComplete="off"
                onChangeRaw={(e) => onChangeRaw(e.target.value)}
                className={fieldClasses}
            />

            {customError &&
                <span
                    className="invalid-feedback text-11"
                    style={{display: "block"}}
                >
                Date format is {i18n.t(dateFormat || "dd/mm/yyyy")}
            </span>}

            <ErrorMessage component={TextError} name={name}/>
        </div>
    )
}

export default DateField;