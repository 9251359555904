import React, {useEffect, useState} from 'react';
import {useField} from "formik";
import {useTranslation} from "react-i18next";
import {
    buildFileObjectFromFileInfo,
    fileToBase64,
    getFileTypeFromBase64DataString,
    humanFileSize,
} from "../../../@utils";
import {ReactSortable} from "react-sortablejs";
import FormikControl from "../FormikControl";
import PopupModal from "../../views/modal/PopupModal";
import uuid from "uuid";
import PreviewFile from "../../../@gull/components/PreviewFile";
import {FaDownload} from "react-icons/fa";

/**
 * @usage
 * <FormikControl
 *     groupClass="form-group mb-4"
 *     control="file3"
 *     accept=".png, .jpg, .jpeg"
 *     label={t('Browse Image')}
 *     name="images"
 *     multiple="multiple"
 *     itemclass="col-12 col-sm-4 col-md-3 col-lg-2"
 *     browsclass="col-12 col-md-4 mb-2"
 *     hasdefault={"yes"}
 *     allowedit={"yes"}
 * />
 */
const FileField3 = (props) => {
    const {t} = useTranslation();
    const {label, name, groupClass, className, value, required, ...rest} = props;
    // const [uploadedFiles, setUploadedFiles] = useState([].sort((a, b) => a.displayPosition - b.displayPosition));
    const [showModalFile, setShowModalFile] = useState(false);
    const [modalFile, setModalFile] = useState();

    const validateFile = (file, accept) => {
        if (!accept.includes(file.ext)) {
            throw Error(t('Unsupported file type found.'));
        }
        // check if the file s more than 5 MB
        if (file.size > 5242880) {
            throw Error(t("{{fileTitle}} size is too large.", {fileTitle: file.fileTitle}));
        }

        if (file.fileTitle.length > 50 || file.name.length > 50) {
            throw Error(t("{{fileTitle}} name must be 50 characters max.", {fileTitle: file.fileTitle}));
        }

        if (file.fileTitle.length === 0 || file.name.length === 0) {
            throw Error(t(`File name must be minimum of 1 character.`));
        }
    }

    const [field, meta, helpers] = useField({
        name,
        multiple: !!props.multiple,
        validate: files => {
            let error;

            if (!files.length && required) {
                error = t('File is required.');
            }

            for (const file of files) {
                try {
                    validateFile(file, props.accept)
                } catch (e) {
                    error = e.message;
                }
            }
            return error;
        }
    });

    const classes = `form-group ${groupClass ? groupClass : ""}`;
    const fieldClasses = `custom-file-input border p-2 ${className} ${meta.touched && meta.error ? " is-invalid" : ""}`;
    const itemClasses = `mt-2 ${props.itemclass ? props.itemclass : ""}`;
    const browsClasses = `${props.browsclass ? props.browsclass : ''}`

    const isMultiple = props.multiple ? true : false;
    const hasDefault = props.hasdefault ? true : false;
    const allowedit = props.allowedit ? true : false;

    useEffect(() => {
        if (meta.initialValue && meta.initialValue.length > 0) {
             (async function () {

                const objects = [];
                for (const item of meta.initialValue) {
                    await buildFileObjectFromFileInfo(item).then(file => {objects.push(file)});
                }

                helpers.setValue(objects.sort((a, b) => a.displayPosition - b.displayPosition));
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta.initialValue]);

    const handleMove = (newState) => {
        const newItemState = newState.map((item, index) => {
            return {
                ...item,
                displayPosition: index + 1
            }
        });
        helpers.setValue(newItemState);
    }

    const handleChange = async (e) => {
        let files = e.target.files;
        const filesArr = Object.values(files)
        let defaultFiles = meta.value;
        let i = defaultFiles.length;

        for (const file of filesArr) {
            const base64 = await fileToBase64(file);
            const fileType = await getFileTypeFromBase64DataString(base64.split(',')[1]);

            const fileObject = {
                name: file.name,
                size: file.size,
                file: file,
                displayPosition: i + 1,
                fileKey: uuid.v4(),
                fileTitle: file.name,
                defaultFile: i === 0,
                isNew: true,
                base64,
                mime: fileType?.mime,
                ext: fileType?.ext
            }

            try {
                validateFile(fileObject, props.accept);
                if (isMultiple) {
                    helpers.setValue([...defaultFiles, fileObject]);
                    defaultFiles.push(fileObject)
                    i++;
                } else {
                    helpers.setValue([fileObject]);
                    break;
                }
            } catch (e) {
                helpers.setError(e.message);
            }
        }
    }

    const handleOpenModalFile = () => {
        setShowModalFile(true)
    }

    const handleCloseModalFile = () => {
        setModalFile(undefined)
        setShowModalFile(false)
    }

    const handleDefaultChange = (modalFile) => {
        const currentFiles = [...meta.value];

        for (const currentFile of currentFiles) {
            currentFile.defaultFile = currentFile.fileKey === modalFile.fileKey;
        }

        helpers.setValue(currentFiles);
    }

    const handleFileDelete = (file) => {
        const remainingFiles = [...meta.value].filter(item => item.fileKey !== file.fileKey);

        if(meta.value.length > 0) {
            const defFile = remainingFiles.find(o => o.defaultFile);

            remainingFiles.map((item, i) => {
                if (!defFile && i === 0) {
                    item.defaultFile = true;
                }
                item.displayPosition = i + 1;
                return item;
            })

        }

        helpers.setValue(remainingFiles);
    }

    // function downloadBlob(blob, filename) {
    //     // Create an object URL for the blob object
    //     const url = URL.createObjectURL(blob);
    //     // Create a new anchor element
    //     const a = document.createElement('a');
    //     // Set the href and download attributes for the anchor element
    //     // You can optionally set other attributes like `title`, etc
    //     // Especially, if the anchor element will be attached to the DOM
    //     a.href = url;
    //     a.download = filename || 'download';
    //     // Click handler that releases the object URL after the element has been clicked
    //     // This is required for one-off downloads of the blob content
    //     // const clickHandler = () => {
    //     //     setTimeout(() => {
    //     //         URL.revokeObjectURL(url);
    //     //         a.removeEventListener('click', clickHandler);
    //     //     }, 150);
    //     // };
    //     // Add the click event listener on the anchor element
    //     // Comment out this line if you don't want a one-off download of the blob content
    //     // a.addEventListener('click', clickHandler, false);
    //     // Programmatically trigger a click on the anchor element
    //     // Useful if you want the download to happen automatically
    //     // Without attaching the anchor element to the DOM
    //     // Comment out this line if you don't want an automatic download of the blob content
    //     a.click();
    //     // Return the anchor element
    //     // Useful if you want a reference to the element
    //     // in order to attach it to the DOM or use it in some other way
    //     return a;
    // }

    return (
        <div className={classes}>
            <PopupModal showModal={showModalFile} closeModal={handleCloseModalFile}>
                <div className="popup-container border rounded p-4">
                    <div onClick={handleCloseModalFile} className="text-right"><i className="i-Close-Window text-24 text-danger font-weight-700"></i></div>
                    {
                        modalFile &&
                        <>
                            <div className="form-group">
                                <h1>{t('File Details')}</h1>
                            </div>

                            <div className="row">
                                {
                                    modalFile?.file &&
                                    <div className="col-md-8">
                                        <PreviewFile file={modalFile} />
                                    </div>
                                }
                                <div className="col-md-4">
                                    <FormikControl
                                        groupClass="form-group mb-3"
                                        control="input"
                                        type="text"
                                        label={t('File Title')}
                                        name={`${field.name}[${modalFile.index}].fileTitle`}
                                    />
                                    {
                                        hasDefault &&
                                        <FormikControl
                                            groupClass="form-group mb-3"
                                            control="checkbox"
                                            name={`${field.name}[${modalFile.index}].defaultFile`}
                                            label={t("Default")}
                                            checked={meta.value[modalFile.index]?.defaultFile}
                                            disabled={meta.value[modalFile.index]?.defaultFile}
                                            onChange={() => handleDefaultChange(modalFile)}
                                        />
                                    }
                                    {
                                        isMultiple &&
                                        <div className="form-group">
                                            <label htmlFor="">{t('File Position')}</label>
                                            <p className="text-break">{modalFile.displayPosition}</p>
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="">{t('File Name')}</label>
                                        <p className="text-break">{modalFile.name}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">{t('File Size')}</label>
                                        <p className="text-break">{humanFileSize(modalFile.size)}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">{t('File Type')}</label>
                                        <p className="text-break">{modalFile ? modalFile.ext.toUpperCase() : ''}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </PopupModal>

            <>
                { label && <label
                    htmlFor={field.name}
                    className="d-block"
                    style={{
                        fontSize: "12px",
                        color: '#343434',
                        marginBottom: "6px"
                    }}
                >
                    {label} { required && <span className="text-danger">*</span> }
                </label>}

                <div className={`${browsClasses} custom-file`}>
                    <input
                        {...rest}

                        id={field.name}
                        type="file"
                        className={fieldClasses}
                        onChange={async (event) => {
                            await handleChange(event);
                        }}
                        onInput={() => {
                            helpers.setTouched(true, false);
                        }}
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                    />
                    <span className="custom-file-label">{t('Choose file')}</span>
                </div>
                {meta.touched && meta.error && <div className='invalid-feedback mt-0 mb-1' style={{display: 'block'}}>{meta.error}</div>}
            </>

            <ReactSortable
                list={meta.value}
                setList={handleMove}
                className="row mb-3 filesBox"
                id={'filesBox'}
            >
                { meta.value.map((uploadedFile, index) => {
                        if (!uploadedFile.file) return <React.Fragment key={index}/>;
                        return (
                            <div className={itemClasses} key={uploadedFile.fileKey}
                                 // onDragEnd={handleMove}
                            >
                                <div className="card border border-light overflow-hidden position-relative">
                                    <PreviewFile file={uploadedFile} className="m-auto py-3 text-50" />

                                    <span
                                        className='mt-1 remove-image'
                                        onClick={(e) => {
                                            handleFileDelete(uploadedFile)
                                        }}
                                    >
                                        <i className="bg-white cursor-pointer border rounded p-1 i-Close-Window text-19 text-danger font-weight-700"/>
                                    </span>
                                    <span className='mt-1 download-image border rounded bg-white'>
                                        <a href={uploadedFile.base64} download={uploadedFile.name} className="outline-none" target="_blank">
                                            <FaDownload className="p-1 text-25"/>
                                        </a>
                                    </span>
                                    {
                                        allowedit &&
                                        <span
                                            className='mt-1 edit-image'
                                            onClick={() => {
                                                handleOpenModalFile()
                                                setModalFile({...uploadedFile, index})
                                            }}
                                        >
                                            <i className="bg-white cursor-pointer border rounded p-1 i-Pen-4 text-19 text-success font-weight-700"/>
                                        </span>
                                    }
                                    {
                                        isMultiple && meta.value.length > 1 &&
                                        <span className='mt-1 move-image'>
                                            <i className="bg-white cursor-move border rounded p-1 text-primary i-Full-Screen text-19 text-dark font-weight-700"/>
                                        </span>
                                    }
                                </div>
                                <p className="mb-0 mt-1">{t("File Title")}: {uploadedFile.fileTitle}</p>
                                <p className="mb-0 mt-1">{t("Size")}: {humanFileSize(uploadedFile.size)}</p>
                                {
                                    isMultiple &&
                                    <p>Position: {uploadedFile.displayPosition}</p>
                                }
                                {
                                    !!uploadedFile.defaultFile && hasDefault &&
                                    <p className="badge badge-primary text-16 mr-2">{t('Default')}</p>
                                }
                            </div>
                        )
                    })
                }
            </ReactSortable>
        </div>
    );
};

export default FileField3;
