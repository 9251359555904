import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import formInitialValues from "./form-model/formInitialValues";
import {getClientAccounts} from "../../../app/views/app/client-admin/clientAdminUtils";
import FormikControl from "../../../app/formic/FormikControl";
import {useTranslation} from "react-i18next";
import validationSchema from "./form-model/validationSchema";
import {debounce, isEmpty} from "lodash";
import {Spinner} from "react-bootstrap";
import {masqueradeUserSearch, resetMasqueradeSuggestions} from "../../../app/redux/slices/masqueradeSlice";
import {selectUserIdClient, selectUserIdClientAccount} from "../../../app/redux/slices/userSlice";

const MasqueradeForm = ({handleSubmit}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const { clientsList } = useSelector(
        (state) => state.clients ?? { clientsList: [], accountsList: [] },
    );
    const {suggestions: masqueradeUsers, suggestionsLoading: masqueradeUsersLoading} = useSelector(state => state.masquerade);
    const user = useSelector(state => state.user)

    const idClient = useSelector(selectUserIdClient);
    const idClientAccount = useSelector(selectUserIdClientAccount);

    const searchRef = useRef(null);
    const [showOptions, setShowOptions] = useState(false);

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            if (showOptions) {
                setShowOptions(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    const clientsOptionList = clientsList.map((client) => {
        return {value: client.idClient, label: client.clientName};
    });

    if (idClient) {
        formInitialValues.idClient = idClient;
        formInitialValues.idClientAccount = idClientAccount;
        formInitialValues.clientAccounts = getClientAccounts(
            idClient,
            clientsList,
            true,
        ).filter(account => user.idClient ? user.accounts.map(acc => acc.idClientAccount).includes(account.value) : true)
    }

    const searchUser = useCallback(debounce(async (query, idClientAccount) => {
        if (!isEmpty(query) && query.length >= 3 && idClientAccount) {
            await dispatch(masqueradeUserSearch({query, idClientAccount}))
        }
    }, 500), []);

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {(formik) => (
                <Form className="row">
                    {!user.idClient && (
                        <FormikControl
                            groupClass="col-12"
                            control="select"
                            label={t("Client")}
                            name="idClient"
                            touched={formik.touched.idClient}
                            errors={formik.errors.idClient}
                            value={formik.values.idClient}
                            required={true}
                            onChange={async (e) => {
                                const { value } = e.target;
                                const _clientAccounts = getClientAccounts(
                                    value,
                                    clientsList,
                                    true,
                                ).filter(account => user.idClient ? user.accounts.map(acc => acc.idClientAccount).includes(account.value) : true);

                                formik.setFieldValue("idClient", value);
                                formik.setFieldValue("clientAccounts", _clientAccounts);

                                if (_clientAccounts.length === 2) {
                                    const idClientAccount = _clientAccounts[1].value;
                                    formik.setFieldValue("idClientAccount", idClientAccount);
                                } else {
                                    formik.setFieldValue("idClientAccount", "");
                                }
                            }}
                            options={[
                                {
                                    value: "",
                                    label: t("-- {{t}} --", {t: t("None")}),
                                },
                                ...clientsOptionList,
                            ]}
                        />
                    )}
                    {formik.values.idClient && formik.values.clientAccounts.length > 1 && (
                        <FormikControl
                            groupClass="col-12"
                            control="select"
                            label={t("Account")}
                            name="idClientAccount"
                            touched={formik.touched.idClientAccount}
                            errors={formik.errors.idClientAccount}
                            required={true}
                            onChange={async (e) => {
                                const { value: idClientAccount } = e.target;
                                formik.setFieldValue(
                                    "idClientAccount",
                                    idClientAccount,
                                );
                            }}
                            options={formik.values.clientAccounts ?? []}
                        />
                    )}
                    {formik.values.idClientAccount && (
                        <div
                            id="search"
                            className="col-12"
                            ref={searchRef}
                        >
                            <FormikControl
                                control="input"
                                type="text"
                                label={t("User")}
                                name="user"
                                placeholder={t('Find and select user')}
                                autoComplete="off"
                                touched={formik.touched.user}
                                errors={formik.errors.user}
                                required={true}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    const {value} = e.target;
                                    formik.setFieldValue("idUser", "");
                                    searchUser(value, formik.values.idClientAccount);
                                }}
                                onFocus={() => {
                                    setShowOptions(true);
                                }}
                            >
                                {masqueradeUsersLoading && (
                                    <Spinner
                                        className="position-absolute"
                                        animation="border"
                                        size="sm"
                                        variant="light"
                                        style={{
                                            right: "10px",
                                            top: "32px",
                                        }}
                                    />
                                )}
                                {!isEmpty(formik.values.idUser) && (
                                    <button
                                        type="button"
                                        className="position-absolute btn p-0"
                                        style={{
                                            right: "10px",
                                            top: "32px",
                                        }}
                                        onClick={(event) => {
                                            formik.setFieldValue(
                                                "user",
                                                '',
                                            );
                                            formik.setFieldValue("idUser", "");
                                        }}
                                    >
                                        <i className="i-Close-Window text-17 text-danger font-weight-700"/>
                                    </button>
                                )}
                                <div className="position-relative">
                                    {showOptions && masqueradeUsers && masqueradeUsers.length > 0 && (
                                        <ul
                                            className="search-user list-group list-group-flush position-absolute border w-100 text-12"
                                            style={{zIndex: 1, maxHeight: "200px", overflowY: "auto"}}
                                        >
                                            {
                                                masqueradeUsers.map(item => {
                                                    return (
                                                        <li className="search-user cursor-pointer list-group-item"
                                                            key={item.idUser}
                                                            onClick={(e) => {
                                                                formik.setFieldValue('user', item.name);
                                                                formik.setFieldValue('idUser', item.idUser);
                                                                dispatch(resetMasqueradeSuggestions());
                                                            }}
                                                        >
                                                            {item.name}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    )}
                                </div>
                            </FormikControl>
                        </div>
                    )}
                    <div className="form-group mt-1 col-12 text-right">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={formik.isSubmitting}
                        >
                            {formik.isSubmitting ? t('Please wait...') : t('Switch User')}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default MasqueradeForm;
