import React from 'react';
import {Helmet} from "react-helmet";

const HeadTitle = ({title, routeSegments}) => {


    return (
        <Helmet>
            <title>{title || routeSegments.reverse().map(segment => segment?.name).join(' | ')} | Linkeepers Powered by IGL</title>
        </Helmet>
    );
};

export default HeadTitle;
