import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import limApi from "../../apis/limApi";

export const getBoxStocks = createAsyncThunk(
    "boxStock/getStocks",
    async (idBox, thunkApi) => {
        const { rejectWithValue } = thunkApi;
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/stocks?pageSize=10000`);
            return response.data.boxStocks;
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const getBoxStock = createAsyncThunk(
    "boxStock/getStock",
    async ({idBox, idProduct}, thunkApi) => {
        const { rejectWithValue } = thunkApi;
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/stocks/${idProduct}`);
            return response.data.boxStock
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

const initialState = {list: [], stock: null, listLoading: false, loading: false}

const boxStockSlice = createSlice({
    name: 'boxStock',
    initialState,
    reducers: {
        resetBoxStocks: state => {
            state.list = []
        },
        resetBoxStock: state => {
            state.stock = null
        }
    },
    extraReducers: (builder) => {
        builder
            // Box Stocks
            .addCase(getBoxStocks.pending, (state) => {
                state.listLoading = true
            })
            .addCase(getBoxStocks.fulfilled, (state,action) => {
                state.listLoading = false
                state.list = action.payload
            })
            .addCase(getBoxStocks.rejected, (state, action) => {
                state.listLoading = false
            })
            // Box Stock
            .addCase(getBoxStock.pending, (state) => {
                state.loading = true
            })
            .addCase(getBoxStock.fulfilled, (state, action) => {
                state.loading = false
                state.stock = action.payload
            })
            .addCase(getBoxStock.rejected, (state, action) => {
                state.loading = false
                console.log(action.payload)
            })
    }
});

export const { resetBoxStocks, resetBoxStock } = boxStockSlice.actions

export default boxStockSlice.reducer;