import React, { Component } from "react";
import { renderRoutes } from "react-router-config";
import Layout1Sidenav from "./Layout1Sidenav";
import Footer from "../SharedComponents/Footer";
import Layout1Header from "./Layout1Header";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { classList } from "@utils";
import { setLayoutSettings } from "app/redux/slices/layoutSlice";
import {NotificationContainer} from "react-notifications";
import '../../../styles/app/themes/default.scss'
import {Loading, GullSearch, Masquerade} from "@gull";
import {withTheme} from "../../hooks/use-theme";
import {Notifications} from "../../notifications/Notifications";

class Layout1 extends Component {

    componentDidUpdate(prevProps) {
        const { appNotification } = this.props;

        if (appNotification && appNotification !== prevProps.appNotification) {
            const {message, type} = appNotification;
            Notifications( undefined, message, type);
        }
    }

    handleSearchBoxClose = () => {
        let {setLayoutSettings} = this.props;
        setLayoutSettings({
            layout1Settings: {
                searchBox: {
                    open: false
                }
            }
        });
    };

    render() {
        let {settings, routes, adminCustomizer} = this.props;

        return (
            <div>
                <div className={`app-admin-wrap layout-sidebar-large ${this.props.themeName}`}>
                    <Layout1Header/>
                    <Layout1Sidenav/>
                    {/* sidebar */}

                    <div
                        className={classList({
                            "main-content-wrap d-flex flex-column": true,
                            "sidenav-open": settings.layout1Settings.leftSidebar.open
                        })}
                    >
                        {adminCustomizer.loading && <Loading/>}
                        {/*<Suspense fallback={<Loading />}>*/}
                        {!adminCustomizer.loading && <div className="main-content">{renderRoutes(routes)}</div>}
                        {/*</Suspense>*/}
                        {(settings.footer.show && <Footer/>)}
                    </div>
                </div>
                <GullSearch
                    open={settings.layout1Settings.searchBox.open}
                    handleClose={this.handleSearchBoxClose}
                />
                <Masquerade open={settings.layout1Settings.masquerade.open}/>
                <NotificationContainer/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        appNotification: state.global.appNotification,
        setLayoutSettings: PropTypes.func.isRequired,
        settings: state.layout.settings,
        adminCustomizer: state.adminCustomizer
    }
};

export default connect(mapStateToProps, { setLayoutSettings })(withTheme(Layout1));
