import axios from "axios";
import uuid from "uuid";
import * as JWT from "jsonwebtoken";

class sgMail {
    axios;

    constructor() {
        // Create token
        const payload = {
            jti: uuid.v4()
        };

        const token = JWT.sign(
            payload,
            process.env.REACT_APP_LINKBOX_APP_API_TOKEN_KEY,
        );

        this.axios = axios.create({
            baseURL: process.env.REACT_APP_LINKBOX_APP_API_HOST,
            headers: {
                "x-access-token": token
            }
        })
    }
	send = (msg) => {
        return this.axios.post("/api/sendgrid/send", msg)
    };
}

export default new sgMail();