import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {reFetchImagesById} from "./imageSlice";

export const getClients = createAsyncThunk(
    'clients/fetchAll',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/clients?pageSize=10000');
            return response.data.clients;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getClient = createAsyncThunk(
    'clients/fetchOne',
    async (idClient, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}`);
            return response.data.client;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postClient = createAsyncThunk(
    'clients/create',
    async (data, {rejectWithValue}) => {
        try {
            const response = await limApi.post("/clients", data);
            return response.data.client;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putClient = createAsyncThunk(
    'clients/update',
    async ({idClient, data}, {rejectWithValue, dispatch}) => {
        try {
            const client = await limApi.put(`/clients/${idClient}`, {idClient: idClient, ...data}).then(response => response.data.client);

            const reFetchImages = [client.idLogoImage, client.idCoverImage].filter(image => image);
            if (reFetchImages.length) {
                await dispatch(reFetchImagesById(reFetchImages));
            }

            return client;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteClient = createAsyncThunk(
    'clients/delete',
    async (idClient, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/clients/${idClient}`);
            await dispatch(getClients())
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const clientSlice = createSlice({
    name: "clients",
    initialState: {
        clientsList: [],
        accountsList: [],
        listLoading: undefined,
        client: undefined,
        loading: undefined
    },
    reducers: {
        resetClients: state => {
            state.clientsList = [];
            state.accountsList = [];
        },
        resetClient: state => {
            state.client = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getClients.pending, state => {
                if (state.clientsList.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getClients.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.clientsList = action.payload;
                state.accountsList = action.payload.flatMap(item => Object.values(item.accounts))
            })
            .addCase(getClients.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getClient.pending, state => {
                state.loading = true;
            })
            .addCase(getClient.fulfilled, (state, action) => {
                state.loading = undefined;
                state.client = action.payload;
            })
            .addCase(getClient.rejected, state => {
                state.loading = undefined;
            })
            .addCase(putClient.fulfilled, (state, action) => {
                state.client = action.payload;
            })
    }
});

export const {resetClients, resetClient} = clientSlice.actions;

export default clientSlice.reducer;