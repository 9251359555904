import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {postHierarchy, putHierarchy} from "./hierarchySlice";

export const getHierarchyModels = createAsyncThunk(
    'hierarchyModel/fetchAll',
    async (idClientAccount, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/hierarchy/models`);
            return response.data.hierarchyModels;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getHierarchyModel = createAsyncThunk(
    'hierarchyModel/fetchOne',
    async ({idClientAccount, idHierarchyModel}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/hierarchy/models/${idHierarchyModel}`);
            return response.data.hierarchyModel;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postHierarchyModel = createAsyncThunk(
    'hierarchyModel/create',
    async ({idClientAccount, data}, {rejectWithValue, dispatch}) => {
        try {
            const hierarchyModel = await limApi.post(`/client/account/${idClientAccount}/hierarchy/models`, {
                enabled: data.enabled,
                useMasquerade: data.useMasquerade,
                label: data.label
            }).then(response => response.data.hierarchyModel);

            await dispatch(postHierarchy({idClientAccount, idHierarchyModel: hierarchyModel.idHierarchyModel, data: data.items[0]}));

            return hierarchyModel;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putHierarchyModel = createAsyncThunk(
    'hierarchyModel/create',
    async ({idClientAccount, idHierarchyModel, data}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.put(`/client/account/${idClientAccount}/hierarchy/models/${idHierarchyModel}`, {
                idHierarchyModel,
                enabled: data.enabled,
                useMasquerade: data.useMasquerade,
                label: data.label
            });
            await dispatch(putHierarchy({idClientAccount, idHierarchyModel, data: data.items[0]}))
                .unwrap()
                .catch(e => {
                    throw Error(e?.response?.data || e.message);
                });
            return response.data.hierarchyModel;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteHierarchyModel = createAsyncThunk(
    'hierarchyModel/delete',
    async ({idClientAccount, idHierarchyModel}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/client/account/${idClientAccount}/hierarchy/models/${idHierarchyModel}`);
            await dispatch(getHierarchyModels(idClientAccount));
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const hierarchyModelSlice = createSlice({
    name: 'hierarchyModel',
    initialState: {
        list: [],
        listLoading: false,
        model: null,
        loading: false
    },
    reducers: {
        resetHierarchyModels: state => {
            state.list = []
        },
        resetHierarchyModel: state => {
            state.model = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getHierarchyModels.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getHierarchyModels.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addCase(getHierarchyModels.rejected, state => {
                state.listLoading = false;
            })
            .addCase(getHierarchyModel.pending, state => {
                state.loading = true;

            })
            .addCase(getHierarchyModel.fulfilled, (state, action) => {
                state.loading = false;
                state.model = action.payload;
            })
            .addCase(getHierarchyModel.rejected, state => {
                state.loading = false;
            })
            .addCase(putHierarchyModel.fulfilled, (state, action) => {
                state.model = action.payload;
            })
    }
});

export const {resetHierarchyModels, resetHierarchyModel} = hierarchyModelSlice.actions;

export default hierarchyModelSlice.reducer;