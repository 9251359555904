import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {find, isEmpty} from "lodash";
import {getImage} from "../../app/redux/slices/imageSlice";
import {isValidUrl} from "../../@utils";
// import "react-loading-skeleton/dist/skeleton.css";

// const LogoSkeleton = () => (
//     <SkeletonTheme borderRadius="3px">
//         <Skeleton width={120} height={57}/>
//     </SkeletonTheme>
// );

// @see src/app/views/extra-kits/AppLoader.jsx for spinners
const LazyImageApi = (props) => {
    const {idImage, loader, className, ...rest} = props;
    const dispatch = useDispatch();
    const image = useSelector(state => find(state.image.images, (value, id) => id === idImage));
    const [isLoaded, setIsLoaded] = useState(image);

    useEffect(() => {
        if (idImage && idImage !== '00000000-0000-0000-0000-000000000000' ) {
            dispatch(getImage(idImage));
        }
    }, [dispatch, idImage]);

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    if (idImage && idImage !== '00000000-0000-0000-0000-000000000000') {
        return (
            <>
                {isEmpty(image) && !isLoaded && (
                    loader || <div className="lazy-image-loading">
                        <div className="spinner spinner-light"/>
                    </div>
                )}
                <img
                    alt=""
                    src={isValidUrl(image) ? image : `data:image/png;base64,${image}`}
                    className={`${className} lazy-image ${isLoaded ? 'lazy-image-loaded' : ''}`}
                    onLoad={handleImageLoad}
                    {...rest}
                />
            </>
        );
    }

    return <img alt="" src="/assets/images/Product_Defaut_Linkeepers.jpg" className={className} {...rest} />;
};

export default LazyImageApi;
