import {createAsyncThunk, createSlice, isAnyOf} from "@reduxjs/toolkit";
import {selectUserIdClientAccount} from "./userSlice";
import limApi from "../../apis/limApi";
import {getDocument} from "./documentSlice";
import {isEqual} from "lodash";

export const getCheckoutDocuments = createAsyncThunk(
    'checkoutDocuments/fetchAll',
    async (idCart, {rejectWithValue, getState, dispatch}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const activeWarehouse = getState().warehouse.active;

            const documents = await limApi.get(`/clients/accounts/${idClientAccount}/warehouse/${activeWarehouse.idWarehouse}/checkout/documents/${idCart}`).then(response => response.data.documents);

            if (documents.length) {
                for (const document of documents) {
                    await dispatch(getDocument(document.idDocument));
                }
            }

            return documents;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postCheckoutDocument = createAsyncThunk(
    'checkoutDocuments/create',
    async ({idCart, documentBase64, values}, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const activeWarehouse = getState().warehouse.active;

            const response = await limApi.post(`/clients/accounts/${idClientAccount}/warehouse/${activeWarehouse.idWarehouse}/checkout/documents`,{
                idCart,
                document: `${documentBase64}`,
                documentDescription: values.fileTitle,
                documentName: values.name,
                documentFileName: values.name,
                documentSize: parseInt(values.size),
                displayPosition: values.displayPosition,
                fileType: values.ext
            });

            return response.data.document;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putCheckoutDocument = createAsyncThunk(
    'checkoutDocuments/update',
    async ({idCart, idCartDocument , documentBase64, values}, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const activeWarehouse = getState().warehouse.active;

            const response = await limApi.put(`/clients/accounts/${idClientAccount}/warehouse/${activeWarehouse.idWarehouse}/checkout/documents/${idCartDocument}`,{
                idCart,
                idCartDocument,
                idDocument: values.fileKey,
                // document: `${documentBase64}`,
                documentDescription: values.fileTitle,
                documentName: values.name,
                documentFileName: values.name,
                documentSize: parseInt(values.size),
                displayPosition: values.displayPosition,
                fileType: values.ext
            });

            return response.data.document;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteCheckoutDocument = createAsyncThunk(
    "checkoutDocuments/delete",
    async (idCartDocument, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const activeWarehouse = getState().warehouse.active;

            await limApi.delete(`/clients/accounts/${idClientAccount}/warehouse/${activeWarehouse.idWarehouse}/checkout/documents/${idCartDocument}`);
            return idCartDocument;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const checkoutDocumentsSlice = createSlice({
    name: "checkoutDocuments",
    initialState: {
        list: [],
        listLoading: undefined
    },
    reducers: {
        resetCheckoutDocuments: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCheckoutDocuments.pending, state => {
                state.listLoading = true;
            })
            .addCase(getCheckoutDocuments.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getCheckoutDocuments.rejected, state => {
                state.listLoading = undefined;
            })
            // .addCase(postCheckoutDocument.fulfilled, (state, action) => {
            //     state.documents.push({...action.payload, document: null});
            // })
            // .addCase(putCheckoutDocument.fulfilled, (state, action) => {
            //     const document = action.payload;
            //     const index = state.documents.findIndex((d) => d.idDocument === document.idDocument);
            //
            //     if (index !== -1) {
            //         // If the object with a matching id exists, update it
            //         state.documents[index] = document;
            //         state.documents[index].document = null;
            //     }
            // })
            // .addCase(deleteCheckoutDocument.fulfilled, (state, action) => {
            //     state.documents = state.documents.filter((d) => d.idCartDocument !== action.payload);
            // })
            .addMatcher(isAnyOf(postCheckoutDocument.pending, putCheckoutDocument.pending, deleteCheckoutDocument.pending), state => {
                state.listLoading = true;
            })
            // .addMatcher(isAnyOf(postCheckoutDocument.fulfilled, putCheckoutDocument.fulfilled, deleteCheckoutDocument.fulfilled), state => {
            //     state.documentsLoading = undefined;
            // })
            .addMatcher(isAnyOf(postCheckoutDocument.rejected, putCheckoutDocument.rejected, deleteCheckoutDocument.rejected), state => {
                state.listLoading = undefined;
            })
    }
});

export const updateCheckoutDocuments = (idCart, documents) => async (dispatch, getState) => {
    if (documents[0] && !documents[0].fileKey) return;
    const checkoutDocuments = getState().checkoutDocuments.list;

    const newDocuments = documents.filter(doc => doc.isNew);
    const existingDocuments = documents.filter(doc => !doc.isNew && checkoutDocuments.length > 0);

    const checkoutDocumentsKeys = checkoutDocuments.map(value => value.idDocument);
    const fieldDocumentKeys = documents.map(value => value.fileKey);
    const deletedDocuments = checkoutDocumentsKeys.filter(x => !fieldDocumentKeys.includes(x));

    if (isEqual(checkoutDocumentsKeys, fieldDocumentKeys)) return;

    const deletePromises = deletedDocuments.map(idDocument => {
        const deletedDocument = checkoutDocuments.find(item => item.idDocument === idDocument);
        if (deletedDocument) {
            return dispatch(deleteCheckoutDocument(deletedDocument.idCartDocument))
        }
    });

    const existingDocumentPromise = existingDocuments.map(doc => {
        const documentDetails = checkoutDocuments.find(item => item.idDocument === doc.fileKey);
        if (documentDetails &&
            (
                documentDetails.displayPosition !== doc.displayPosition ||
                documentDetails.documentName !== doc.fileTitle ||
                documentDetails.documentFileName !== doc.name
            )
        ) {
            return dispatch(putCheckoutDocument({
                idCart,
                idCartDocument: documentDetails.idCartDocument,
                // documentBase64: doc.base64.split(',')[1],
                values: doc
            }))
        }
    });

    const newDocumentPromises = newDocuments.map(doc => {
        return dispatch(postCheckoutDocument({
            idCart,
            documentBase64: doc.base64.split(',')[1],
            values: doc
        }))
    });

    const allPromises = [...deletePromises, ...existingDocumentPromise, ...newDocumentPromises].filter(prom => prom);

    if (allPromises.length) {
        await Promise.all(allPromises);
        await dispatch(getCheckoutDocuments(idCart));
    }
}

export const {resetCheckoutDocuments} = checkoutDocumentsSlice.actions;

export default checkoutDocumentsSlice.reducer;