import React from 'react';
import {FaRegFileArchive, FaRegFileExcel, FaRegFilePdf, FaRegFilePowerpoint, FaRegFileWord} from "react-icons/fa";

const PreviewFile = (props) => {
    const {file, ...rest} = props;
    const imageExtension = ['tif', 'bmp', 'jpeg', 'jpg', 'gif', 'png', 'eps'];

    return (
        <div {...rest}>
            {imageExtension.includes(file.ext) && <img src={file.base64} alt="preview"/>}
            {file.ext === 'pdf' && <FaRegFilePdf />}
            {(file.ext === 'doc' || file.ext === 'docx') && <FaRegFileWord />}
            {(file.ext === 'xls' || file.ext === 'xlsx') && <FaRegFileExcel />}
            {(file.ext === 'zip' || file.ext === 'rar') && <FaRegFileArchive />}
            {(file.ext === 'ppt' || file.ext === 'pptx') && <FaRegFilePowerpoint />}
        </div>
    )
};

export default PreviewFile;
