import React, {useCallback, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {classList} from "@utils";
import ScrollBar from "react-perfect-scrollbar";
import {Form, Formik} from 'formik';
import {useTranslation} from "react-i18next";
import {merge} from "lodash";
import {getClientAccounts} from "../../views/app/client-admin/clientAdminUtils";
import FormikControl from "../../formic/FormikControl";
import {
    cancelAdminCustomizerSettings,
    getAdminCustomizerSettings,
    setAdminCustomizerOpen,
    setAdminCustomizerSettings
} from "../../redux/slices/adminCustomizerSlice";
import {getWarehouses} from "../../redux/slices/warehouseSlice";
import OutsideAlerter from "../../../@gull/components/OutsideAlerter";
import * as Yup from 'yup';
import history from "../../../@history";

const formInitialValues = {
    idClient: '',
    idClientAccount: '',
    idWarehouse: '',
    clientAccounts: []
};

const AdminCustomizer = ({ warehouses, activeWH }) => {
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const { t } = useTranslation();

    const { clientsList } = useSelector((state) => state.clients ?? { clientsList: [], accountsList: [] });
    const user = useSelector((state) => state.user);
    const {settings: adminCustomizerSettings, open: customizerOpen} = useSelector((state) => state.adminCustomizer);

    useEffect(() => {
        dispatch(getAdminCustomizerSettings());
    }, [dispatch]);

    useEffect(() => {
        if (adminCustomizerSettings) {
            merge(formInitialValues, adminCustomizerSettings);
            formInitialValues.clientAccounts = getClientAccounts(adminCustomizerSettings.idClient, clientsList, true).filter(account => user.idClient ? user.accounts.map(acc => acc.idClientAccount).includes(account.value) : true);
        } else {
            formInitialValues.idClient = "";
            formInitialValues.idClientAccount = '';
            formInitialValues.idWarehouse = '';
            formInitialValues.clientAccounts = [];
        }
    }, [adminCustomizerSettings, clientsList, user.accounts, user.idClient]);

    useEffect(() => {
        if (user?.idClient) {
            const clientAccounts = [
                { label: t("-- None --"), value: "" },
                ...user.accounts.map((account) => {
                    const accountName =
                        user.client.accounts.find(
                            (clientAccount) =>
                                clientAccount.idClientAccount === account.idClientAccount
                        )?.accountName;

                    if (accountName) {
                        return { value: account.idClientAccount, label: accountName };
                    }
                    return false;
                }).filter(Boolean),
            ];

            formInitialValues.idClient = user.idClient;
            formInitialValues.clientAccounts = clientAccounts;
        }
    }, [t, user]);

    if (activeWH) {
        formInitialValues.idWarehouse = activeWH.idWarehouse;
    }

    const handleCustomizerToggle = () => {
        dispatch(setAdminCustomizerOpen(!customizerOpen));
    };

    const clientsOptionList = clientsList.map((client) => {
        return { value: client.idClient, label: client.clientName };
    });

    const warehouseOptions = warehouses.map((warehouse) => {
        return { value: warehouse.idWarehouse, label: warehouse.warehouseName };
    });

    warehouseOptions.unshift({
        label: t("-- None --"),
        value: "",
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const data = { ...values };
        delete data.clientAccounts;

        dispatch(setAdminCustomizerOpen(false));
        setSubmitting(false);

        dispatch(setAdminCustomizerSettings(data));
        await history.replace("/");
    };

    const handleCancel = () => {
        dispatch(setAdminCustomizerOpen(false));
        dispatch(cancelAdminCustomizerSettings())
    }

    const confirmAction = useCallback(() => {
            const {idClient, idClientAccount} = formInitialValues;

            if (idClient && idClientAccount) {
                dispatch(getWarehouses({idClient, idClientAccount}));
            }
            dispatch(setAdminCustomizerOpen(false))
            formRef.current.resetForm();
        },
        [dispatch],
    );

    const dismissAction = () => {
        // setCustomizerOpen(false);
    }

    return (
        <OutsideAlerter
            show={customizerOpen}
            confirmAction={confirmAction}
            dismissAction={dismissAction}
        >
            <div
                id="customizer"
                className={classList({
                    customizer: true,
                    open: customizerOpen
                })}
            >
                <div className="handle" onClick={handleCustomizerToggle}>
                    <i className="i-Gear"></i>
                </div>
                <ScrollBar
                    className="customizer-body"
                    data-perfect-scrollbar
                    data-suppress-scroll-x="true"
                >
                    <div className="accordion" id="accordionCustomizer">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <p className="mb-0">{t('Administrator View')}</p>
                            </div>

                            <div
                                id="collapseOne"
                                className="collapse show"
                                aria-labelledby="headingThree"
                                data-parent="#accordionCustomizer"
                            >
                                <div className="card-body">
                                    <Formik
                                        initialValues={formInitialValues}
                                        validationSchema={Yup.object().shape({
                                            idClient: Yup.string().required(t('Client is required.')),
                                            idClientAccount: Yup.string().required(t('Account is required.')),
                                            idWarehouse: Yup.string().required(t('Warehouse is required.'))
                                        })}
                                        onSubmit={handleSubmit}
                                        enableReinitialize={true}
                                        innerRef={formRef}
                                    >
                                        {(formik) => (
                                            <Form className="row">
                                                {!user?.idClient && (
                                                    <FormikControl
                                                        groupClass="col-12"
                                                        control="select"
                                                        label={t("Client")}
                                                        name="idClient"
                                                        touched={formik.touched.idClient}
                                                        errors={formik.errors.idClient}
                                                        value={formik.values.idClient}
                                                        onChange={async (e) => {
                                                            const { value } = e.target;
                                                            const _clientAccounts = getClientAccounts(
                                                                value,
                                                                clientsList,
                                                                true,
                                                            ).filter(account => user.idClient ? user.accounts.map(acc => acc.idClientAccount).includes(account.value) : true);

                                                            formik.setFieldValue("idClient", value);
                                                            formik.setFieldValue("clientAccounts", _clientAccounts);

                                                            if (_clientAccounts.length === 2) {
                                                                const idClientAccount = _clientAccounts[1].value;
                                                                formik.setFieldValue("idClientAccount", idClientAccount);
                                                                await dispatch(getWarehouses({idClient: value, idClientAccount}))
                                                                    .unwrap()
                                                                    .then(warehouses => {
                                                                        if (warehouses.length === 1) {
                                                                            formik.setFieldValue("idWarehouse", warehouses[0].idWarehouse);
                                                                        } else {
                                                                            formik.setFieldValue("idWarehouse", "");
                                                                        }
                                                                    })
                                                            } else {
                                                                formik.setFieldValue("idClientAccount", "");
                                                                formik.setFieldValue("idWarehouse", "");
                                                            }
                                                        }}
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: t("-- {{t}} --", {t: t("None")}),
                                                            },
                                                            ...clientsOptionList,
                                                        ]}
                                                    />
                                                )}
                                                {formik.values.idClient && (
                                                    <FormikControl
                                                        groupClass="col-12"
                                                        control="select"
                                                        label={t("Account")}
                                                        name="idClientAccount"
                                                        touched={formik.touched.idClientAccount}
                                                        errors={formik.errors.idClientAccount}
                                                        onChange={async (e) => {
                                                            const { value: idClientAccount } = e.target;
                                                            formik.setFieldValue(
                                                                "idClientAccount",
                                                                idClientAccount,
                                                            );
                                                            await dispatch(getWarehouses({idClient: formik.values.idClient, idClientAccount}))
                                                                .unwrap()
                                                                .then(warehouses => {
                                                                    if (warehouses.length === 1) {
                                                                        formik.setFieldValue("idWarehouse", warehouses[0].idWarehouse);
                                                                    } else {
                                                                        formik.setFieldValue("idWarehouse", "");
                                                                    }
                                                                })

                                                        }}
                                                        options={formik.values.clientAccounts ?? []}
                                                    />
                                                )}
                                                {formik.values.idClientAccount && (
                                                    <FormikControl
                                                        groupClass="col-12"
                                                        control="select"
                                                        label={t("Warehouse")}
                                                        name="idWarehouse"
                                                        touched={formik.touched.idWarehouse}
                                                        errors={formik.errors.idWarehouse}
                                                        options={warehouseOptions}
                                                    />
                                                )}
                                                {formik.values.idClientAccount && (
                                                    <div className="form-group mt-1 col-12">
                                                        {adminCustomizerSettings && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary mr-3"
                                                                onClick={handleCancel}
                                                            >
                                                                {t("Cancel")}
                                                            </button>
                                                        )}
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary float-right"
                                                            disabled={formik.isSubmitting}
                                                        >
                                                            {formik.isSubmitting ? t('Please wait...') : t('Apply')}
                                                        </button>
                                                    </div>
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollBar>
            </div>
        </OutsideAlerter>
    );
}

export default AdminCustomizer;
