export default {
    from: 'IGLEDIService@igl-concept.com',
    appUrl: process.env.REACT_APP_MSAL_REDIRECT_URI,
    templatesId: {
        user: {
            create: {
                en: "d-decf7874a812439f8fdfca9cb55541a6",
                fr: "d-b6dfa53460904d77837a520efca9b007"
            }
        },
        box: {
            addUser: {
                en: "d-f278d31bf74c47919474596a5d6bed94",
                fr: "d-38a5f29acc1c4494b75ab59dda746627"
            },
            removeUser: {
                en: "d-e4c150c127654aa1ace5f30909430fbf",
                fr: "d-fcde5d44ef734dcd8ac087116777b04b"
            }
        },
        order: {
            confirmation: {
                en: "d-4eb2088165554b39803bca61f2525bae",
                fr: "d-6230109f6c9c4b8e9435f231b9f73424"
            },
            delivered: {
                en: "",
                fr: ""
            }
        }
    }
};