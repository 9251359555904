import React, { Component } from "react";
import { classList } from "@utils";
import { Link, withRouter } from "react-router-dom";
import {connect} from "react-redux";
import {resetEcommerceSearchProducts, ecommerceSearchProducts} from "../../app/redux/slices/ecommerceSlice";
import LazyImageApi from "./LazyImageAPI";
import {withTranslation} from "react-i18next";
import Pagination from "./Pagination";
import {isEmpty} from "lodash";
import {Alert} from "react-bootstrap";
import {formatPrice} from "../../app/views/app/ecommerce/ecommerceUtils";

class GullSearch extends Component {

  // componentDidMount() {
    // this.props.searchProducts('');
  // }

  componentWillUnmount() {
    this.clearQuery();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.productsList && prevProps.productsList !== this.props.productsList) {
      this.setState({searchList: this.props.productsList})
    }
  }

  debounceTimer = null;
  state = {
    query: "",
    searchList: this.props.productsList,
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextProps.open !== this.props.open ||
        nextProps.offset !== this.props.offset ||
        nextProps.endOffset !== this.props.endOffset ||
        nextProps.productsList !== this.props.productsList ||
        nextState.searchList !== this.state.searchList
  }

  //show searched results
  handleSearch = event => {
    event.persist();
    this.setState({ query: event.target.value }, () => {
      if (this.debounceTimer) clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        if (!isEmpty(this.state.query)) {
          this.props.ecommerceSearchProducts(this.state.query);
        } else {
          this.props.resetEcommerceSearchProducts();
        }
      }, 350);
    });
  };

  clearQuery = () => {
    this.props.resetEcommerceSearchProducts();
    this.setState({
      query: "",
      searchList: []
    });
    document.getElementsByClassName('search-input')[0].value = ''
  }

  render() {
    let { t, open, handleClose, offset, endOffset, searchProductsLoading } = this.props;
    let { searchList } = this.state;
    const hasPrice = searchList.some(item => item.priceSell > 0)

    return (
      <div
        className={classList({
          "search-ui position-fixed": true,
          open: open
        })}
      >
        <div className="search-header o-hidden">
          <img
            src="/assets/images/Logo_Linkeepers_big.png"
            alt=""
            className="logo float-left"
          />
          <button
            className="search-close btn btn-icon bg-transparent float-right mt-2"
            onClick={() => {
              this.clearQuery()
              handleClose()
            }}
          >
            <i className="i-Close-Window text-22 text-dark"></i>
          </button>
        </div>

        {/*search input*/}
        <input
          type="text"
          placeholder={t("Type here")}
          className="search-input d-block w-100"
          autoFocus
          onChange={this.handleSearch}
        />

        <div className="search-title">
          <span className="text-dark">{t("Search results")}</span>
        </div>

        <div className="search-results list-horizontal" >
          {this.state.query.length > 0 && this.state.searchList.length === 0 && !searchProductsLoading && (
              <Alert className="text-center alert-card" variant="warning">
                {t("No matching results found!")}
              </Alert>
          )}

          {
            //default search list before searching
            searchList &&
            searchList.slice(offset,endOffset).map((item, index) => (
            <div key={index} className="list-item col-md-12 p-0">
              <div className="card o-hidden flex-row mb-3 d-flex">
                <div className="list-thumb d-flex position-relative" style={{width: "120px", minHeight: "74px"}}>
                  {/* <!-- TUMBNAIL --> */}
                  {
                    item.idImage &&
                    <Link
                        to={`/product/${item.idProduct}`}
                        onClick={(e) => {
                          this.clearQuery()
                          handleClose()
                        }}
                    >
                      <LazyImageApi idImage={item.idImage} width="120px" />
                    </Link>
                  }
                  {
                    !item.idImage && item.images &&
                    <Link
                        to={`/product/${item.idProduct}`}
                        onClick={(e) => {
                          this.clearQuery()
                          handleClose()
                        }}
                    >
                      <LazyImageApi idImage={item?.images[0]?.idImage} width="120px"/>
                    </Link>
                  }
                </div>
                <div className="flex-grow-1 pl-2 d-flex">
                  <div className="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                    {/* <!-- OTHER DATA --> */}
                    <Link
                      to={`/product/${item.idProduct}`}
                      className="w-25 w-40 w-sm-100"
                      onClick={(e) => {
                        this.clearQuery()
                        handleClose()
                      }}
                    >
                      {item.productName}
                    </Link>
                    <p className="w-25 m-0 text-dark text-small w-15 w-sm-100">
                      {item.skuClient}
                    </p>
                    <p className="w-25 m-0 text-muted text-small w-15 w-sm-100">
                      {item.productFamily}
                    </p>
                    <p className="w-25 m-0 text-dark text-small w-15 w-sm-100">
                      {item.productSubFamily}
                    </p>
                    {hasPrice &&
                      <p className="w-15 m-0 text-dark text-small w-15 w-sm-100">
                        {formatPrice(item.priceSell)}
                      </p>
                    }
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {
          searchList.length > 5 &&
              <div className="pt-3 pb-2">
                <Pagination searchList={searchList} />
              </div>
        }
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    productsList: state.ecommerce.searchProducts,
    searchProductsLoading: state.ecommerce.searchProductsLoading,
    offset: state.ecommerce.offsetSearchProducts,
    endOffset: state.ecommerce.offsetSearchEnd
  };
}

export default connect(mapStateToProps, {
  ecommerceSearchProducts,
  resetEcommerceSearchProducts
})(withRouter((withTranslation()(GullSearch))))
