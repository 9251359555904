import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getClientUsers = createAsyncThunk(
    'clientUser/fetchAll',
    async ({idClient, queryParams = "", jsonParams}, {rejectWithValue}) => {
        try {
            const response = jsonParams ? await limApi.put(`/clients/${idClient}/users`, jsonParams) : await limApi.get(`/clients/${idClient}/users?${queryParams}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const clientUserSlice = createSlice({
    name: 'clientUser',
    initialState: {
        list: [],
        listLoading: undefined
    },
    reducers: {
        resetClientUsers: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getClientUsers.pending, state => {
                state.listLoading = true
            })
            .addCase(getClientUsers.fulfilled, (state, action) => {
                state.listLoading = undefined
                state.list = action.payload;
            })
            .addCase(getClientUsers.rejected, state => {
                state.listLoading = undefined
            })
    }
});

export const {resetClientUsers} = clientUserSlice.actions;

export default clientUserSlice.reducer;