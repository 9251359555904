import {useMemo} from "react";

const useQuery = (jsonParse = false) => {
	const { search } = window.location;

	return useMemo(() => {
		const params = new URLSearchParams(search);

		let paramObj = {};
		for (const [key, value] of params.entries()) {
			if (typeof value === "string" && jsonParse) {
				try {
					paramObj[key] = JSON.parse(value, (key, value) => {
						if (typeof value === "object") {
							return value;
						}
						return value.toString();
					})
				} catch (e) {
					paramObj[key] = value.toString();
				}
			} else {
				paramObj[key] = value;
			}
		}

		return paramObj;
	}, [jsonParse, search]);
};

export default useQuery;
