import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import pagesRoutes from "./views/pages/pagesRoutes";
import ecommerceRoutes from "./views/app/ecommerce/ecommerceRoutes";
import dataOrdersRoute from "./views/orders/ordersRoutes";
import boxManagementRoutes from "./views/box-management/boxManagementRoutes";
import boxAdminRoutes from './views/app/box-admin/boxAdminRoutes';
import clientAdminRoutes from "./views/app/client-admin/clientAdminRoutes";
import catalogAdminRoutes from "./views/app/catalog-admin/catalogAdminRoutes";
import userRoutes from "./views/app/user/userRoutes";
import zendeskRoutes from "./zendesk/zendeskRoutes";
import quotasRoutes from "./views/quotas/quotasRoutes";
import reportsRoutes from "./views/reports/reportsRoutes";
import eventsRoutes from "./views/events/eventsRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionsRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...zendeskRoutes,
      ...dashboardRoutes,
      ...pagesRoutes,
      ...ecommerceRoutes,
      ...dataOrdersRoute,
      ...boxManagementRoutes,
      ...boxAdminRoutes,
      ...clientAdminRoutes,
      ...catalogAdminRoutes,
      ...userRoutes,
      ...reportsRoutes,
      ...quotasRoutes,
      ...eventsRoutes,
      ...redirectRoute,
      ...errorRoute
    ]
  }
];

export default routes;
