import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {getEcommerceCatalogs} from "./ecommerceSlice";

export const getCategories = createAsyncThunk(
    "category/fetchAll",
    async ({idClient, idClientAccount, idCatalog}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}/categories?pageSize=1000&sort=[{"property":"displayPosition","direction":"asc"}]`);

            return response.data.categories;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getCategory = createAsyncThunk(
    "category/fetchOne",
    async ({idClient, idClientAccount, idCatalog, idCatalogCategory}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}/categories/${idCatalogCategory}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postCategory = createAsyncThunk(
    "category/create",
    async ({idClient, idClientAccount, idCatalog, category}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}/categories`, {
                idCatalog,
                ...category
            })

            await dispatch(getEcommerceCatalogs('?sort=[{"property":"displayPosition","direction":"asc"}]'));
            await dispatch(getCategories({idClient, idClientAccount, idCatalog}));
            return response.data.category;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putCategory = createAsyncThunk(
    "category/update",
    async ({idClient, idClientAccount, idCatalog, idCatalogCategory, category}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.put(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}/categories/${idCatalogCategory}`, {
                idCatalog: idCatalog,
                ...category
            })

            await dispatch(getEcommerceCatalogs('?sort=[{"property":"displayPosition","direction":"asc"}]'));
            await dispatch(getCategories({idClient, idClientAccount, idCatalog}));
            return response.data.category;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteCategory = createAsyncThunk(
    "category/delete",
    async ({idClient, idClientAccount, idCatalog, idCatalogCategory}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/catalogs/${idCatalog}/categories/${idCatalogCategory}`)
            await dispatch(getEcommerceCatalogs('?sort=[{"property":"displayPosition","direction":"asc"}]'));
            await dispatch(getCategories({idClient, idClientAccount, idCatalog}));

            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const categorySlice = createSlice({
    name: "category",
    initialState: {
        list: [],
        listLoading: undefined,
        category: undefined,
        loading: undefined
    },
    reducers: {
        resetCategories: state => {
            state.list = [];
        },
        resetCategory: state => {
            state.category = undefined;
        },
        editCreateCategory: (state, action) => {
            state.change = action.payload;
        },
        getParentCategory: (state, action) => {
            state.parentCategory = action.payload;
        },
        setActiveCategory: (state, action) => {
            state.selectedCategory = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCategories.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getCategories.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getCategory.pending, state => {
                state.loading = true;
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                state.loading = undefined;
                state.category = action.payload;
            })
            .addCase(getCategory.rejected, state => {
                state.loading = undefined;
            })
    }
});

export const {resetCategories, resetCategory, editCreateCategory, getParentCategory, setActiveCategory} = categorySlice.actions;

export default categorySlice.reducer;