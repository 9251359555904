import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {emptyCart} from "./cartSlice";
import {resetAdminCustomizerSettings} from "./adminCustomizerSlice";
import {resetActiveWarehouse, resetWarehouses} from "./warehouseSlice";
import {resetDashboard} from "./dashboardSlice";
import localStorageService from "../../services/localStorageService";
import history from "../../../@history";
import {batch} from "react-redux";
import {initUserData} from "./userSlice";
import {resetClients} from "./clientSlice";
import {resetActiveEvent} from "./eventsSlice";

export const masqueradeUserSearch = createAsyncThunk(
    "masquerade/userSearch",
    async ({query, idClientAccount}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/masquerade/${idClientAccount}/users/${query}`);
            return response.data.masqueradeUsers;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putMasqueradeSession = createAsyncThunk(
    "masquerade/sessionManage",
    async ({idUserMasquerade, isOpen, idClientAccount}, {rejectWithValue}) => {
        try {
            const response = await limApi.put('/masquerade/session', {idUserMasquerade, isOpen, idClientAccount});
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const masqueradeSlice = createSlice({
    name: "masquerade",
    initialState: {
        session: localStorageService.getItem("masquerade") && localStorageService.getItem("masqueradeSid") ? {
            masquerade: localStorageService.getItem("masquerade"),
            masqueradeSid: localStorageService.getItem("masqueradeSid")
        } : undefined,
        suggestions: undefined,
        suggestionsLoading: undefined,
    },
    reducers: {
        setMasqueradeSession: (state, action) => {
            state.session = action.payload;
        },
        resetMasqueradeSuggestions: state => {
            state.suggestions = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(masqueradeUserSearch.pending, state => {
                state.suggestionsLoading = true;
            })
            .addCase(masqueradeUserSearch.fulfilled, (state, action) => {
                state.suggestionsLoading = undefined;
                state.suggestions = action.payload;
            })
            .addCase(masqueradeUserSearch.rejected, state => {
                state.suggestionsLoading = undefined;
            })
    }
});

const masqueradeResetAllState = (dispatch) => {
    dispatch(resetActiveWarehouse());
    dispatch(resetWarehouses());
    dispatch(resetActiveEvent());
    dispatch(resetClients());
    dispatch(emptyCart());
    dispatch(resetAdminCustomizerSettings());
    dispatch(resetDashboard());
    dispatch(initUserData());
};

export const masqueradeSessionOpen = (idUserMasquerade, idClientAccount) => async (dispatch) => {
    return await dispatch(putMasqueradeSession({idUserMasquerade, isOpen: true, idClientAccount}))
        .unwrap()
        .then(response => {
            if (!response.idMasquerade) return;

            localStorageService.setItem("masquerade", idUserMasquerade);
            localStorageService.setItem("masqueradeSid", response.idMasquerade);
            dispatch(setMasqueradeSession({masquerade: idUserMasquerade, masqueradeSid: response.idMasquerade, idClientAccount}))

            history.replace("/");
            batch(() => masqueradeResetAllState(dispatch));

            return response;
        });
};

export const masqueradeSessionClose = () => async (dispatch, getState) => {
    const masqueradeSession = getState().masquerade.session;

    // Close session even if the API fails;
    dispatch(setMasqueradeSession(undefined));
    localStorageService.removeItem("masquerade");
    localStorageService.removeItem("masqueradeSid")

    history.replace("/");
    batch(() => masqueradeResetAllState(dispatch));

    await dispatch(putMasqueradeSession({idUserMasquerade: masqueradeSession.masquerade, isOpen: false})).catch((e) => void e);
};

export const {resetMasqueradeSuggestions, setMasqueradeSession} = masqueradeSlice.actions;

export default masqueradeSlice.reducer;