import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import CAN from "../../app/casl/Can";
import {useTranslation} from "react-i18next";
import useTheme from "../../app/hooks/use-theme";
import {hardReloadApp} from "../../@utils";
import HeadTitle from "../../app/GullLayout/SharedComponents/HeadTitle";

const MaintenanceMode = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [maintenance, setMaintenance] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ready, setReady] = useState(true);

    const checkMaintenance = useCallback(() => {
        axios({
            url: '/maintenance.json',
            method: "GET",
            responseType: "json"
        })
            .then(response => {
                if (response.status === 200 && response.data) {
                    return response.data;
                }
            })
            .then(response => {
                if (response) {
                    setMaintenance(prevState => {
                        if (prevState !== response.enabled && prevState === false) {
                            return response.enabled;
                        }
                        return prevState;
                    });
                    setReady(!response.enabled)
                }
            })
            .catch(e => undefined)
            .finally(() => {
                setLoading(false);
            });
    }, [])

    useEffect(() => {
        checkMaintenance();
        
        const interval = setInterval(() => {
            checkMaintenance();
        }, 20000);
        
        return () => {
            clearInterval(interval);
        }
    }, [checkMaintenance]);

    return (
        <>
            {!CAN("View", "CoreMaintenance") && maintenance && !loading ? (
                <div className={`not-found-wrap text-center ${theme} mt-5`}>
                    <img src="/assets/images/Logo_Linkeepers_big.png" alt="logo" className="max-width-200 pb-5"/>
                    {!ready && (
                        <>
                            <HeadTitle title={t("We are currently on maintenance!")}/>
                            <h1 className="text-60 text-dark">{t("We are currently on maintenance!")}</h1>
                            <p className="mb-5 text-36 text-dark">
                                {t("We will be back soon!")}
                            </p>
                        </>
                    )}
                    {ready && (
                        <>
                            <HeadTitle title={t("We are ready to go!")}/>
                            <h1 className="text-60 text-dark">{t("We are ready to go!")}</h1>
                            <p className="mb-5 text-36 text-dark">
                                {t("Please click on refresh button below!")}
                            </p>
                            <button className="btn btn-lg btn-primary btn-rounded" onClick={hardReloadApp}>{t("Refresh")}!</button>
                        </>
                    )}
                </div>
            ) : props.children}
        </>
    )
}

export default MaintenanceMode;