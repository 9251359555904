import React, {useEffect, useRef, useState} from "react";
import {ConfirmNotification} from "../../app/notifications/Notifications";
import {useTranslation} from "react-i18next";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, show, confirmAction, dismissAction) {
    // this state prevent infinity opening modal
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            // console.log(isOpen)
            if (ref.current && !ref.current.contains(event.target) && show && !isOpen) {
                setIsOpen(true);
                const title = t('Exit without apply changes!');
                const confirmButtonText = t('Yes');
                ConfirmNotification(title, null, confirmButtonText)
                    .then(result => {
                        if (result.isConfirmed && confirmAction) {
                            confirmAction();
                        } else if (result.isDismissed && dismissAction) {
                            dismissAction();
                        }
                        // click on element befor confirmation open
                        if (result.isConfirmed) {
                            event.target.click();
                        }
                        setIsOpen(false);
                    })
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [ref, show, confirmAction, dismissAction, isOpen, t]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter(props) {
    const {show, confirmAction, dismissAction} = props;
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, show, confirmAction, dismissAction);

    return (
        <>
            <div ref={wrapperRef}>{props.children}</div>
        </>
    );
}