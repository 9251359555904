import {createAsyncThunk, createSlice, isPending, isFulfilled, isRejectedWithValue, isAnyOf} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {getBoxes} from "./boxSlice";
import {getInboundOrderStatusTypes} from "./inboundOrdersSlice";
import {selectUserIdClientAccount} from "./userSlice";

export const getWarehouseStockReport = createAsyncThunk(
    'reports/fetchWarehouseStock',
    async ({date, idWarehouse, queryParams = ""}, {getState, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/warehouse/${idWarehouse}/stocklevels/date/${date}?${queryParams}`);
            return response.data
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getWarehouseStockDetailsReport = createAsyncThunk(
    'reports/fetchWarehouseStockDetails',
    async ({date, idWarehouse, queryParams = ""}, {getState, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/warehouse/${idWarehouse}/stocklevels/details/date/${date}?${queryParams}`);
            return response.data
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getWarehouseStockProductReport = createAsyncThunk(
    'reports/fetchWarehouseStockProduct',
    async ({date, idWarehouse, idProduct}, {getState, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/warehouse/${idWarehouse}/stocklevels/date/${date}/product/${idProduct}`);
            return response.data.reportStock
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getWarehouseOutboundReport = createAsyncThunk(
    'reports/fetchWarehouseOutbound',
    async ({queryParams = ""}, {getState, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/warehouse/outboundorders?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getWarehouseOutboundDetailsReport = createAsyncThunk(
    'reports/fetchWarehouseOutboundDetails',
    async ({queryParams = ""}, {getState, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/warehouse/outboundorders/details?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const getBoxStockReport = createAsyncThunk(
    'reports/fetchBoxStock',
    async ({date, idBox, queryParams = ""}, {getState, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/box/${idBox}/stocklevels/date/${date}?${queryParams}`);
            return response.data
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getBoxStockDetailsReport = createAsyncThunk(
    'reports/fetchBoxStockDetails',
    async ({date, idBox, queryParams = ""}, {getState, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/box/${idBox}/stocklevels/details/date/${date}?${queryParams}`);
            return response.data
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getBoxStockProductReport = createAsyncThunk(
    'reports/fetchBoxStockProduct',
    async ({date, idBox, idProduct}, {getState, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/box/${idBox}/stocklevels/date/${date}/product/${idProduct}`);
            return response.data.reportStock
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getBoxOutboundReport = createAsyncThunk(
    'reports/fetchBoxOutbound',
    async ({queryParams = ""}, {getState, dispatch, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/box/outboundorders?${queryParams}`);

            await dispatch(getBoxes({queryParams: 'currentPage=1&sortOrders=[{"field":"boxNumber","direction":"asc"}]'}));
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const getBoxOutboundDetailsReport = createAsyncThunk(
    'reports/fetchBoxOutboundDetails',
    async ({queryParams = ""}, {getState, dispatch, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/box/outboundorders/details?${queryParams}`);

            await dispatch(getBoxes({queryParams: 'currentPage=1&sortOrders=[{"field":"boxNumber","direction":"asc"}]'}));
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const getBoxInboundReport = createAsyncThunk(
    'reports/fetchBoxInbound',
    async ({queryParams = ""}, {getState, dispatch, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/box/inboundorders?${queryParams}`);

            await dispatch(getBoxes({queryParams: 'currentPage=1&sortOrders=[{"field":"boxNumber","direction":"asc"}]'}));
            await dispatch(getInboundOrderStatusTypes())
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const getBoxInboundDetailsReport = createAsyncThunk(
    'reports/fetchBoxInboundDetails',
    async ({queryParams = ""}, {getState, dispatch, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/client/account/${idClientAccount}/reports/box/inboundorders/details?${queryParams}`);

            await dispatch(getBoxes({queryParams: 'currentPage=1&sortOrders=[{"field":"boxNumber","direction":"asc"}]'}));
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const listActions = [
    getWarehouseStockReport,
    getWarehouseStockDetailsReport,
    getWarehouseOutboundReport,
    getWarehouseOutboundDetailsReport,
    getBoxStockReport,
    getBoxStockDetailsReport,
    getBoxOutboundReport,
    getBoxOutboundDetailsReport,
    getBoxInboundReport,
    getBoxInboundDetailsReport
];

const isPendingListActions = isPending(...listActions);
const isFulfilledListActions = isFulfilled(...listActions);
const isRejectedListActions = isRejectedWithValue(...listActions);

const isPendingActions = isPending(getWarehouseStockProductReport, getBoxStockProductReport);
const isFulfilledActions = isFulfilled(getWarehouseStockProductReport, getBoxStockProductReport);
const isRejectedActions = isRejectedWithValue(getWarehouseStockProductReport, getBoxStockProductReport);

const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        list: [],
        entity: undefined,
        loading: false,
        listLoading: false
    },
    reducers: {
        resetReportList: state => {
            state.list = [];
        },
        resetReportEntity: state => {
            state.entity = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            // lists matchers
            .addMatcher(isAnyOf(isPendingListActions), (state) => {
                state.listLoading = true
            })
            .addMatcher(isAnyOf(isFulfilledListActions), (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addMatcher(isAnyOf(isRejectedListActions), (state) => {
                state.listLoading = false
            })
            // entity matchers
            .addMatcher(isAnyOf(isPendingActions), (state) => {
                state.loading = true
            })
            .addMatcher(isAnyOf(isFulfilledActions), (state, action) => {
                state.loading = false;
                state.entity = action.payload;
            })
            .addMatcher(isAnyOf(isRejectedActions), (state) => {
                state.loading = false
            })
    }
});

export const {resetReportList, resetReportEntity} = reportsSlice.actions;

export default reportsSlice.reducer;