import React, { useEffect } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import {initUserData} from "../redux/slices/userSlice";

const Auth = (props) => {
	const { initUserData, children } = props;

	useEffect(() => {
		initUserData();
	}, [initUserData]);

	return <>{children}</>;
};

const mapStateToProps = (state) => ({
	setUserData: PropTypes.func.isRequired,
	login: state.login,
});

export default connect(mapStateToProps, { initUserData })(Auth);
