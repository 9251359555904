import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {isEqual} from "lodash";

export const getCategoryProducts = createAsyncThunk(
    "productCategory/fetchCategoryProducts",
    async ({idClient, idClientAccount, idCatalogCategory, queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/categories/${idCatalogCategory}/products?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const postProductCategory = createAsyncThunk(
    "productCategory/create",
    async ({idClient, idClientAccount, idProduct, idCatalogCategory, displayPosition, enabled}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/products/${idProduct}/categories`,{
                idProduct: idProduct,
                idCatalogCategory: idCatalogCategory,
                displayPosition: displayPosition,
                enabled: enabled
            });

            return response.data.productCategory;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putProductCategory = createAsyncThunk(
    "productCategory/update",
    async ({idClient, idClientAccount, idProduct, idProductCategory, idCatalogCategory, displayPosition, enabled}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/clients/${idClient}/accounts/${idClientAccount}/products/${idProduct}/categories/${idProductCategory}`,{
                idProductCategory: idProductCategory,
                idProduct: idProduct,
                idCatalogCategory: idCatalogCategory,
                displayPosition: displayPosition,
                enabled: enabled
            });

            return response.data.productCategory;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const productCategorySlice = createSlice({
    name: "productCategory",
    initialState: {
        selectedProducts: [],
        removedProducts: []
    },
    reducers: {
        setSelectedCategoryProduct: (state, action) => {
            const {product: newProduct, select} = action.payload;
            let products = [...state.selectedProducts];
            const existingProduct = products.find(product => product.idProduct === newProduct.idProduct);

            if (select) {
                if (!existingProduct) {
                    products.push(newProduct);
                } else {
                    existingProduct.enabled = newProduct.enabled;
                    existingProduct.displayPosition = newProduct.displayPosition;
                }
            } else {
                products = products.filter(item => item.sku !== newProduct.sku);
            }

            state.selectedProducts = products;
        },
        resetSelectedCategoryProduct: state => {
            state.selectedProducts = [];
        },
        setRemovedCategoryProduct: (state, action) => {
            const {product, select} = action.payload;
            let products = [...state.removedProducts];

            if (!select) {
                products.push(product);
            } else {
                products = products.filter(item => item.idProduct !== product.idProduct)
            }

            state.removedProducts = products;
        },
        resetRemovedCategoryProduct: state => {
            state.removedProducts = [];
        }
    }
});

export const attachProductToCategory = (idClient, idClientAccount, idProduct, productCategories) => async (dispatch, getState) => {
    const oldCats = getState().products.product?.productCategories.reduce((accumulator, cat) => {
        return {...accumulator, [cat.idCatalogCategory]: {
                displayPosition: cat.displayPosition,
                enabled: cat.enabled,
                idCatalogCategory: cat.idCatalogCategory,
                idProduct: cat.idProduct,
                idProductCategory: cat.idProductCategory
            }}
    }, {});

    const equal = isEqual(oldCats, productCategories)

    if (!equal) {
        for (const idProductCategory in productCategories) {
            const productCategory = productCategories[idProductCategory];
            if (productCategory.idProductCategory) {
                await dispatch(putProductCategory({
                    idClient,
                    idClientAccount,
                    idProduct,
                    idProductCategory: productCategory.idProductCategory,
                    idCatalogCategory: productCategory.idCatalogCategory,
                    displayPosition: productCategory.displayPosition,
                    enabled: productCategory.enabled
                }))
            } else {
                await dispatch(postProductCategory({
                    idClient,
                    idClientAccount,
                    idProduct,
                    idCatalogCategory: productCategory.idCatalogCategory,
                    displayPosition: productCategory.displayPosition,
                    enabled: productCategory.enabled
                }))
            }
        }
    }
}

export const {setSelectedCategoryProduct, resetSelectedCategoryProduct, setRemovedCategoryProduct, resetRemovedCategoryProduct} = productCategorySlice.actions;

export default productCategorySlice.reducer;