import { useEffect } from 'react'
import { useHistory } from "react-router-dom"

// @see https://gist.github.com/Cornally/c7fa0b75d50abd09136abef7a844be90

/**
 * If the user navigates by way of anything but the back button, scroll to the top.
 * Otherwise, retrieve the previous scroll position when back is pressed, if a value
 * has been previously stored.
 * @param {String} key Internal react-router key provided by history listener
 * @param {String} action Event action that triggered a history change
 */
const getOffset = (key, action, storageKey) => {
    if (action === 'PUSH') {
        return { x: 0, y: 0 }
    }

    const keys = JSON.parse(sessionStorage.getItem(storageKey));
    return keys && keys[key] ? keys[key] : { x: 0, y: 0 }
}

/**
 * Store the current scroll offset
 * @param {String} key Internal react-router key provided by history listener
 * @param {Object} value x and y coordinates of current route history entry
 */
const setOffset = (key, value, storageKey) => {
    let keys = JSON.parse(sessionStorage.getItem(storageKey))
    keys = {...keys, ...{[key]: value }}
    sessionStorage.setItem(storageKey, JSON.stringify(keys))
}

/**
 * Include this component alongside your <Router> declaration.
 * @param {Boolean} active Control whether scrolling should be affected for the current application state.
 * @param {String} storageKey Application-unique storage key for persisting scroll offsets in session storage.
 */
const ScrollRestoration = ({ active, storageKey }) => {
    const history = useHistory()

    useEffect(() => {
        history.listen(({ ...args }, action) => {
            // Navigation action not triggered by way of 'forward' or 'back' behavior
            if (action === 'PUSH') {
                setOffset(args.key, { x: window.scrollX, y: window.scrollY }, storageKey)
            }

            // Only modify the scroll if active
            if (active) {
                const { x, y } = getOffset(args.key, action, storageKey)
                window.scrollTo(x, y)
            }
        })
    }, [active, history, storageKey])

    return null
}

ScrollRestoration.defaultProps = {
    active: true
}

export default ScrollRestoration