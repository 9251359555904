import React, {useEffect, useState} from 'react';
import {loginRequest} from "./authConfig";
import {InteractionRequiredAuthError} from "@azure/msal-browser";
import {useMsal} from "@azure/msal-react";
import localStorageService from "../services/localStorageService";
import useSyncSetState from "../hooks/use-sync-set-state";
import "sweetalert2/dist/sweetalert2.min.css";
import {useTranslation} from "react-i18next";

const eventTypes = [
    'keypress',
    'mousemove',
    'mousedown',
    'scroll',
    'touchmove',
    'pointermove'
]
const addEventListeners = (listener) => {
    eventTypes.forEach((type) => {
        window.addEventListener(type, listener, false)
    })
}
const removeEventListeners = (listener) => {
    if (listener) {
        eventTypes.forEach((type) => {
            window.removeEventListener(type, listener, false)
        })
    }
}

const addStorageEventListened = (listener) => {
    window.addEventListener('storage', (event) => {
        if (event.storageArea !== localStorage) return;
        if (event.key === 'lastActive') {
            listener();
        }
    });
}

const removeStorageEventListened = (listener) => {
    if (listener) {
        window.removeEventListener('storage', listener, false)
    }
}

const TIME_IN_MILISECONDS_TO_COUNTDOWN = 60*1000; // 1 minute
const INTERVAL_IN_MILISECONDS = 1000;

const SessionTimeout = () => {
    const {t} = useTranslation();
    const [isWarningModalOpen, setWarningModalOpen] = useSyncSetState('sessionTimeoutWarningModalOpen', false);
    const [referenceTime, setReferenceTime] = useSyncSetState('sessionTimeout', Date.now() + TIME_IN_MILISECONDS_TO_COUNTDOWN);
    const [sessionExpired, setSessionExpired] = useSyncSetState('sessionExpired', false);
    const [time, setTime] = useState(TIME_IN_MILISECONDS_TO_COUNTDOWN);

    useEffect(() => {
        if (isWarningModalOpen) {
            const countDownUntilZero = () => {
                setTime(referenceTime + 1000 - Date.now()); // + 1000 fixes time counter
            }
            setTimeout(countDownUntilZero, INTERVAL_IN_MILISECONDS);
        } else {
            setTime(TIME_IN_MILISECONDS_TO_COUNTDOWN);
        }
    }, [isWarningModalOpen, referenceTime, time]);
    
    const msal = useMsal();
    const {instance} = msal;

    const acquireNewToken = (instance) => {
        const request = {
            ...loginRequest,
            account: instance.getActiveAccount()
        }
        instance.acquireTokenSilent(request)
            .then((response) => {
                // In case the response from B2C server has an empty accessToken field
                // throw an error to initiate token acquisition
                if (!response.idToken || response.idToken === "") {
                    console.log('no token')
                }

                return response;
            })
            .catch(error => {
                console.log("Silent token acquisition fails. Acquiring token using popup. \n", error);
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return instance.loginRedirect(loginRequest)
                        .catch(e => {
                            return;
                        });
                } else {
                    console.log(error);
                }
            })
    }


    const handleLogout = (instance) => {
        setWarningModalOpen(false);
        instance.logoutRedirect().catch((e) => {
            console.error(e);
        });
    }

    if (time <= 0) {
        setWarningModalOpen(false);
        setSessionExpired(true);
    }

    if (sessionExpired && instance.getActiveAccount()) {
        handleLogout(instance);
    } else if (sessionExpired && !instance.getActiveAccount()) {
        window.location.reload();
    }

    useEffect(() => {
        const createTimeout1 = () => setTimeout(() => {
            setReferenceTime(Date.now() + TIME_IN_MILISECONDS_TO_COUNTDOWN);
            setWarningModalOpen(true);
        }, 900000) // show session timeout popup after 15 minutes of inactivity

        // const createTimeout2 = () => setTimeout(() => {
        //     handleLogout(instance)
        // }, 960000) // logout after 16 minutes of inactivity

        const listener = () => {
            if (!isWarningModalOpen) {
                clearTimeout(timeout);
                localStorageService.setItem('lastActive', Date.now());
                timeout = createTimeout1();
            }
        }

        // Initialization
        let timeout = isWarningModalOpen ? undefined : createTimeout1()
        addEventListeners(listener);
        addStorageEventListened(listener)

        // Cleanup
        return () => {
            removeEventListeners(listener);
            removeStorageEventListened(listener)
            clearTimeout(timeout);
        }
    }, [instance, isWarningModalOpen, setReferenceTime, setWarningModalOpen])

    return  <>
        {isWarningModalOpen && (
            <div className="swal2-container swal2-center swal2-backdrop-show" style={{overflowX: 'auto'}}>
                <div className="swal2-popup swal2-modal swal2-icon-warning swal2-show" style={{display: "grid"}}>
                    <div className="swal2-icon swal2-warning swal2-icon-show" style={{display: "flex"}}>
                        <div className="swal2-icon-content">!</div>
                    </div>
                    <h2 className="swal2-title d-block" id="swal2-title">Session Timeout</h2>
                    <div className="swal2-content">
                        <div id="swal2-content" className="swal2-html-container d-block">{t("You're being timed out due to inactivity. Please choose to stay signed in or to sign out. Otherwise, you will be signed out automatically.")}
                        </div>
                    </div>
                    <div className="swal2-actions">
                        <button
                            type="button"
                            className="swal2-confirm swal2-styled d-inline-block"
                            style={{
                                backgroundColor: "rgb(40, 167, 69)",
                                borderLeftColor: "rgb(64, 83, 101)",
                                borderRightColor: "rgb(64, 83, 101)"
                            }}
                            onClick={() => {
                                setWarningModalOpen(false);
                                localStorageService.setItem('lastActive', Date.now());
                                acquireNewToken(instance)
                            }}
                        >{t("Stay Logged in")} ({Math.floor(time / 1000)})
                        </button>
                        <button
                            type="button"
                            className="swal2-cancel swal2-styled"
                            style={{backgroundColor: "rgb(221, 51, 51)"}}
                            onClick={() => {
                                handleLogout(instance);
                            }}
                        >{t("Sign out")}
                        </button>
                    </div>
                </div>
            </div>
        )}
    </>;
};

export default SessionTimeout;
