import { lazy } from "react";
const Dashboard1 = lazy(() => import("./dashboard1/Dashboard1"));

const dashboardRoutes = [
  {
    path: "/dashboard",
    component: Dashboard1,
  },
];

export default dashboardRoutes;
