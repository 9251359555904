import { lazy } from "react";

const Login = lazy(() => import("./Login"));
const Logout = lazy(() => import("./Logout"));

const zendeskRoutes = [
  {
    path: "/zendesk/sso/login",
    component: Login
  },
  {
    path: "/zendesk/sso/logout",
    component: Logout
  }
];

export default zendeskRoutes;
