import { lazy } from "react";

const BoxInventoryList = lazy(() =>
    import("./box-inventory/BoxInventoryList")
);
const StockRegulationList = lazy( () =>
    import("./stock-regulation/StockRegulationList")
);
const AddBoxImage = lazy( () =>
    import("./box-images/AddBoxImage")
);

const EditBoxImage = lazy( () =>
    import("./box-images/EditBoxImage")
);
const BoxImageList = lazy( () =>
    import("./box-images/BoxImageList")
);
const CreateStockRegulation = lazy(()=>
    import('./stock-regulation/CreateStockRegulation')
);
// const EditStockRegulation = lazy(()=>
//     import('./stock-regulation/EditStockRegulation')
// );
const InfoStockRegulation = lazy(()=>
    import('./stock-regulation/InfoStockRegulation')
);


const boxManagementRoutes = [
    {
        path: "/box-management/box-inventory/list",
        component: BoxInventoryList
    },
    {
        path: "/box-management/stock-regulation/list",
        component: StockRegulationList
    },
    {
        path: "/box-management/stock-regulation/create",
        component: CreateStockRegulation
    },
    {
        path: "/box-management/stock-regulation/:idStockRegularizationDocument",
        component: InfoStockRegulation
        // component: EditStockRegulation
    },
    {
        path: "/box-management/box-image/list",
        component: BoxImageList
    },
    {
        path: "/box-management/box-image/add",
        component: AddBoxImage
    },
    {
        path: "/box-management/box-image/:idBoxPicture",
        component: EditBoxImage
    }
];

export default boxManagementRoutes;
