import React from 'react';
import styles from "./SelectApp.module.scss";
import localStorageService from "../../services/localStorageService";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import LazyImageAPI from "../../../@gull/components/LazyImageAPI";
import GullLayout from "../GullLayout";
import {useMsal} from "@azure/msal-react";

const SelectApp = ({route}) => {
    const history = useHistory();
    const {instance} = useMsal();
    const domainHint = instance.getActiveAccount()?.idTokenClaims?.idp
    const user = useSelector(state => state.user);

    const handleOnClick = (app) => {
        localStorageService.setItem('initAppSelect', true);

        const redirectToDashboard = () => history.replace('/dashboard');

        if (app.idApplication === process.env.REACT_APP_IGL_API_ID_APPLICATION) {
            redirectToDashboard();
        } else {
            const { applicationType, url } = app;
            const redirectToUrl = (path) => window.location.replace(path);

            switch (applicationType) {
                case 'API':
                    window.open(url, '_blank');
                    redirectToDashboard();
                    break;
                default:
                    const domainHintParam = domainHint ? `&domain_hint=${domainHint}` : '';
                    redirectToUrl(`${url}?loginHint=${user.email}${domainHintParam}`);
            }
        }
    }

    return (
        user.applications && user.applications.length > 1 && !localStorageService.getItem("initAppSelect") ? (
            <div className={`container d-flex justify-content-center align-items-center py-5 ${styles.containerWrap}`}>
                <div className="row justify-content-center">
                    {user.applications.map((application, index) => {
                        return (
                            <div key={index} className="col-lg-3 col-md-4 col-sm-6 text-center mb-4 mb-sm-0">
                                <div className={`card cursor-pointer ${styles.h100}`}
                                     onClick={() => handleOnClick(application)}>
                                    <div
                                        className={`p-3 d-flex align-items-center position-relative ${styles.imgWrap}`}>
                                        <LazyImageAPI idImage={application.idImage} className="mx-auto img-fluid"/>
                                    </div>

                                    <div className="card-body h-100">
                                        <p className="card-text text-uppercase mb-0">{application.description}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        ) : <GullLayout route={route} />
    );
}

export default SelectApp;
