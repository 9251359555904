import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {updateCartItemsHelper} from "../../views/app/ecommerce/ecommerceUtils";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import limApi from "../../apis/limApi";
import i18n from "i18next";
import {postOutboundOrder} from "./outboundOrdersSlice";
import {postCustomerOrder} from "./customerOrdersSlice";
import {productTypes} from "../../views/app/catalog-admin/products/form-model/formInitialValues";
import {resetCheckout} from "./checkoutSlice";
import {resetCheckoutDocuments} from "./checkoutDocumentsSlice";

export const getCart = createAsyncThunk(
    'cart/fetch',
    async ({idClientAccount, idWarehouse, type, idEvent}, {rejectWithValue}) => {
        try {
            const queryParams = new URLSearchParams({
                ...(type ? {type} : {}),
                ...(idEvent ? {idEvent} : {})
            }).toString()

            const response = await limApi.get(`/clients/accounts/${idClientAccount}/warehouse/${idWarehouse}/cart?${queryParams}`);
            return response.data.cart;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const addCartItem = createAsyncThunk(
    'cart/addItem',
    async ({idCart, item}, {getState, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const idWarehouse = getState().warehouse?.active.idWarehouse;

            const response = await limApi.post(`/clients/accounts/${idClientAccount}/warehouse/${idWarehouse}/cart/${idCart}/items`, item);
            return response.data.item;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const updateCartItem = createAsyncThunk(
    'cart/updateItem',
    async ({idCartItem, item}, {getState, rejectWithValue}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const idWarehouse = getState().warehouse?.active.idWarehouse;

            const response = await limApi.put(`/clients/accounts/${idClientAccount}/warehouse/${idWarehouse}/cart/items/${idCartItem}`, item);
            return response.data.item;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const removeCartItem = createAsyncThunk(
    'cart/removeItem',
    async (idCartItem, {getState, rejectWithValue,  dispatch}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const idWarehouse = getState().warehouse?.active.idWarehouse;

            await limApi.delete(`/clients/accounts/${idClientAccount}/warehouse/${idWarehouse}/cart/items/${idCartItem}`);
            await dispatch(cartSetErrors({idProduct: idCartItem, diff: 1, error: ""}));

            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const cartSearchProduct = createAsyncThunk(
    'cart/searchProduct',
    async (query, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products/search/${query}?types=${productTypes['simple'].value},${productTypes['bundle'].value}`);
            return response.data
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const cartGetProduct = createAsyncThunk(
    'cart/getProduct',
    async (idProduct, {getState, dispatch}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products/${idProduct}`);

            return response.data.product
        } catch (e) {
            dispatch(cartSetErrors({idProduct, diff: -1, error: i18n.t('The requested qty is not available!')}))
        }
    }
);

const initialState = {
    idCart: undefined,
    items: [],
    totalQuantity: 0,
    subtotalPrice: 0,
    productsSuggestions: [],
    suggestionsLoading: false,
    errors: [],
    loading: undefined
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        cartSearchReset: (state) => {
            state.productsSuggestions = [];
        },
        cartCompleteInternal: (state, action) => {
            state.idOrder = action.payload;
        },
        /**
         *
         * @param state
         * @param action
         * payload: {
         *    idProduct,
         *    diff,
         *    error
         * }
         */
        cartSetErrors: (state, action) => {
            const {idProduct, diff} = action.payload;
            const errorIndex = state.errors.findIndex((err) => err.idProduct === idProduct);

            if (diff < 0 && errorIndex === -1) {
                state.errors.push(action.payload);
            } else if (diff >= 0) {
                state.errors = state.errors.filter((err) => err.idProduct !== idProduct);
            }
        },
        resetCart: () => {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCart.pending, state => {
                state.loading = true;
            })
            .addCase(getCart.fulfilled, (state, action) => {
                state.loading = undefined;
                const {
                    idCart,
                    items,
                    totalQuantity,
                    subtotalPrice,
                } = action.payload;

                state.idCart = idCart;
                state.items = items;
                state.totalQuantity = totalQuantity;
                state.subtotalPrice = subtotalPrice;
            })
            .addCase(getCart.rejected, state => {
                state.loading = undefined;
            })
            .addCase(cartSearchProduct.pending, state => {
                state.suggestionsLoading = true;
            })
            .addCase(cartSearchProduct.fulfilled, (state, action) => {
                state.suggestionsLoading = false;
                state.productsSuggestions = action.payload;
            })
            .addCase(cartSearchProduct.rejected, state => {
                state.suggestionsLoading = false;
            })
            .addCase(cartGetProduct.fulfilled, (state, action) => {
                const product = action.payload;
                const index = state.products.findIndex((item) => item.idProduct === product.idProduct);

                if (index !== -1) {
                    // If the object with a matching id exists, update it
                    state.products[index] = product;
                } else {
                    // If it doesn't exist, push it to the array
                    state.products.push(product);
                }
            })
    }
});

export const updateCartItems = (item, quantity) => async (dispatch, getState) => {
    const {cart: {idCart, items}} = getState();
    const {newItems, updateItems} = updateCartItemsHelper(items, item, quantity);

    const newItemsPromises = newItems.map(item => dispatch(addCartItem({idCart, item})));
    const updateItemsPromises = updateItems.map(item => dispatch(updateCartItem({idCartItem: item.idCartItem, item})));

    const settledPromises = await Promise.allSettled([...newItemsPromises, ...updateItemsPromises]);
    const errorPromises = settledPromises.filter(promise =>  promise.value.payload instanceof Error);
    const completePromises = settledPromises.filter(promise => !(promise.value.payload instanceof Error));

    if (errorPromises.length > 0) {
        return Promise.reject(errorPromises[0].value.payload)
    }

    return Promise.resolve(completePromises.map(promise => promise.value.payload))
}

export const emptyCart = () => (dispatch) => {
    dispatch(resetCart());
    dispatch(resetCheckout());
    dispatch(resetCheckoutDocuments());
}

export const cartComplete = (cart, data) => async (dispatch, getState) => {
    const idClientAccount = selectUserIdClientAccount(getState());

    let error;

    switch (getState().warehouse.active.warehouseType) {
        case "BOX":
            await dispatch(postOutboundOrder({
                user: getState().user,
                shipping: data,
                orderItems: cart.items
            }))
                .unwrap()
                .catch(async e => {
                    error = { code: e.code, message: e.message }
                })
            break;
        default:
            await dispatch(postCustomerOrder({
                user: getState().user,
                idClientAccount: idClientAccount,
                shipping: {
                    ...data,
                    shipToMultiple: cart.checkout.shipToMultiple,
                    multipleAddressBookAddresses: cart.checkout.multipleAddressBookAddresses,
                    shippingMethod: cart.checkout.shippingMethod,
                    addresses: cart.checkout.addresses
                },
                orderItems: cart.items,
                orderSubtotalPrice: cart.subtotalPrice
            }))
                .unwrap()
                .catch(async e => {
                    error = { code: e.code, message: e?.response?.data || e.message };
                })
    }

    if (!error) {
        dispatch(cartCompleteInternal(getState().customerOrders?.order?.idCustomerOrder || getState().outboundOrders?.order?.idOutboundOrder))
    } else {
        throw Error(error.message);
    }
}

export const {
    cartSearchReset,
    cartCompleteInternal,
    cartSetErrors,
    resetCart,
} = cartSlice.actions;

export default cartSlice.reducer;
