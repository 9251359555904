import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getProductEventStockReservations = createAsyncThunk(
    "eventStockReservations/productEventStockReservationsForPeriod",
    async ({idProduct, fromDate, toDate}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/products/${idProduct}/reservations/fromdate/${fromDate}/todate/${toDate}`);
            return response.data.reservations;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getProductEventDateStock = createAsyncThunk(
    "eventStockReservations/productEventDateStock",
    async ({idProduct, date}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/products/${idProduct}/reservations/date/${date}/stocks`);
            return response.data.reservation;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const eventStockReservationsSlice = createSlice({
    name: "eventStockReservations",
    initialState: {
        list: [],
        listLoading: undefined,
        dateStock: undefined,
        loading: undefined
    },
    extraReducers: builder => {
        builder
            .addCase(getProductEventStockReservations.fulfilled, (state, action) => {
                state.list = action.payload;
            })
            .addCase(getProductEventDateStock.fulfilled, (state, action) => {
                state.dateStock = action.payload;
            })
    }
});

export default eventStockReservationsSlice.reducer;