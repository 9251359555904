import React from "react";
import { ErrorMessage, Field } from "formik";
import TextError from "../TextError";

const Select = (props) => {
    const {label, name, options, groupClass, className, touched, errors, children, required, ...rest} = props;
    const classes = `form-group ${groupClass ? groupClass : ""}`;
    const fieldClasses = `select custom-select ${className ?? ''}${touched && errors ? " is-invalid" : ""}`;

    return (
        <div className={classes}>
            { label && <label htmlFor={name}>{label} { required && <span className="text-danger">*</span> }</label>}
            <Field as="select" id={name} name={name} className={fieldClasses} {...rest}>
                {options.map((option) => {
                    return (
                        <option key={option.value} value={option.value} style={option.style}>
                            {option.label}
                        </option>
                    );
                })}
            </Field>
            <ErrorMessage component={TextError} name={name} />
            {children}
        </div>
    );
};

export default Select;