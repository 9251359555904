import React from "react";
import Input from "./fields/Input";
import Select from "./fields/Select";
import Textarea from "./fields/Textarea";
import RadioButtons from "./fields/RadioButtons";
import CheckboxGroup from "./fields/CheckboxGroup";
import CheckboxSingle from "./fields/CheckboxSingle";
import Email from "./fields/Email";
import Phone from "./fields/Phone";
import AddressGroup from "./fields/AddressGroup";
import SwitchButton from "./fields/SwitchButton";
import DateField from './fields/DateField';
import FileField from "./fields/FileField";
import FileField3 from "./fields/FileField3";
import InputAppend from "./fields/InputAppend";
import ColorPicker from "./fields/ColorPicker";

const FormikControl = (props) => {
    const { control, ...rest } = props;
    switch (control) {
        case "input":
            return <Input {...rest} />;
        case "inputAppend":
            return <InputAppend {...rest} />;
        case 'textarea':
            return <Textarea {...rest} />
        case "select":
            return <Select {...rest} />;
        case 'radio':
            return <RadioButtons {...rest} />
        case 'checkbox':
            return <CheckboxSingle {...rest} />
        case 'checkboxes':
            return <CheckboxGroup {...rest} />
        case 'email':
            return <Email {...rest} />
        case 'phone':
            return <Phone {...rest} />
        case 'addressGroup':
            return <AddressGroup {...rest} />
        // case 'date':
        //     return <DatePicker {...rest} />
        case 'switch':
            return <SwitchButton {...rest} />
        case 'date':
            return <DateField {...rest} />
        case 'file':
            return <FileField {...rest} />
        case 'file3':
            return <FileField3 {...rest} />
        case 'colorPicker':
            return <ColorPicker {...rest} />
        default:
            return null;
    }
};

export default FormikControl;
