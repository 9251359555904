import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setActiveWarehouse} from "../../redux/slices/warehouseSlice";
import {isEmpty} from "lodash";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {ConfirmNotification} from "../../notifications/Notifications";
import {useTranslation} from "react-i18next";
import AdminCustomizer from "./AdminCustomizer";
import {Button} from "react-bootstrap";
import {setAdminCustomizerOpen} from "../../redux/slices/adminCustomizerSlice";
import history from "../../../@history";
import {SYSTEM_ADMIN_ROLE} from "../../apis/limApi";

const SelectSkeleton = () => (
    <SkeletonTheme borderRadius="3px">
        <div className="nav-item-hold">
            <Skeleton count={1} height={33}/>
        </div>
    </SkeletonTheme>
);

const WarehouseSwitcher = ({label}) => {
    const dispatch = useDispatch();
    const warehouses = useSelector((state) => state.warehouse.list);
    const activeWH = useSelector((state) => state.warehouse.active);
    const accountPermissions = useSelector((state) => state.user.accountPermissions);
    const adminCustomizerEnabled = useMemo(() => (
        accountPermissions && (accountPermissions.length > 1 || (accountPermissions[0] && accountPermissions[0].idClientAccount === SYSTEM_ADMIN_ROLE))
    ), [accountPermissions]);
    const {t} = useTranslation();

    const warehouseSwitchHandler = (e) => {
        const index = e.nativeEvent.target.selectedIndex;
        const value = e.target.value;
        const title = t('Please confirm source switching to {{name}}!', {name: e.nativeEvent.target[index].text})
        const confirmButtonText = t('Yes')
        ConfirmNotification(title, null, confirmButtonText)
            .then(result => {
                if (result.isConfirmed) {
                    dispatch(setActiveWarehouse(value))
                    history.replace('/');
                }
            });
    };

    return (
        <>
            {isEmpty(warehouses) && <SelectSkeleton/>}
            {!isEmpty(warehouses) && activeWH && (
                <div className="input-group">
                    { label && <label htmlFor="warehouseSwitch">{label}</label>}
                    <select
                        id="warehouseSwitch"
                        name="warehouseSwitch"
                        className="custom-select pr-4"
                        value={activeWH?.idWarehouse}
                        onChange={(e) => warehouseSwitchHandler(e)}
                        disabled={adminCustomizerEnabled}
                    >
                        {warehouses.map((warehouse) => {
                            return (
                                <option key={warehouse.idWarehouse} value={warehouse.idWarehouse}>
                                    {warehouse.warehouseName}
                                </option>
                            );
                        })}
                    </select>
                    {adminCustomizerEnabled && (
                        <div className="input-group-append">
                            <Button className="text-18" onClick={() => dispatch(setAdminCustomizerOpen(true))}><i className="i-Gear"/></Button>
                        </div>
                    )}
                </div>
            )}
            {adminCustomizerEnabled && (
                <AdminCustomizer
                    warehouses={warehouses}
                    activeWH={activeWH}
                />
            )}
        </>
    );
};

export default WarehouseSwitcher;
