import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {getDocument} from "./documentSlice";

export const getCustomerOrderDocuments = createAsyncThunk(
    'customerOrderDocuments/fetchAll',
    async (idCustomerOrder, {rejectWithValue, dispatch}) => {
        try {
            const documents = await limApi.get(`/customerorders/${idCustomerOrder}/documents`).then(response => response.data.documents);

            if (documents.length) {
                for (const document of documents) {
                    await dispatch(getDocument(document.idDocument));
                }
            }

            return documents;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postCustomerOrderDocument = createAsyncThunk(
    'customerOrderDocuments/create',
    async ({idCustomerOrder, documentBase64, values}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/customerorders/${idCustomerOrder}/documents`,{
                idCustomerOrder,
                document: documentBase64,
                documentDescription: values.fileTitle,
                documentName: values.name,
                documentFileName: values.name,
                documentSize: parseInt(values.size),
                displayPosition: values.displayPosition,
                fileType: values.ext
            });

            return response.data.document;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putCustomerOrderDocument = createAsyncThunk(
    'customerOrderDocuments/update',
    async ({idCustomerOrder, idCustomerOrderDocument, documentBase64, values}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/customerorders/${idCustomerOrder}/documents/${idCustomerOrderDocument}`,{
                idCustomerOrder,
                idCustomerOrderDocument,
                idDocument: values.fileKey,
                document: documentBase64,
                documentDescription: values.fileTitle,
                documentName: values.name,
                documentFileName: values.name,
                documentSize: parseInt(values.size),
                displayPosition: values.displayPosition,
                fileType: values.ext
            });

            return response.data.document;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteCustomerOrderDocument = createAsyncThunk(
    "customerOrderDocuments/delete",
    async ({idCustomerOrder, idCustomerOrderDocument}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/customerorders/${idCustomerOrder}/documents/${idCustomerOrderDocument}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const customerOrderDocumentsSlice = createSlice({
    name: "customerOrderDocuments",
    initialState: {
        list: [],
        listLoading: undefined
    },
    reducers: {
        resetCustomerOrderDocuments: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCustomerOrderDocuments.pending, state => {
                state.listLoading = true;
            })
            .addCase(getCustomerOrderDocuments.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getCustomerOrderDocuments.rejected, state => {
                state.listLoading = undefined;
            })
    }
});

export const updateCustomerOrderDocuments = (idCustomerOrder, documents) => async (dispatch, getState) => {
    const orderDocuments = getState().customerOrderDocuments.list;

    const newDocuments = documents.filter(doc => doc.isNew);
    const existingDocuments = documents.filter(doc => !doc.isNew && orderDocuments.length > 0);

    const newDocumentPromises = newDocuments.map(doc => {
        return dispatch(postCustomerOrderDocument({
            idCustomerOrder,
            documentBase64: doc.base64.split(',')[1],
            values: doc
        }))
    });

    const existingDocumentPromise = existingDocuments.map(doc => {
        const documentDetails = orderDocuments.find(item => item.idDocument === doc.fileKey);
        if (documentDetails &&
            (
                documentDetails.displayPosition !== doc.displayPosition ||
                documentDetails.documentName !== doc.fileTitle ||
                documentDetails.documentFileName !== doc.name
            )
        ) {
            return dispatch(putCustomerOrderDocument({
                idCustomerOrder,
                idCustomerOrderDocument: documentDetails.idCustomerOrderDocument,
                documentBase64: doc.base64.split(',')[1],
                values: doc
            }))
        }
    });

    const orderDocumentKeys = orderDocuments.map(value => value.idDocument);
    const fieldDocumentKeys = documents.map(value => value.fileKey);
    const deletedDocuments = orderDocumentKeys.filter(x => !fieldDocumentKeys.includes(x));

    const deletePromises = deletedDocuments.map(idDocument => {
        const deletedDocument = orderDocuments.find(item => item.idDocument === idDocument);
        if (deletedDocument) {
            return dispatch(deleteCustomerOrderDocument({idCustomerOrder, idCustomerOrderDocument: deletedDocument.idCustomerOrderDocument}))
        }
    });

    const allPromises = [...newDocumentPromises, ...existingDocumentPromise, ...deletePromises].filter(prom => prom);

    if (allPromises.length) {
        await Promise.all(allPromises);
        await dispatch(getCustomerOrderDocuments(idCustomerOrder));
    }
}

export const {resetCustomerOrderDocuments} = customerOrderDocumentsSlice.actions;

export default customerOrderDocumentsSlice.reducer;