import React from 'react';

const styles = {
    textAlign: "center",
    height: "120px",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
};

const LoadingInner = () => {
    return (
        <div style={styles}>
            <div className="spinner spinner-light"></div>
        </div>
    );
};

export default LoadingInner;
