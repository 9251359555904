import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const postClientAccountUsers = createAsyncThunk(
    'clientAccountUser/create',
    async ({idClient, idClientAccount, clientAccount}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/users`, clientAccount);
            return response.data.clientAccountUser;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteClientAccountUser = createAsyncThunk(
    'clientAccountUser/delete',
    async ({idClient, idClientAccount, idUser}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/users/${idUser}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const clientAccountUserSlice = createSlice({
    name: 'clientAccountUser',
    initialState: {}
});

export default clientAccountUserSlice.reducer;