import React from 'react';
import {ErrorMessage, Field} from "formik";
import TextError from "../TextError";

const CheckboxSingle = (props) => {
    const {label, name, groupClass, ...rest} = props;
    const classes = `form-group ${groupClass ? groupClass : ""}`;

    return (
        <div className={classes}>
            <div className={`d-inline-flex`}>
                <Field name={name}>
                    {({field}) => {
                        return (
                            <label
                                className={`checkbox checkbox-primary`}>
                                <input
                                    type="checkbox"
                                    id={name}
                                    {...field}
                                    {...rest}
                                />
                                <span>{label}</span>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }}
                </Field>
            </div>
            <ErrorMessage component={TextError} name={name}/>
        </div>
    )
};

export default CheckboxSingle;
