import React from "react";
import {Card} from "react-bootstrap";
import LoadingInner from "../GullLoadable/LoadingInner";

const SimpleCard = ({title, children, className, subtitle, loading}) => {
    return (
        <Card className={className}>
            <Card.Body>
                {(title || subtitle) && (
                    <div className="simple-card-title">
                        {title && <h3 className="text-capitalize mb-1">{title}</h3>}
                        {subtitle && <h6 className="text-mute">{subtitle}</h6>}
                    </div>
                )}
                {loading && <LoadingInner/>}
                {!loading && children}
            </Card.Body>
        </Card>
    );
};

export default SimpleCard;
