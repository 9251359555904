import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTheme} from "../../app/hooks/use-theme";
import {withTranslation} from "react-i18next";
import WebWidget from "../../app/zendesk/WebWidget";
import {hardReloadApp} from "../../@utils";
import HeadTitle from "../../app/GullLayout/SharedComponents/HeadTitle";

class ErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    // static getDerivedStateFromError(error) {
    //     return { error: error };
    // }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        const {t} = this.props

        if (this.state.error || this.props.error) {
            return (
                <>
                    <HeadTitle title={t("Ops...") + " " + t("Something happened") + "!"}/>
                    <div className={`not-found-wrap text-center ${this.props.themeName}`}>
                        <img src="/assets/images/Logo_Linkeepers_big.png" alt="logo" className="max-width-200 pb-5"/>
                        <h1 className="text-60 text-dark">{t('Ops...')}</h1>
                        <p className="text-36 subheading mb-3 text-dark">{t("Something happened")}!</p>
                        <p className="mb-5 text-18 text-dark">
                            {t("Sorry! Please refresh the page!")}
                        </p>
                        <button className="btn btn-lg btn-primary btn-rounded" onClick={hardReloadApp}>{t("Refresh")}!</button>
                        {process.env.NODE_ENV === "development" && (
                            <div className="col-12 col-md-8 offset-md-2" style={{whiteSpace: "pre-wrap"}}>
                                <div className="d-flex align-items-center mt-3">
                                    <div className="mx-auto alert alert-danger"
                                         role="alert">
                                        <h2 className="alert-heading">{t("Error occurred!")}</h2>
                                        <p className="text-16 mb-0"
                                           dangerouslySetInnerHTML={{
                                               __html: this.state.error ? this.state.error + "<br/>" + this.state.errorInfo.componentStack : this.props.error.message || ""
                                           }}/>
                                    </div>
                                </div>
                            </div>
                        )}
                        <WebWidget/>
                    </div>
                </>
            )
        }
        return this.props.children;
    }
}

const mapStateToProps = function (state) {
    return {
        error: state.global.appGlobalError
    }
}
export default withTranslation()(connect(mapStateToProps, null)(withTheme(ErrorBoundary)));