import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const postClientAccountUserRole = createAsyncThunk(
    'clientAccountUserRole/create',
    async ({idClient, idClientAccount, idUser, role}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/users/${idUser}/roles`, role);
            return response.data.clientAccountUserRole;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteClientAccountUserRole = createAsyncThunk(
    'clientAccountUserRole/delete',
    async ({idClient, idClientAccount, idUser, idApplicationRole}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/users/${idUser}/roles/${idApplicationRole}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const clientAccountUserRoleSlice = createSlice({
    name: 'clientAccountUserRole',
    initialState: {}
});

export default clientAccountUserRoleSlice.reducer;