import {lazy} from "react";

const CustomReports = lazy(() => import("./custom-reports/CustomReports"));
const WarehouseStock = lazy(() => import("./warehouse/Stock"));
const WarehouseStockDetails = lazy(() => import("./warehouse/StockDetails"));
// const WarehouseInboundOrders = lazy(() => import("./warehouse/InboundOrders"));
const WarehouseOutboundOrders = lazy(() => import("./warehouse/OutboundOrders"));
const WarehouseOutboundOrdersDetails = lazy(() => import("./warehouse/OutboundOrdersDetails"));
const BoxStock = lazy(() => import("./box/Stock"));
const BoxStockDetails = lazy(() => import("./box/StockDetails"));
const BoxInboundOrders = lazy(() => import("./box/InboundOrders"));
const BoxInboundOrdersDetails = lazy(() => import("./box/InboundOrdersDetails"));
const BoxOutboundOrders = lazy(() => import("./box/OutboundOrders"));
const BoxOutboundOrdersDetails = lazy(() => import("./box/OutboundOrdersDetails"));

const reportsRoutes = [
    {
        path: "/reports/custom-report/:idCustomReport",
        component: CustomReports,
        key: "View_ReportsReportsCustomReports",
    },
    {
        path: "/reports/warehouse/stock",
        component: WarehouseStock,
        key: "View_ReportsReportsWarehouseReportsStock"
    },
    {
        path: "/reports/warehouse/stock-details",
        component: WarehouseStockDetails,
        key: "View_ReportsReportsWarehouseReportsStockDetails"
    },
    // {
    //     path: "/reports/warehouse/inbound-orders",
    //     component: WarehouseInboundOrders
    // },
    {
        path: "/reports/warehouse/outbound-orders",
        component: WarehouseOutboundOrders,
        key: "View_ReportsReportsWarehouseReportsOutboundOrders"
    },
    {
        path: "/reports/warehouse/outbound-orders-details",
        component: WarehouseOutboundOrdersDetails,
        key: "View_ReportsReportsWarehouseReportsOutboundOrdersDetails"
    },
    {
        path: "/reports/box/stock",
        component: BoxStock,
        key: "View_BoxReportsBoxReportsStock"
    },
    {
        path: "/reports/box/stock-details",
        component: BoxStockDetails,
        key: "View_BoxReportsBoxReportsStockDetails"
    },
    {
        path: "/reports/box/inbound-orders",
        component: BoxInboundOrders,
        key: "View_BoxReportsBoxReportsInboundOrders"
    },
    {
        path: "/reports/box/inbound-orders-details",
        component: BoxInboundOrdersDetails,
        key: "View_BoxReportsBoxReportsInboundOrdersDetails"
    },
    {
        path: "/reports/box/outbound-orders",
        component: BoxOutboundOrders,
        key: "View_BoxReportsBoxReportsOutboundOrders"
    },
    {
        path: "/reports/box/outbound-orders-details",
        component: BoxOutboundOrdersDetails,
        key: "View_BoxReportsBoxReportsOutboundOrdersDetails"
    },
];

export default reportsRoutes;
