import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";
import {setEcommerceSearchProductOffset, setEcommerceSearchEndOffset} from "../../app/redux/slices/ecommerceSlice";
import {connect} from "react-redux";

const Pagination = ({searchList, setEcommerceSearchProductOffset, setEcommerceSearchEndOffset}) => {

    const [totalSize, setTotalSize] = useState(0);
    const [productOffset, setProductOffset] = useState(0);
    const [itemsPerPage] = useState(5)
    const currentPage = Math.round(productOffset / itemsPerPage);
    const {t} = useTranslation()

    useEffect(() => {
        const endOffset = productOffset + parseInt(itemsPerPage);
        setEcommerceSearchProductOffset(productOffset)
        setEcommerceSearchEndOffset(endOffset)
        setTotalSize(Math.ceil(searchList.length / parseInt(itemsPerPage)));

        return () => {
            setEcommerceSearchProductOffset(undefined);
            setEcommerceSearchEndOffset(undefined);
        }
    }, [itemsPerPage, productOffset, searchList, setEcommerceSearchEndOffset, setEcommerceSearchProductOffset]);
    
    useEffect(() => {
        // this return pagination to first page
        setProductOffset(0)
    }, [searchList.length]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % searchList.length;
        setProductOffset(newOffset);
    };

    if (searchList.length <= itemsPerPage) {
        return null;
    }

    return (
        <ReactPaginate
            previousLabel={t("Previous")}
            nextLabel={t("Next")}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalSize}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination pagination-lg"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            renderOnZeroPageCount={null}
            forcePage={currentPage}
        />
    )
}

export default connect(null,
    {setEcommerceSearchProductOffset, setEcommerceSearchEndOffset})(Pagination);