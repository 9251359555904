import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from '../TextError'

const RadioButtons = (props) => {
    const {label, name, options, groupClass, className, inline, touched, errors, required, ...rest} = props;
    const classes = `form-group ${groupClass && groupClass}`;
    // const fieldClasses = `${className ? className : ''}`;

    return (
        <div className={classes}>
            { label && <label htmlFor={name}>{label} { required && <span className="text-danger">*</span> }</label>}
            <div className={`ul-form__radio${inline ? "-inline" : ""}`}>
                <Field name={name}>
                    {({ field }) => {
                        return options.map((option) => {
                            return (
                                <label
                                    key={option.value}
                                    className={`ul-radio__position radio radio-primary ${className} ${inline ? "form-check-inline" : ""}`}>
                                    <input
                                        type="radio"
                                        id={option.value}
                                        {...field}
                                        {...rest}
                                        value={option.value}
                                        checked={field.value === option.value}
                                    />
                                    <span className="ul-form__radio-font">
                                        {option.label}
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                            );
                        });
                    }}
                </Field>

            </div>
            <ErrorMessage component={TextError} name={name}/>
        </div>
    );
}

export default RadioButtons