import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getQuotaPersonal = createAsyncThunk(
    'quotaPersonal/fetchOne',
    async ({idClientAccount, idQuota}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/client/account/${idClientAccount}/quota/${idQuota}/personal`);
            return response.data.quotaPersonals;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postQuotaPersonal = createAsyncThunk(
    'quotaPersonal/create',
    async ({idClientAccount, idQuota, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/client/account/${idClientAccount}/quota/${idQuota}/personal`, {
                idQuota,
                ...data
            });
            return response.data.quotaPersonals;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putQuotaPersonal = createAsyncThunk(
    'quotaPersonal/update',
    async ({idClientAccount, idQuota, idQuotaPersonal, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/client/account/${idClientAccount}/quota/${idQuota}/personal/${idQuotaPersonal}`, data);
            return response.data.quotaPersonals;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const quotaPersonalSlice = createSlice({
    name: 'quotaPersonal',
    initialState: {}
});

export default quotaPersonalSlice.reducer;