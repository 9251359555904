import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {isEqual} from "lodash";

export const getProductAttributes = createAsyncThunk(
    "productAttributes/fetchAll",
    async (idProduct, {rejectWithValue, getState}) => {
        try {
            const response = await limApi.get(`/product/${idProduct}/attributes`);

            return response.data.productAttributes;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getProductAttribute = createAsyncThunk(
    "productAttributes/fetchOne",
    async ({idProduct, idProductAttribute}, {rejectWithValue, getState}) => {
        try {
            const response = await limApi.get(`/product/${idProduct}/attributes/${idProductAttribute}`);

            return response.data.productAttribute;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postProductAttribute = createAsyncThunk(
    "productAttributes/create",
    async ({idProduct, data}, {rejectWithValue, getState}) => {
        try {
            const response = await limApi.post(`/product/${idProduct}/attributes`, data);

            return response.data.productAttribute;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProductAttribute = createAsyncThunk(
    "productAttributes/delete",
    async ({idProduct, idProductAttribute}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/product/${idProduct}/attributes/${idProductAttribute}`);

            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const productAttributesSlice = createSlice({
    name: "productAttributes",
    initialState: {
        list: [],
        listLoading: undefined,
        attribute: undefined,
        loading: undefined
    },
    reducers: {
        resetProductAttributes: state => {
            state.list = [];
        },
        resetProductAttribute: state => {
            state.attribute = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProductAttributes.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getProductAttributes.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getProductAttributes.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getProductAttribute.pending, state => {
                state.loading = true;
            })
            .addCase(getProductAttribute.fulfilled, (state, action) => {
                state.loading = undefined;
                state.attribute = action.payload;
            })
            .addCase(getProductAttribute.rejected, state => {
                state.loading = undefined;
            })
    }
});

export const updateProductAttributes = (idProduct, productAttributes) => async (dispatch, getState) => {
    const oldProductAttributes = getState().productAttributes.list.map(productAttribute => productAttribute.idAttribute);

    const equal = isEqual(oldProductAttributes, productAttributes);

    if (!equal) {
        // DELETE/REMOVE
        const deleteAttributes = oldProductAttributes.filter((old) => {
            const exist = productAttributes.includes(old);
            if (!exist) {
                return old;
            }
            return null;
        });

        for (const idAttribute of deleteAttributes) {
            await dispatch(deleteProductAttribute({
                idProduct,
                idProductAttribute: getState().productAttributes.list.find(productAttribute => productAttribute.idAttribute === idAttribute).idProductAttribute
            }))
        }
        // DELETE/REMOVE

        // ADD
        const addAttributes = productAttributes.filter((idAttribute) => {
            const exist = oldProductAttributes.includes(idAttribute)
            if (!exist) {
                return idAttribute;
            }
            return null;
        });

        for (const idAttribute of addAttributes) {
            await dispatch(postProductAttribute({
                idProduct,
                data: {
                    idProduct,
                    idAttribute
                }
            }))
        }
        // ADD
    }
}

export const {resetProductAttributes, resetProductAttribute} = productAttributesSlice.actions;

export default productAttributesSlice.reducer;