import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getAddressTypes = createAsyncThunk(
    'addressTypes/fetchAll',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/addresstypes');
            return response.data.addressTypes;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const addressTypesSlice = createSlice({
    name: 'addressTypes',
    initialState: {
        list: [],
        listLoading: false
    },
    reducers: {
        resetAddressTypesList: state => {
            state.list = []
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAddressTypes.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getAddressTypes.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload.sort((a, b) => a.displayPosition - b.displayPosition);
            })
            .addCase(getAddressTypes.rejected, state => {
                state.listLoading = false;
            })
    }
});

export const {resetAddressTypesList} = addressTypesSlice.actions;

export default addressTypesSlice.reducer;