import { lazy } from "react";

const CatalogAdminList = lazy(()=> import("./catalog/CatalogAdminList"))
const CreateCatalog = lazy( () => import("./catalog/CreateCatalog"))
const EditCatalog = lazy(() => import("./catalog/EditCatalog"));
const ProductsLists = lazy(() => import("./products/ProductsList"));
const CreateProduct = lazy( ()=> import('./products/CreateProduct') );
const EditProduct = lazy(()=> import('./products/EditProduct'));
const ProductsBulkImport = lazy(()=> import('./products/bulk-import/ProductsBulkImport'));
const ProductsBulkImportWizard = lazy(()=> import('./products/bulk-import-wizard/ProductsBulkImportWizard'));
const QuotaList = lazy( () => import("./quota/QuotaList"))
const CreateQuota = lazy( () => import("./quota/CreateQuota"))
const EditQuota = lazy( () => import("./quota/EditQuota"))
const ProductAttributesList = lazy( () => import("./product-attributes/ProductAttributesList"))
const CreateProductAttribute = lazy( () => import("./product-attributes/CreateProductAttribute"))
const EditProductAttribute = lazy( () => import("./product-attributes/EditProductAttribute"))
const KitList = lazy( () => import("./kits/KitList"))
const CreateKit = lazy( () => import("./kits/CreateKit"))
const EditKit = lazy( () => import("./kits/EditKit"))

const clientAdminRoutes = [
    {
        path: "/admin/catalogs",
        component: CatalogAdminList,
        key: "View_CatalogAdminCatalogAdministrationCatalog"
    },
    {
        path: "/admin/create-catalog",
        component: CreateCatalog,
        key: "Create_CatalogAdminCatalogAdministrationCatalog"
    },
    {
        path: "/admin/edit-catalog/clients/:idClient/accounts/:idClientAccount/catalogs/:idCatalog",
        component: EditCatalog,
        key: 'Edit_CatalogAdminCatalogAdministrationCatalog'
    },
    {
        path: "/admin/products/list",
        component: ProductsLists,
        key: "View_CatalogAdminCatalogAdministrationProducts"
    },
    {
        path: "/admin/products/create",
        component: CreateProduct,
        key: "Create_CatalogAdminCatalogAdministrationProducts"
    },
    {
        path: "/admin/products/:idProduct([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
        component: EditProduct,
        key: "Edit_CatalogAdminCatalogAdministrationProducts"
    },
    {
        path: "/admin/products/bulk-import",
        component: ProductsBulkImport,
        key: "BulkImport_CatalogAdminCatalogAdministrationProducts"
    },
    {
        path: "/admin/products/bulk-import-wizard",
        component: ProductsBulkImportWizard,
        key: "View_CatalogAdminCatalogAdministrationProductsImportWizard"
    },
    {
        path: "/admin/quota/list",
        component: QuotaList,
        exact: true,
        key: "View_QuotasAdminCatalogAdministrationQuotas"
    },
    {
        path: "/admin/quota/create",
        component: CreateQuota,
        exact: true,
        key: "Create_QuotasAdminCatalogAdministrationQuotas"
    },
    {
        path: "/admin/quota/:idQuota/account/:idClientAccount",
        component: EditQuota,
        exact: true,
        key: "Edit_QuotasAdminCatalogAdministrationQuotas"
    },
    {
        path: "/admin/product/attributes/list",
        component: ProductAttributesList,
        exact: true,
        key: "View_CatalogAdminCatalogAdministrationProductAttributes"
    },
    {
        path: "/admin/product/attributes/create",
        component: CreateProductAttribute,
        exact: true,
        key: "Create_CatalogAdminCatalogAdministrationProductAttributes"
    },
    {
        path: "/admin/product/attributes/:idAttribute",
        component: EditProductAttribute,
        exact: true,
        key: "Edit_CatalogAdminCatalogAdministrationProductAttributes"
    },
    {
        path: "/admin/kit/list",
        component: KitList,
        exact: true,
        key: "View_CatalogAdminCatalogAdministrationKits"
    },
    {
        path: "/admin/kit/create",
        component: CreateKit,
        exact: true,
        key: "Create_CatalogAdminCatalogAdministrationKits"
    },
    {
        path: "/admin/kit/:idKit",
        component: EditKit,
        exact: true,
        key: "Edit_CatalogAdminCatalogAdministrationKits"
    }
];



export default clientAdminRoutes;
