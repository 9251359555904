import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {includes, isEqual} from "lodash";
import {deleteShippingMethodGroup, getShippingMethodGroups, postShippingMethodGroup} from "./shippingMethodGroupSlice";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import {getGroups} from "./groupsSlice";

export const getCustomerOrdersShippingMethods = createAsyncThunk(
    'shippingMethod/fetchAllCustomerOrdersShippingMethods',
    async (_, {rejectWithValue, getState}) => {
        try {
            let url = '/customerorders/shippingmethods';
            const idClientAccount = selectUserIdClientAccount(getState());

            if (idClientAccount) {
                url = url + '?IdClientAccount=' + idClientAccount;
            }

            const response = await limApi.get(url);
            return response.data.shippingMethods;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getShippingMethods = createAsyncThunk(
    'shippingMethod/fetchAll',
    async (_, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get( `/clients/${idClient}/accounts/${idClientAccount}/shippingmethods?pageSize=1000`)
            return response.data.shippingMethods;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getShippingMethod = createAsyncThunk(
    'shippingMethod/fetchOne',
    async (idShippingMethod, {rejectWithValue, getState, dispatch}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/shippingmethods/${idShippingMethod}`);
            await dispatch(getShippingMethodGroups({idClient, idClientAccount, idShippingMethod}))
            await dispatch(getGroups({idClient, idClientAccount}));

            return response.data.shippingMethod;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postShippingMethod = createAsyncThunk(
    'shippingMethod/create',
    async ({idClient, idClientAccount, data}, {rejectWithValue, dispatch,}) => {
        try {
            const shippingMethod = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/shippingmethods`, data)
                .then(async response => await response.data.shippingMethod);

            for (const idClientAccountGroup of data.shippingMethodGroups) {
                await dispatch(postShippingMethodGroup({
                    idClient,
                    idClientAccount,
                    idShippingMethod: shippingMethod.idShippingMethod,
                    data: {
                        idShippingMethod: shippingMethod.idShippingMethod,
                        idClientAccountGroup
                    }
                }));
            }

            return shippingMethod;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putShippingMethod = createAsyncThunk(
    'shippingMethod/update',
    async ({idClient, idClientAccount, idShippingMethod, data}, {rejectWithValue, getState, dispatch,}) => {
        try {
            const shippingMethod = await limApi.put(`/clients/${idClient}/accounts/${idClientAccount}/shippingmethods/${idShippingMethod}`, data)
                .then(async response => await response.data.shippingMethod);

            const oldGroups = getState().shippingMethodGroup.list.map((group) => group.idClientAccountGroup);
            const equal = isEqual(oldGroups, data.shippingMethodGroups);

            if (!equal) {
                // DELETE/REMOVE ShippingMethodGroups
                const deleteGroups = oldGroups.filter((old) => {
                    const exist = includes(data.shippingMethodGroups, old);
                    if (!exist) {
                        return old;
                    }
                    return null;
                });

                for (const idClientAccountGroup of deleteGroups) {
                    await dispatch(deleteShippingMethodGroup({
                        idClient,
                        idClientAccount,
                        idShippingMethod,
                        idClientAccountGroup
                    }));
                }
                // DELETE/REMOVE ShippingMethodGroups

                // ADD ShippingMethodGroups
                const addGroups = data.shippingMethodGroups.filter((idClientAccountGroup) => {
                    const exist = includes(oldGroups, idClientAccountGroup)
                    if (!exist) {
                        return idClientAccountGroup;
                    }
                    return null;
                });

                for (const idClientAccountGroup of addGroups) {
                    await dispatch(postShippingMethodGroup({
                        idClient,
                        idClientAccount,
                        idShippingMethod,
                        data: {
                            idShippingMethod,
                            idClientAccountGroup
                        }
                    }));
                }
                // ADD ShippingMethodGroups
                await dispatch(getShippingMethodGroups({idClient, idClientAccount, idShippingMethod}));
            }

            return shippingMethod;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteShippingMethod = createAsyncThunk(
    'shippingMethod/delete',
    async ({idClient, idClientAccount, idShippingMethod}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/shippingmethods/${idShippingMethod}`);
            await dispatch(getShippingMethods())
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const shippingMethodSlice = createSlice({
    name: 'shippingMethod',
    initialState: {
        list: [],
        listLoading: false,
        method: null,
        loading: false,
        availableList: [],
        availableListLoading: undefined
    },
    reducers: {
        resetShippingMethods: state => {
            state.list = [];
        },
        resetShippingMethod: state => {
            state.method = null;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCustomerOrdersShippingMethods.pending, state => {
                if (state.availableList.length === 0) {
                    state.availableListLoading = true;
                }
            })
            .addCase(getCustomerOrdersShippingMethods.fulfilled, (state, action) => {
                state.availableListLoading = undefined;
                state.availableList = action.payload.filter(method => method.enabled === true).sort((a, b) => a.displayPosition - b.displayPosition)
            })
            .addCase(getCustomerOrdersShippingMethods.rejected, state => {
                state.availableListLoading = undefined;
            })
            .addCase(getShippingMethods.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getShippingMethods.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addCase(getShippingMethods.rejected, state => {
                state.listLoading = false;
            })
            .addCase(getShippingMethod.pending, state => {
                state.loading = true;
            })
            .addCase(getShippingMethod.fulfilled, (state, action) => {
                state.loading = false;
                state.method = action.payload;
            })
            .addCase(getShippingMethod.rejected, state => {
                state.loading = false;
            })
            .addCase(putShippingMethod.fulfilled, (state, action) => {
                state.method = action.payload;
            })
    }
});

export const {resetShippingMethods, resetShippingMethod} = shippingMethodSlice.actions;

export default shippingMethodSlice.reducer;