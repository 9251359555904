import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getStockRegularizationReasons = createAsyncThunk(
    'stockRegularizationReason/fetchAll',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/box/stockregularizationreasons');
            return response.data.stockRegularizationReasons;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const stockRegularizationReasonSlice = createSlice({
    name: 'stockRegularizationReason',
    initialState: {
        list: []
    },
    extraReducers: builder => {
        builder.addCase(getStockRegularizationReasons.fulfilled, (state, action) => {
            state.list = action.payload.filter((s) => s.activ).sort((a, b) => a.displayOrder - b.displayOrder);
        })
    }
});

export default stockRegularizationReasonSlice.reducer;