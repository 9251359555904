import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getCountries = createAsyncThunk(
    'country/fetchAll',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/countries');
            return response.data.countries;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const countrySlice = createSlice({
    name: 'country',
    initialState: {
        list: [],
        listLoading: undefined,
    },
    reducers: {
        resetCountries: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCountries.pending, state => {
                state.listLoading = true;
            })
            .addCase(getCountries.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getCountries.rejected, state => {
                state.listLoading = undefined;
            })
    }
});

export const {resetCountries} = countrySlice.actions;

export default countrySlice.reducer;