import ReactGA from "react-ga4";
import {getUserRoles} from "../casl/Ability";
import {isEmpty} from "lodash";

const setUserData = (user, clients, adminCustomizer) => {
    const adminCustomizerSettings = adminCustomizer.settings || {};
    const {clientsList} = clients;

    const userProperties = {
        idClient: null,
        clientName: null,
        idClientAccount: null,
        accountName: null,
        roles: getUserRoles(user, adminCustomizerSettings).join(", "),
    };

    if (user.idClient) {
        userProperties.idClient = user.idClient;
        userProperties.clientName = user.accounts.find(account => account.idClient === user.idClient)?.clientName;

        if (user.accounts.length === 1 && isEmpty(adminCustomizerSettings)) {
            userProperties.idClientAccount = user.accounts[0].idClientAccount;
            userProperties.accountName = user.accounts[0].accountName;
        }
    }

    if (!isEmpty(adminCustomizerSettings)) {
        userProperties.idClient = adminCustomizerSettings.idClient;
        userProperties.clientName = clientsList.find(client => client.idClient === adminCustomizerSettings.idClient).clientName;
        userProperties.idClientAccount = adminCustomizerSettings.idClientAccount;
        userProperties.accountName = user.accounts.find(account => account.idClientAccount === adminCustomizerSettings.idClientAccount)?.accountName;
    }

    ReactGA.gtag('set', 'user_properties', Object.fromEntries(
        Object.entries(userProperties).filter(([_, value]) => value !== null)
    ));
}

const userLogin = (idUser) => {
    ReactGA.event('login', {
        method: "Azure AD B2C",
        user_id: idUser
    });
}

const setLanguage = (lng) => {
    ReactGA.gtag('set', 'language', lng);
}

const setUserId = (idUser) => {
    ReactGA.gtag('set', 'user_id', idUser);
}

const sessionEnd = () => {
    ReactGA.gtag('event', 'session_end', {
        'session_engaged': false
    });
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
        if (cookies[i].startsWith("_ga")) {
            document.cookie = `${cookies[i].split("=")[0]}=; expires=${new Date(0).toUTCString()}; path=/;`;
        }
    }
};


export const gaEvents = {
    userLogin,
    setUserId,
    setUserData,
    setLanguage,
    sessionEnd
};