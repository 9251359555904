import {chain, floor, isEmpty, multiply} from "lodash";
import {addWeekdays, getDaysBetweenDates, isWeekday} from "../../../../@utils";
import moment from "moment/moment";
import {productTypes} from "../catalog-admin/products/form-model/formInitialValues";

export const getProductStock = (product, idWarehouse) => {
    const stock = product.stocks?.find((warehouse) => warehouse.idWarehouse === idWarehouse);
    return stock || {};
}

export const getProductStockLots = (stock) => {
    if (!stock || !stock.lots) return;

	const lotsDestr = [];
    stock.lots.forEach((lot) => {
        if (!isEmpty(lot.serialNumbers)) {
            lot.serialNumbers.forEach((sn) => {
                lotsDestr.push({
                    idStockLot: lot.idStockLot,
                    lotNumber: lot.lotNumber,
                    bestBeforeDate: isEmpty(lot.bestBeforeDate) ? "" : lot.bestBeforeDate,
                    // lotId: `${cartItem.sku}:${lot.lotNumber}:${lot.bestBeforeDate}:${sn.serialNumber}`,
                    idStockLotSerialNumber: sn.idStockLotSerialNumber,
                    serialNumber: isEmpty(sn.serialNumber) ? "" : sn.serialNumber,
                    availableQuantity: sn.quantity,
                });
            });
        } else {
            lotsDestr.push({
                idStockLot: lot.idStockLot,
                lotNumber: lot.lotNumber,
                bestBeforeDate: isEmpty(lot.bestBeforeDate) ? "" : lot.bestBeforeDate,
                // lotId: `${cartItem.sku}:${lot.lotNumber}:${lot.bestBeforeDate}:${lot.serialNumber}`,
                serialNumber: isEmpty(lot.serialNumber) ? "" : lot.serialNumber,
                idStockLotSerialNumber: null,
                availableQuantity: lot.quantity,
            });
        }
    });

	return chain(lotsDestr)
		.uniq()
		.orderBy(["bestBeforeDate", "serialNumber"], ["asc", "asc"])
		.value();
};

export const filterAddressBookByOrderType = (addressBook, activeWarehouse, orderType) => {
    let addresses = [...addressBook];

    switch (orderType) {
        case 'DELIVERY':
            addresses = addresses.filter((address => address.addressType !== 'WAREHOUSE' && address.addressType !== 'BOX' && address.idAddressBookAddress !== activeWarehouse?.idWarehouse ));
            break;

        case 'DESTRUCT':
            addresses = addresses.filter((address => address.idAddressBookAddress === activeWarehouse?.idWarehouse));
            break;

        case 'TRANSFERT':
            addresses = addresses.filter((address => (address.addressType === 'WAREHOUSE' || address.addressType === 'BOX') && address.idAddressBookAddress !== activeWarehouse?.idWarehouse ));
            break;

        default:
            addresses = addresses.filter((address => address.idAddressBookAddress !== activeWarehouse?.idWarehouse ));
    }

    return addresses.filter((address) => address.enabled === true);
}

export const buildAddressBookParamsByOrderType = (activeWarehouse, orderType, filterFields, value) => {
    const jsonParams = {
        currentPage: 1,
        pageSize: 50,
        sortOrders: [
            {
                field: "readOnly",
                direction: "asc",
            },
            {
                field: "creationDate",
                direction: "desc"
            }
        ],
        filterGroups: [
            {
                filters: [{
                    field: "enabled",
                    value: "1",
                    operator: "eq"
                }]
            },
            ...(value ? [{
                filters: filterFields.map(field => (
                    {
                        field,
                        value: `%${value}%`,
                        operator: "like"
                    }
                ))
            }] : [])
        ]
    };

    switch (orderType) {
        case 'DELIVERY':
            jsonParams.filterGroups.push({
                filters: [{
                    field: "addressType",
                    value: "'WAREHOUSE','BOX'",
                    operator: "nin"
                }]
            });
            jsonParams.filterGroups.push({
                filters: [{
                    field: "idAddressBookAddress",
                    value: activeWarehouse?.idWarehouse,
                    operator: "neq"
                }]
            });
            break;

        case 'DESTRUCT':
            jsonParams.filterGroups.push({
                filters: [{
                    field: "idAddressBookAddress",
                    value: activeWarehouse?.idWarehouse,
                    operator: "eq"
                }]
            });
            break;

        case 'TRANSFERT':
            jsonParams.filterGroups.push({
                filters: [{
                    field: "addressType",
                    value: "'WAREHOUSE','BOX'",
                    operator: "in"
                }]
            });
            jsonParams.filterGroups.push({
                filters: [{
                    field: "idAddressBookAddress",
                    value: activeWarehouse?.idWarehouse,
                    operator: "neq"
                }]
            });
            break;

        default:
            jsonParams.filterGroups.push({
                filters: [{
                    field: "idAddressBookAddress",
                    value: activeWarehouse?.idWarehouse,
                    operator: "neq"
                }]
            });
    }
    return jsonParams;
}

export const filterAddressBookByShippingMethod = (addressBook, activeWarehouse, shippingMethod) => {
    let addresses = [...addressBook];

    switch (shippingMethod.isProvisionAtDock) {
        case true:
            addresses = addresses = addresses.filter((address => address.idAddressBookAddress === activeWarehouse?.idWarehouse));
            break;
        default:
            addresses = addresses.filter((address => address.idAddressBookAddress !== activeWarehouse?.idWarehouse ));
    }

    return addresses.filter((address) => address.enabled === true);
}

export const buildAddressBookParamsByShippingMethod = (activeWarehouse, shippingMethod, filterFields, value) => {
    return {
        currentPage: 1,
        pageSize: 50,
        sortOrders: [
            {
                field: "readOnly",
                direction: "asc",
            },
            {
                field: "creationDate",
                direction: "desc"
            }
        ],
        filterGroups: [
            {
                filters: [{
                    field: "enabled",
                    value: "1",
                    operator: "eq"
                }]
            },
            {
                filters: [
                    ...(shippingMethod.isProvisionAtDock ? [{
                        field: "addressType",
                        value: "WAREHOUSE",
                        operator: "eq"
                    }] : [{
                        field: "idAddressBookAddress",
                        value: activeWarehouse?.idWarehouse,
                        operator: "neq"
                    }])
                ]
            },
            ...(value ? [{
                filters: filterFields.map(field => (
                    {
                        field,
                        value: `%${value}%`,
                        operator: "like"
                    }
                ))
            }] : [])
        ]
    };
}

export const updateCartItemsHelper = (cartItems, cartItemToAdd, quantity) => {
    const newItems = [];
    const updateItems = [];

    const findItem = (itemId) => cartItems.find((item) => item.idProduct === itemId);

    if (cartItemToAdd.type === productTypes['bundle'].value && cartItemToAdd.children.length > 0) {
        for (const child of cartItemToAdd.children) {
            const existingItem = findItem(child.idChild);
            if (!existingItem) {
                newItems.push({
                    idProduct: child.idChild,
                    quantity: +child.quantity * +quantity,
                    serialNumber: null,
                    lotNumber: null,
                    bestBeforeDate: null,
                    idStockLot: null,
                    idStockLotSerialNumber: null,
                });
            } else {
                updateItems.push({
                    ...existingItem,
                    quantity: existingItem.quantity + (+child.quantity * +quantity),
                });
            }
        }
    } else {
        const existingItem = findItem(cartItemToAdd.idProduct);
        if (!existingItem) {
            newItems.push({
                idProduct: cartItemToAdd.idProduct,
                quantity: +quantity,
                serialNumber: null,
                lotNumber: null,
                bestBeforeDate: null,
                idStockLot: null,
                idStockLotSerialNumber: null,
            });
        } else {
            updateItems.push({
                ...existingItem,
                quantity: existingItem.quantity + +quantity,
            });
        }
    }

    return {newItems, updateItems};
};


export const addItemToCartHelper = (cartItems, cartItemToAdd) => {
    const items = [...cartItems];
    const newItem = cartItemToAdd.item;

    if (newItem.type === productTypes['bundle'].value && newItem.children.length > 0) {
        for (const child of newItem.children) {
            const existingItem = items.find((item) => item.idProduct === child.idChild);
            if (!existingItem) {
                items.push({
                    idProduct: child.idChild,
                    productName: child.productName,
                    sku: child.sku,
                    skuClient: child.skuClient,
                    priceSell: child.priceSell,
                    idImage: child.idImage || child.images.find(item => item.defaultImage === true)?.idImage,
                    quantity: +child.quantity * +cartItemToAdd.qty,
                    totalPrice: floor(multiply(child.priceSell, +child.quantity * +cartItemToAdd.qty), 2),
                })
            } else {
                existingItem.quantity += +child.quantity * +cartItemToAdd.qty;
                existingItem.totalPrice += multiply(+existingItem.priceSell, +child.quantity * +cartItemToAdd.qty);
                existingItem.totalPrice = floor(existingItem.totalPrice, 2);
            }
        }
    } else {
        const existingItem = items.find((item) => item.idProduct === newItem.idProduct);
        // const totalQuantity = +state.totalQuantity + +action.payload.qty;
        if (!existingItem) {
            items.push({
                idProduct: newItem.idProduct,
                productName: newItem.productName,
                sku: newItem.sku,
                skuClient: newItem.skuClient,
                priceSell: newItem.priceSell,
                idImage: newItem.idImage || newItem.images.find(item => item.defaultImage === true)?.idImage,
                quantity: +cartItemToAdd.qty,
                totalPrice: floor(multiply(newItem.priceSell, +cartItemToAdd.qty), 2),
            })
        } else {
            existingItem.quantity += +cartItemToAdd.qty;
            existingItem.totalPrice += multiply(+existingItem.priceSell, +cartItemToAdd.qty);
            existingItem.totalPrice = floor(existingItem.totalPrice, 2);
        }
    }

    return items;
}

/**
 * Formats a product price to two decimal places and adds a currency symbol.
 * Positions the currency symbol before or after the value depending on the currency.
 *
 * @param {number} price - The price to be formatted.
 * @param {string} [currencySymbol='€'] - Optional currency symbol. Defaults to '€'.
 * @returns {string} The formatted price with the currency symbol added.
 */
export const formatPrice = (price, currencySymbol = '€') => {
    // Round to two decimal places
    let formattedPrice = parseFloat(price).toFixed(2);

    // List of currencies where the symbol should be placed before the value
    const symbolBeforeValue = ['$'];

    // Check if the currency symbol should be placed before the value
    if (symbolBeforeValue.includes(currencySymbol)) {
        return `${currencySymbol}${formattedPrice}`;
    } else {
        return `${formattedPrice}${currencySymbol}`;
    }
}

/**
 * Calculates the current page number based on previous items per page, previous current page, and new items per page.
 *
 * @param {number} previousItemsPerPage - The previous number of items per page.
 * @param {number} previousCurrentPage - The previous current page.
 * @param {number} newItemsPerPage - The new number of items per page.
 *
 * @return {number} - The current page number.
 */
export const calculateCurrentPage = (previousItemsPerPage, previousCurrentPage, newItemsPerPage) => {
    // Calculate the first item on the previous page
    let firstItemOnPreviousPage = (previousCurrentPage - 1) * previousItemsPerPage;
    // Calculate the current page based on the new items per page
    return Math.ceil((firstItemOnPreviousPage + 1) / newItemsPerPage);
}

/**
 * Calculates the starting and ending offsets of the current page.
 *
 * @param {number} currentPage - The current page number.
 * @param {number} itemsPerPage - The number of items per page.
 * @param {number} totalItems - The total number of items.
 *
 * @return {object} - An object containing the starting and ending offsets of the current page.
 */
export const calculatePageItems = (currentPage, itemsPerPage, totalItems) => {
    // Calculate the starting item on the current page
    let startOffset = (currentPage - 1) * itemsPerPage;
    // Calculate the ending item on the current page
    let endOffset = Math.min(startOffset + itemsPerPage, totalItems);

    return { startOffset, endOffset };
}

/**
 * Calculates the new current page number and the starting/ending offsets of its respective page based on previous items per page, previous current page, and new items per page.
 *
 * @param {number} totalItems - The total number of items.
 * @param {number} previousItemsPerPage - The previous number of items per page.
 * @param {number} previousCurrentPage - The previous current page.
 * @param {number} newItemsPerPage - The new number of items per page.
 *
 * @return {object} - An object containing the new current page number, as well as the starting and ending offsets of its respective page.
 */
export const calculateNewPageAndItems = (totalItems, previousItemsPerPage, previousCurrentPage, newItemsPerPage) => {
    // Calculate new current page
    const newCurrentPage = calculateCurrentPage(previousItemsPerPage, previousCurrentPage, newItemsPerPage);
    // Calculate the starting and the ending items on the current page
    const {startOffset, endOffset} = calculatePageItems(newCurrentPage, newItemsPerPage, totalItems);

    return { page: newCurrentPage, startOffset, endOffset };
}

/**
 * Builds a query object for product list based on new state.
 *
 * @param {object} newState - Object containing new state values for page, sizePerPage, stockFilter, labelFilter,
 * sortField, sortOrder and searchText.
 * @param {number} sizePerPageDefault - Default value of sizePerPage.
 * @param {string} defaultSortField - Default value of sortField.
 * @param {string} defaultSortOrder - Default value of sortOrder.
 *
 * @returns {Object} Query object consisting of pagination, sort order, filters and search.
 */
export const buildProductListQueryObject = (newState, sizePerPageDefault, defaultSortField, defaultSortOrder) => {
    const {page, sizePerPage, stockFilter, labelFilter, sortField, sortOrder, searchText} = newState;

    const queryObject = {};

    // Pagination
    if (parseInt(page) !== 1) {
        queryObject.page = parseInt(page);
    }

    if (sizePerPage && sizePerPageDefault !== sizePerPage) {
        queryObject.sizePerPage = sizePerPage;
    }
    // Pagination
    // Sort Order
    if (sortField && sortOrder && (defaultSortField !== sortField || defaultSortOrder !== sortOrder)) {
        queryObject.sortField = sortField;
        queryObject.sortOrder = sortOrder;
    }
    // Sort Order
    // Filters
    if (stockFilter) {
        queryObject.stockFilter = stockFilter
    }

    if (labelFilter) {
        queryObject.labelFilter = labelFilter
    }
    // Filters
    // Search
    if (searchText) {
        queryObject.searchText = searchText;
    }
    // Search

    const querySortOrder = ["page", "sizePerPage", "sortField", "sortOrder", "stockFilter", "labelFilter", "searchText"];

    return Object.keys(queryObject)
        .sort((a, b) => querySortOrder.indexOf(a) - querySortOrder.indexOf(b))
        .reduce((accumulator, key) => {
            accumulator[key] = queryObject[key];
            return accumulator;
        }, {});
}

export const getShippingMethodDeliveryDateAttributes = (shippingMethod) => {
    const now = moment().utcOffset(0, true).startOf('day');

    const minDeliveryDelay = shippingMethod.deliveryDelayInDay || 0;
    const maxDeliveryDelay = shippingMethod.deliveryMaxDelayInDay || 0;

    const minDate = addWeekdays(now, minDeliveryDelay, shippingMethod.deliveryWeekEndEnabled);
    const maxDate = addWeekdays(now, minDeliveryDelay + maxDeliveryDelay, shippingMethod.deliveryWeekEndEnabled);

    const deliveryDateAttributes = {
        minDate: minDate.toDate(),
        maxDate: maxDate.toDate(),
    };

    if (!shippingMethod.deliveryWeekEndEnabled) {
        deliveryDateAttributes.filterDate = isWeekday;
    }

    deliveryDateAttributes.includeDates = getDaysBetweenDates(minDate, maxDate, shippingMethod.deliveryDayOfWeek, shippingMethod.deliveryDayOfWeekWeekIncrement);

    return deliveryDateAttributes;
}